<template>
    <div class="nav">
        <img class="openBtn" src="https://ivyhouseaward.com/wdc2013/site/Dark1/img/more_horiz-white-18dp.svg" alt="">

        <div class="prevCourse navbtn">
            <div class="relative flex">
                <div class="text"></div>
                <div class="image bg"></div>
            </div>
        </div>

        <div class="nextCourse navbtn">
            <div class="relative flex">
                <div class="text"></div>
                <div class="image bg"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted: function() {
         console.log("Find attempted course's curriculum for user");

        //Find attempted course's curriculum for user
        var attemptId = '<%= Request.QueryString("AttemptId") %>';
        var url = "https://ivyhouse-uat-v2-api.purplemediahosting.co.uk/classroomAPI/LMS/getCoursePrevNext?AttemptId=" + attemptId;

        var settings = {
            "url": url,
            "method": "GET",
            "timeout": 0,
        };

        $.ajax(settings).done(function (response) {
            var mainWindow = parent.FindMainFrame();

            if (response.Prev != '') {
                var PrevElem = $(".prevCourse");

                var prevlink = '/wdc2013/lms/FullScreenPlayer.aspx?cid=' + response.Prev.Catalog_ID_FK + '&t=' + response.Prev.CourseName;

                if (response.Prev.DeepLinkURL != "" && response.Prev.DeepLinkURL != "") {
                    PrevElem.removeClass("active");
                } else {
                    var prevImage = "https://ivyhouse.purplemediacloud.co.uk/site/usermedia/images/" + response.Prev.M_FILE_PATH;
                    var prevName = "Previous Course: <br/><br/>" + response.Prev.Curriculum + "<br/>" + response.Prev.CourseName;

                    PrevElem.addClass("active");
                    PrevElem.find(".image").css("background-image", "url('" + prevImage + "')");
                    PrevElem.find(".text").html(prevName)

                    PrevElem.click(function () {
                        mainWindow.location.href = prevlink;
                    });
                }
            }

            if (response.Next != '') {
                var NextElem = $(".nextCourse");

                var nextlink = '/wdc2013/lms/FullScreenPlayer.aspx?cid=' + response.Next.Catalog_ID_FK + '&t=' + response.Next.CourseName;

                if (response.Next.DeepLinkURL != null && response.Next.DeepLinkURL != "") {
                    NextElem.removeClass("active");
                } else {
                    var nextImage = "https://ivyhouse.purplemediacloud.co.uk/site/usermedia/images/" + response.Next.M_FILE_PATH;
                    var nextName = "Next Course: <br/><br/>" + response.Next.Curriculum + "<br/>" + response.Next.CourseName;

                    NextElem.addClass("active");
                    NextElem.find(".image").css("background-image", "url('" + nextImage + "')");
                    NextElem.find(".text").html(nextName)

                    NextElem.click(function () {
                        mainWindow.location.href = nextlink;
                    });
                }
            }
        });
    },
}
</script>

<style>

    .relative {
        position: relative;
    }

    .flex {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .bg {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }


    .navbtn {
        position: absolute;
        background-color: #282425;
        /* border: 1px solid rgba(43, 51, 63, 0.7); */
        color: black;
        width: auto;
        height: 80px;
        text-align: center;
        cursor: pointer;
        z-index: 1000;
        text-decoration: none;
        padding: 6px 8px;
        bottom: calc(50% - 50px);
        -webkit-transition: all 300ms ease-in-out;
        -moz-transition: all 300ms ease-in-out;
        -ms-transition: all 300ms ease-in-out;
        -o-transition: all 300ms ease-in-out;
        transition: all 300ms ease-in-out;
        opacity: 0;
        pointer-events: none;
        box-shadow: 0 0 2px #000;
    }

    .prevCourse {
        left: 0;
    }

    .nextCourse {
        right: 0;
    }

        .prevCourse .text, .nextCourse .text {
            font-size: 12px;
            color: white;
            text-transform: uppercase;
            font-weight: bold;
            font-family: 'Montserrat', sans-serif;
        }

        .prevCourse .text, .nextCourse .text {
            padding: 8px 12px;
        }

        .prevCourse .image, .nextCourse .image {
            height: 100%;
            width: 80px;
        }

    .nav {
        background-color: #282425;
        height: 16px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        overflow: hidden;
        -webkit-transition: all 300ms ease-in-out;
        -moz-transition: all 300ms ease-in-out;
        -ms-transition: all 300ms ease-in-out;
        -o-transition: all 300ms ease-in-out;
        transition: all 300ms ease-in-out;
    }

        /* .nav.active,  */
        .nav:hover {
            height: 140px;
        }

            /* .nav.active .navbtn.active, */
            .nav:hover .navbtn.active {
                opacity: 1;
                pointer-events: all;
            }

        .nav .openBtn {
            position: absolute;
            width: 40px;
            left: calc(50% - 20px);
            top: -12px;
        }

    .hidden {
        display: none;
    }

    .fullscreen {
        width: 100vw;
        height: calc(100vh - 16px);
        position: absolute;
        left: 0;
        top: 0;
    }

    iframe {
        border: 0;
    }
</style>