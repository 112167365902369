<template>
  <div class="Content">
    
    <div class="helpContent featured_article">
        
        <div id="contact-form" class="contact-form">
            
            <form class="form" style="font-size:1.5rem">
                
                <p class="title">You have filled in the survey</p>
                <span class="question button" v-on:click="home">Part 2</span>
            </form>
        </div>
    </div>
</div>

</template>

<script>
export default {
  methods: {
    home: function (e) {
      this.$router.push('/home_part_two');
    }
  },
}
</script>

<style>

</style>