<template>
    <div class="questions">

        <div class="helpContent featured_article">

            <h1 style="font-size:5vw">{{page.title}}</h1>
            <h2>This page currently is under maintenance.</h2>

            <div class="featured_article">

                <div id="contact-form" class="contact-form">

                    <div class="separator"></div>
                    <form class="form" style="font-size:1.5rem">

                        <label class="question">
                            1. Having completed part one of The Award, has your knowledge and understanding deepened in the following areas?<br />Tick all of those that apply
                        </label>
                        <br />
                        <div>
                            <input class="question" type="checkbox" id="area1" v-model="formd.area1" name="area1" value="How you can choose your level of ownership for every situation" style="float:left;width:10%;margin-top:10px">
                            <label style="float:left;width:90%" for="area1">How you can choose your level of ownership for every situation</label>
                        </div>
                        <div><input class="question" type="checkbox" id="area2" v-model="formd.area2" name="area2" value="How you choose your behaviour" style="float:left;width:10%;margin-top:10px"><label style="float:left;width:90%" l for="area1">How you choose your behaviour</label></div>
                        <div><input class="question" type="checkbox" id="area3" v-model="formd.area3" name="area3" value="How you can better manage and control your thinking" style="float:left;width:10%;margin-top:10px"><label style="float:left;width:90%" for="area1">How you can better manage and control your thinking</label></div>
                        <div><input class="question" type="checkbox" id="area4" v-model="formd.area4" name="area4" value="How you can become a more courageous learner" style="float:left;width:10%;margin-top:10px"><label style="float:left;width:90%" for="area1">How you can become a more courageous learner</label></div>
                        <div><input class="question" type="checkbox" id="area5" v-model="formd.area5" name="area5" value="How your passions and strengths combine to put you in your element" style="float:left;width:10%;margin-top:10px"><label style="float:left;width:90%" for="area1">How your passions and strengths combine to put you in your element</label></div>
                        <div><input class="question" type="checkbox" id="area6" v-model="formd.area6" name="area6" value="How to get your priority driving forces met in a positive way" style="float:left;width:10%;margin-top:10px"><label style="float:left;width:90%" for="area1">How to get your priority driving forces met in a positive way</label></div>
                        <div><input class="question" type="checkbox" id="area7" v-model="formd.area7" name="area7" value="How to adapt your communication preferences to start to build better relationships" style="float:left;width:10%;margin-top:10px"><label style="float:left;width:90%" for="area1">How to adapt your communication preferences to start to build better relationships</label></div>
                        <div><input class="question" type="checkbox" id="area8" v-model="formd.area8" name="area8" value="How to build your unique, authentic, personal brand" style="float:left;width:10%;margin-top:10px"><label style="float:left;width:90%" label for="area1">How to build your unique, authentic, personal brand</label></div>
                        <div><input class="question" type="checkbox" id="area9" v-model="formd.area9" name="area9" value="How to start to build your extraordinary life" style="float:left;width:10%;margin-top:10px"><label style="float:left;width:90%" for="area1">How to start to build your extraordinary life</label></div>


                        <label class="question">2. Have you applied the learning to any areas of your life: </label> <br />
                        <div><input class="question" type="radio" v-model="formd.applied" id="appliedyes" name="applied" value="Yes" style="float:left;width:10%;margin-top:10px"><label style="float:left;width:90%" for="appliedyes">Yes</label></div>
                        <div><input type="radio" class="question" v-model="formd.applied" id="appliedno" name="applied" value="No" style="float:left;width:10%;margin-top:10px"><label style="float:left;width:90%" l for="appliedno" class="question">No</label></div>

                        <label class="question">3. Tell us here how you have applied the learning ? </label> <br />
                        <textarea class="question" name="appliedDetail" v-model="formd.appliedDetail" rows="4"></textarea>
                        <label class="question">4. What could we do differently to improve the learning? </label> <br />
                        <textarea class="question" name="improveLearning" v-model="formd.improveLearning" rows="4"></textarea>
                        <span class="question button" v-on:click="senddata">Submit</span>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {

        data: function () {
            return {
                surveyId: 0,
                page: pep.data.news.featured,
                formd: {
                    area1: '',
                    area2: '',
                    area3: '',
                    area4: '',
                    area5: '',
                    area6: '',
                    area7: '',
                    area8: '',
                    area9: '',
                    applied: '',
                    appliedDetail: '',
                    improveLearning: '',
                    userid: ''
                }
            };
        },
        mounted: function () {
            //this.checkResponse();
        },
        created: function () {
            this.checkResponse()
        },
        methods: {
            clearForm: function () {
                for (let field in this.formd) {
                    this.formd[field] = '';
                }
            },

            checkResponse: function () {
                var bool = false;
                bool = pep.checkSurvey();
                //alert(bool)
                //if (bool) {

                //    pep.gotoRoute('/questionnaireComplete');
                //    bool = false;
                //};
            },

            senddata: function (e) {
                e.preventDefault;
                var area1 = this.formd.area1;
                var area2 = this.formd.area2;
                var area3 = this.formd.area3;
                var area4 = this.formd.area4;
                var area5 = this.formd.area5;
                var area6 = this.formd.area6;
                var area7 = this.formd.area7;
                var area8 = this.formd.area8;
                var area9 = this.formd.area9;
                var applied = this.formd.applied;
                var appliedDetail = this.formd.appliedDetail;
                var improveLearning = this.formd.improveLearning;
                var userid = pep.data.userdetail.ID;


                pep.sendStudentQuestionnaireResponses(area1, area2, area3, area4, area5, area6, area7, area8, area9, applied, appliedDetail, improveLearning, userid);
                var url = location.href;
                var newurl = url.split('/').slice(0, -1).join('/') + '/quesionnairethankyou';
                location.href = newurl;
            },
            senddatafailed: function () {
                //
            },
            senddatasuccess: function () {
                this.formd.area1 = "";
                this.formd.area2 = "";
                this.formd.area3 = "";
                this.formd.area4 = "";
                this.formd.area5 = "";
                this.formd.area6 = "";
                this.formd.area7 = "";
                this.formd.area8 = "";
                this.formd.area9 = "";
                this.formd.applied = "";
                this.formd.appliedDetail = "";
                this.formd.improveLearning = "";
                this.formd.userid = "";
                this.$router.push('/questionnaireComplete');

            }
        },
    }
</script>

<style>
</style>