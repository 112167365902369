<template>
  <div class="page-header page-header-light">
    
    <div class="page-header-content header-elements-md-inline">
        
        <!-- Page Title -->
        <div class="page-title d-flex">
            
            <h4><i class="icon-arrow-left52 mr-2"></i> <span class="font-weight-semibold">Dashboards</span> - Sales</h4>
            <a href="#" class="header-elements-toggle text-default d-md-none"><i class="icon-more"></i></a>
        </div>
        <!-- /Page Title -->
        <!-- Header Elements -->
        <div class="header-elements d-none">
            
            <div class="d-flex justify-content-center">
                
                <a href="#" class="btn btn-link btn-float font-size-sm font-weight-semibold text-default">
                    
                    <i class="icon-bars-alt text-pink-300"></i>
                    <span>Statistics</span>
                </a>
                <a href="#" class="btn btn-link btn-float font-size-sm font-weight-semibold text-default">
                    
                    <i class="icon-users text-pink-300"></i>
                    <span>Customers</span>
                </a>
                <a href="#" class="btn btn-link btn-float font-size-sm font-weight-semibold text-default">
                    
                    <i class="icon-calendar text-pink-300"></i>
                    <span>Schedule</span>
                </a>
            </div>
        </div>
        <!-- /Header Elements -->
    </div>
    <div class="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
        
        <div class="d-flex">
            
            <!-- The Breadcrumb -->
            <div class="breadcrumb">
                
                <a href="index.html" class="breadcrumb-item"><i class="icon-home2 mr-2"></i> Home</a>
                <a href="#" class="breadcrumb-item">Sales</a>
                <span class="breadcrumb-item active">Dashboard</span>
            </div>
            <!-- /The Breadcrumb -->
            <a href="#" class="header-elements-toggle text-default d-md-none"><i class="icon-more"></i></a>
        </div>
        <!-- The Filter dropdown -->
        <div class="header-elements d-none">
            
            <div class="breadcrumb justify-content-center">
                
                <div class="breadcrumb-elements-item dropdown p-0">
                    
                    <a href="#" class="breadcrumb-elements-item dropdown-toggle" data-toggle="dropdown">
                        
                        Filters
                    </a>
                    <div class="dropdown-menu dropdown-menu-right">
                        
                        <a href="#" class="dropdown-item"><i class="icon-check"></i>Current</a>
                        <a href="#" class="dropdown-item"><i class="icon-none"></i>Past</a>
                        <a href="#" class="dropdown-item"><i class="icon-none"></i>Current &amp; Past</a>
                        <div class="dropdown-divider"></div>
                        <a href="#" class="dropdown-item"><i class="icon-none"></i>pending tasks</a>
                        <a href="#" class="dropdown-item"><i class="icon-none"></i>invoiced</a>
                        <a href="#" class="dropdown-item"><i class="icon-none"></i>overdue</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- The Filter dropdown -->
    </div>
</div>
</template>

<script>
export default {
  props: {

  },
  data: function () {
    return {

    };
  },
  methods: {
    login: function () {

    }
  },
  watch: {

  },
}
</script>

<style>

</style>