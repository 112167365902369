<template>
    <div class="myprofile">
        <div class="featured_article">
            <h1 class="title">My Profile</h1>
            <pep_user_profile></pep_user_profile>
        </div>
    </div>
</template>

<script>
    import pep_user_profile from '@/components/pep_user_profile.vue'
    export default {
        data: function () {
            return {
                leaderboard: pep.data.leaderboard
            };
        },
        components: {
            pep_user_profile,
        }
    }
</script>

<style>
</style>