<template>
    <form class="reset-password-form" action="index.html">
        <div class="card mb-0">
            <div class="card-body">
                <div class="text-center mb-3">
                    <img class="login-logo" v-bind:src="require(`@/${login_text.logo}`)" />
                    <span class="d-block text-muted">{{ login_text.resetsubtitle }}</span>
                </div>
                <div class="form-group form-group-feedback form-group-feedback-left">

                    <input v-model="password" id="frm_password" type="password" class="form-control" v-bind:placeholder="login_text.password" maxlength="32">
                    <div class="form-control-feedback">
                        <i class="icon-lock2 text-muted"></i>
                    </div>

                    <div id="phint" class="text-muted" style="margin:5px 0; max-width: 320px;">
                        Your password must contain a capital letter, <br /> 
                        number and special character e.g. paKJDp@w4
                    </div>

                </div>
                <div class="form-group form-group-feedback form-group-feedback-left">
                    <input v-model="password2" id="frm_password2" type="password" class="form-control" v-bind:placeholder="login_text.confirmpassword" 
                           v-on:keyup.enter="resetPassword()" 
                           maxlength="32">
                    <div class="form-control-feedback">
                        <i class="icon-lock2 text-muted"></i>
                    </div>
                </div>

                <div class="form-group">
                    <span class="d-block text-warning-800" style="max-width:320px;" v-html="login_text.reseterror"></span>
                    <span class="d-block text-success-700" style="max-width:320px;">{{ login_text.resetsuccess }}</span>
                </div>

                <div class="form-group">
                    <div id="btnResetPassword" v-on:click="resetPassword()" type="submit"
                         class="btn btn-primary btn-block blue">
                        <span>{{ login_text.reset }}</span><i class="icon-circle-right2 ml-2"></i>
                    </div>
                </div>
                <div id="signin" class="form-group d-flex align-items-center" style="cursor:pointer;">
                    <a v-on:click="changePage('/')" class="ml-auto blue">{{login_text.signin}}</a>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
    export default {

        props: {
            token: Object,
            isloggedin: Boolean
        },
        data: function () {
            return {
                login_text: pep.ui_lang_components.login,
                password: "",
                password2: "",
                resetDone: false,
            };
        },
        methods: {
            resetPassword: function () {
                var self = this;

                var userid = self.getParameterByName("userId", window.location.href);
                var token = encodeURIComponent(self.getParameterByName("token", window.location.href));

                if (userid == null || token == null) {
                    self.login_text.reseterror = "The reset password link is not valid.";

                    return;
                }

                var errors = this.validateEmptyFields();

                self.login_text.reseterror = "";
                self.login_text.resetsuccess = "";


                if (errors == "") {
                    pep.resetPassword(this.password);
                } else {
                    self.login_text.reseterror = errors;
                }
            },
            getParameterByName: function(name, url) {
                if (!url) url = window.location.href;
                name = name.replace(/[\[\]]/g, '\\$&');
                var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                    results = regex.exec(url);
                if (!results) return null;
                if (!results[2]) return '';
                return decodeURIComponent(results[2].replace(/\+/g, ' '));
            },
            changePage: function (pageurl) {
                this.$router.push(pageurl);
            },
            removeClass: function (el, c) {
                $(el).removeClass(c);
            },
            validatePassword: function (str) {
                // at least one number, one lowercase and one uppercase letter
                // at least six characters
                var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
                return re.test(str);
            },
            validateEmptyFields: function () {
                var errors = "";

                if (this.password.length < 6) {
                    errors += "Password must be at least 6 characters long. <br/>";
                } else {

                    if (!this.validateHasLetters(this.password))
                        errors += "Password must have letters.<br/>";

                    if (!this.validateHasSymbols(this.password))
                        errors += "Password must have at least one symbol (!@#$%^&*+).<br/>";

                    if (!this.validateHasDigits(this.password))
                        errors += "Password must have at least one digit.<br/>";
                }

                if (this.password2 == "")
                    errors += "Confirm password field is empty. <br/>";

                if (this.password != this.password2 && this.password != "" && this.password2 != "")
                    errors += "Passwords don't match. <br/>";
      
                return errors;
            },
            validateEmail: function (email) {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(email).toLowerCase());
            },
            validatePassword: function (str) {
                // at least one number, one lowercase and one uppercase letter
                // at least six characters
                var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
                return re.test(str);
            },
            validateHasLetters: function (str) {
                var re = /(?=.*[a-zA-Z])/;
                return re.test(str);
            },
            validateHasDigits: function (str) {
                var re = /(?=.*[0-9])/;
                return re.test(str);
            },
            validateHasSymbols: function (str) {
                var re = /(?=.*[!@#$%^&*])/;
                return re.test(str);
            },
            validateOnlyLetters: function (str) {
                var re = /^[A-Za-z]+$/;
                return re.test(str);
            },
        },
        mounted: function () {
            this.login_text.resetsuccess = "";
            this.login_text.reseterror = "";
        },
        watch: {
            isloggedin: function (val, oldval) {
                if (val === true) {
                    this.$router.push('/home');
                }
            }
        },
    }
</script>

<style>
</style>