<template>
    <div id="navbar_button_bar" v-if="loggedIn" class="navbar navbar-expand-lg navbar-light">
        <div class="col-12">
            <div class="row">
                <div class="col-3 text-center" v-on:click="changePage(home.url)" >
                    <div class="menuBarItem icon-home4">
                        <div v-if="home.current" class="isActive"></div>
                    </div>
                </div>
                <div class="col-3 text-center" v-on:click="changePage(actionplan.url)">
                    <div class="menuBarItem icon-stats-dots">
                        <div v-if="actionplan.current" class="isActive"></div>
                    </div>
                </div>
                <div class="col-3 text-center" v-on:click="changePage(notifications.url)">
                    <div class="menuBarItem icon-bell2">
                        <div v-if="hasNotifications" class="hasNotifications"></div>
                        <div v-if="notifications.current" class="isActive"></div>
                    </div>
                </div>
                <div class="col-3 text-center" v-on:click="changePage(questions.url)">
                    <div class="menuBarItem icon-help">
                        <div v-if="questions.current" class="isActive"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style >

</style>


<script>
    export default {
        data: function () {
            return {
                bb_text: pep.ui_lang_components.button_bar,
                login: pep.data.login,
                home: pep.config.defaultPages[1],
                actionplan: pep.config.defaultPages[2],
                notifications: pep.config.defaultPages[3],
                questions: pep.config.defaultPages[4],
                notificationitems: pep.data.notifications,
            };
        },
        methods: {
            changePage: function (newPageURL) {
                this.menuclass = "";

                pep.goToPageByURL(newPageURL);
            }
        },
        computed: {
            loggedIn: function () {
                return this.login.isLoggedIn;
            },
            hasNotifications: function () {
                if (this.notificationitems.length > 0) {
                    return true;
                } else {
                    return false;
                }
            }
        },
    }
</script>
