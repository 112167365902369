var pepData = {
    config: {
        system: {
            company_name: "Volvo UK",
            company_logos: ["images/logos/ivy_house_logo.png", "images/logos/ivy_house_logo.png"],
            system_name: "PEP",
            title: "ivyhouse",
        },
        defaultPages: [
            { id: 0, name: "Login", page: "index.html", url: "/", current: false, notifications: 0 },
            { id: 1, name: "Home", page: "index.html", url: "/home", current: true, notifications: 0 },
            { id: 2, name: "My Action Plan", page: "index.html", url: "/actionplanposter", current: false, notifications: 0 },
            { id: 3, name: "Notifications", page: "index.html", url: "/notifications", current: false, notifications: 0 },
            { id: 4, name: "Contact Us", page: "index.html", url: "/questions", current: false, notifications: 0 },
        ],
        services: {
            //Staging
            //"generalURL": "https://ivyhouse-uat-v2.purplemediahosting.co.uk/",
            //"generalURLImage": "https://ivyhouse-uat-v2.purplemediahosting.co.uk/site/usermedia/images/",
            //"LoginEndPoint": "https://ivyhouse-uat-v2-api.purplemediahosting.co.uk/TOKEN",
            //"ServiceEndpoint": "https://ivyhouse-uat-v2-api.purplemediahosting.co.uk/classroomAPI/IvyHouse/",

            //Live
            generalURL: "https://ivyhouseaward.com/",
            generalURLImage: "https://ivyhouseaward.com/site/usermedia/images/",
            LoginEndPoint: "https://ivyhouseapi.purplemediacloud.co.uk/TOKEN",
            ServiceEndpoint: "https://ivyhouseapi.purplemediacloud.co.uk/classroomAPI/IvyHouse/",

            /*
             * Action Plans packages have the ServiceEndpoint defined in:
             * IvyHouseV2_ActCourse_VueCli3\public\static\config-v2-act.js
             */

            loginClientID: "5059e7b0-cee1-47cf-8aef-5ded919d8aea",
            loginClientSecret: "OZxpxYmXSrnk07JrqHW5rXWnx69sZTQ2Zh4fCZNyMeIbChB7jEgSm7mSAhVuAZFN9It6T_MH35dfJ3Zr8gBXFw",
            loginTokenPath: "/nidp/oauth/nam/token",
            loginTokenRefreshPath: "/nidp/oauth/nam/tokeninfo",
            loginTokenCheckPath: "/nidp/oauth/nam/tokeninfo",
            currentlyRunning: 0,
            max_retrys: 10,
        },
        language: {
            language: "en",
            culture: "en-GB",
        },
    },
    ui_language: {
        en: {
            pages: {
                "salesDashboard.html": {},
            },
            components: {
                add_project: {
                    contact_title: "Primary Contact",
                    contact_description: "Enter the details for the main contact on this project, if the peron is already in our system then their account will be retrieved when you enter the email address.",
                    firstname: "First name",
                    firstname_prompt: "Enter first name",
                    lastname: "Last name",
                    lastname_prompt: "Enter last name",
                    email: "Email",
                    email_prompt: "Enter email",
                    password: "Password",
                    password_prompt: "Enter password",
                    repeat_password: "Repeat password",
                    repeat_password_prompt: "Repeat password",
                    phone_number: "Phone number",
                    phone_number_prompt: "Enter phone #",
                    company_title: "Company",
                    company_title_description: "Enter the company details, if the company already exists choose it from the list.",
                    company_name: "Company name",
                    company_name_prompt: "Enter the company name",
                    company_description: "Company Description",
                    company_description_prompt: "Enter a Company Description",
                    company_street: "Street",
                    company_street_prompt: "Enter the street",
                    company_town: "Town",
                    company_town_prompt: "Enter the town",
                    company_city: "City",
                    company_city_prompt: "Enter the city",
                    company_county: "County/Area",
                    company_county_prompt: "Enter the County/Area",
                    company_country: "Country",
                    company_country_prompt: "Enter the country",
                    company_postcode: "Postcide/Zip",
                    company_postcode_prompt: "Enter the postcode/zip",
                    company_telephone: "Telephone",
                    company_telephone_prompt: "Enter the telephone number",
                    company_email: "EMail",
                    company_email_prompt: "Enter the email",
                    company_website: "Web Address",
                    company_website_prompt: "Enter the web address",
                    company_business_type: "Business Type",
                    company_business_type_prompt: "Enter the business type",
                    project_title: "Project",
                    project_title_description: "Enter the project details.",
                    project_name: "Project name",
                    project_name_prompt: "Enter the project name",
                    project_reference: "Project Reference Code",
                    project_reference_prompt: "Enter the project reference code",
                    project_start: "Start Date",
                    project_start_prompt: "Enter the start date",
                    project_description: "Project Description",
                    project_description_prompt: "Enter the project description",
                    project_end: "End Date",
                    project_end_prompt: "Enter the end date",
                    files_title: "Files",
                    Files_title_description: "Attach any files to upload with this project.",
                    files_purchase: "Upload the Purchase Order",
                    files_purchase_prompt: "Accepted formats: doc, docx, pdf. Max file size 2Mb",
                    files_tech: "Upload the Tech Spec",
                    files_tech_prompt: "Accepted formats: doc, docx, pdf. Max file size 2Mb",
                    files_brand: "Upload the Brand Guidelines",
                    files_brand_prompt: "Accepted formats: doc, docx, pdf. Max file size 2Mb",
                    files_instructional: "Upload the Instructional Design",
                    files_instructional_prompt: "Accepted formats: doc, docx, pdf. Max file size 2Mb",
                    files_design: "Upload the Designs",
                    files_design_prompt: "Accepted formats: doc, docx, pdf. Max file size 2Mb",
                    submit: "SUBMIT FORMS",
                    cancel: "CANCEL",
                },
                navbar: {
                    logo: "images/logos/ivy_house_logo_white.png",
                    logout: "Log Out",
                },
                login: {
                    title: "ivyhouse app",
                    subtitle: "Login to your account",
                    username: "Username",
                    email: "Email",
                    password: "Password",
                    forgot: "Forgot password?",
                    forgot2: "Forgot Username?",
                    signin: "Sign In",
                    noaccount: "Don't have an account?",
                    signup: "Register Now",
                    recover: "Recover",
                    reset: "Save Password",
                    terms1: "By continuing, you're confirming that you've read our",
                    terms1b: "Tick, to confirm that you've read our",
                    terms2: "Terms & Conditions",
                    and: "and",
                    cookiepolicy: "Cookie Policy",
                    registererror: "",
                    loginerror: "",
                    recovererror: "",
                    recoversuccess: "",
                    reseterror: "",
                    resetsuccess: "",
                    logo: "images/logos/ivy_house_logo.png",
                    regsubtitle: "Register a new account",
                    recoversubtitle: "Recover password",
                    recoversubtitle2: "Recover username",
                    resetsubtitle: "Reset password",
                    confirmpassword: "Confirm password",
                    register: "Register",
                    rcode: "Registration code",
                    fname: "First name",
                    lname: "Last name",
                    salutation: "Salutation",
                    mr: "Mr",
                    mrs: "Mrs",
                    miss: "Miss",
                    ms: "Ms",
                    dr: "Dr",
                    other: "Other",
                    back: "Back",
                },
                notifications: {
                    title: "Notifications",
                    subtitle: "Your notifications will appear here. Once you've read them, swipe right to delete.",
                    subtitle2: "",
                },
                footer: {
                    support: "",
                    docs: "",
                },
                sales_list: {
                    updatedata: "Update Data",
                    showactive: "Show Active",
                    showclosed: "Show Closed",
                    showhold: "Show on Hold",
                    showpending: "Show Pending",
                    addcustomer: "Add Customer",
                    addpitch: "Add Pitch",
                    viewclient: "View Client",
                    viewproject: "View Project Detail",
                    viewschedule: "View Schedule",
                    updateproject: "Update Project Detail",
                    addtask: "Add Task",
                    salesleads: "Sales Leads",
                    client: "Client",
                    project: "Project",
                    confidence: "Confidence",
                    budget: "Budget",
                    status: "Status",
                    active: "Active",
                    hold: "On Hold",
                    closed: "Closed",
                    pending: "Pending",
                },
            },
        },
        es: {
            pages: {
                "salesDashboard.html": {},
            },
            components: {
                navbar: {
                    logo: "assets/img/pm-logo-s.png",
                    logout: "Cerrar sesión",
                },
                login: {
                    title: "Ingrese a su cuenta",
                    subtitle: "Tus credenciales",
                    username: "Nombre de usuario",
                    password: "Contraseña",
                    forgot: "Se te olvidó tu contraseña",
                    signin: "Registrarse",
                    noaccount: "No tienes una cuenta?",
                    signup: "Regístrate",
                    terms1: "Al continuar, confirma que ha leído nuestra",
                    terms2: "Términos y condiciones",
                    and: "y",
                    cookiepolicy: "Política de cookies",
                    loginerror: "",
                    logo: "assets/img/PEP_LOGOS-02.svg",
                },
                footer: {
                    support: "PEP Support",
                    docs: "Documentos de PEP",
                },
                sales_list: {
                    updatedata: "Update Data",
                    showactive: "Show Active",
                    showclosed: "Show Closed",
                    showhold: "Show on Hold",
                    showpending: "Show Pending",
                    addcustomer: "Add Customer",
                    addpitch: "Add Pitch",
                    viewclient: "View Client",
                    viewproject: "View Project Detail",
                    viewschedule: "View Schedule",
                    updateproject: "Update Project Detail",
                    addtask: "Add Task",
                    salesleads: "Sales Leads",
                    client: "Client",
                    project: "Project",
                    confidence: "Confidence",
                    budget: "Budget",
                    status: "Status",
                    active: "Active",
                    hold: "On Hold",
                    closed: "Closed",
                    pending: "Pending",
                },
            },
        },
    },
    data: {
        settings: {
            storyboard_viewed: false,
            temp_redirect: "",
            administrator_email: "support@ivyhouse.co.uk",
        },
        login: {
            token: {
                access_token: "",
                token_type: "bearer",
                expires_in: "0",
                userName: "",
                issued: "",
                expires: "",
                isLoggedIn: false,
                scope: "",
                refresh_token: "",
            },
            isLoggedIn: false,
        },
        video: [
            { id: 1, name: "Orientation", videoUrl: "https://ivyhouseaward.com/site/usermedia/video/orientation.mp4", poster: "https://ivyhouseaward.com/site/usermedia/video/poster/intro.jpg", viewed: false },
            { id: 2, name: "Intro", videoUrl: "https://player.vimeo.com/external/430259662.hd.mp4?s=4b89f17ad018a64596fdb633ff14101958131623&profile_id=175", poster: "https://ivyhouseaward.com/site/usermedia/video/poster/intro.jpg", viewed: false },
            { id: 3, name: "Intro", videoUrl: "https://ivyhouseaward.com/site/usermedia/video/t2_intro.mp4", poster: "https://ivyhouseaward.com/site/usermedia/video/poster/intro.jpg", viewed: false },
        ],
        user: {
            userid: "",
            firstname: "",
            lastname: "",
            jobtitle: "",
            joined: "",
            address: "",
            photo: "",
        },
        certifications: [],
        news: {
            featured: { id: "1", link: "/nourl/", imageUrl: "images/ivycourses/my_ownership_cows_square.jpg", header: "week 2", title: "My Ownership", title2: "Cows and ", title3: "Rhinos", subtitle: "", summary: "", article: "", categories: [], created: "2018-01-01T05:12:12.121Z", updated: "2018-01-01T05:12:12.121Z", read: null },
            current: [
                { id: "", link: "", imageUrl: "", title: "", subtitle: "", summary: "", article: "", categories: [], created: "2018-01-01T05:12:12.121Z", updated: "2018-01-01T05:12:12.121Z", read: null },
                { id: "", link: "", imageUrl: "", title: "", subtitle: "", summary: "", article: "", categories: [], created: "2018-01-01T05:12:12.121Z", updated: "2018-01-01T05:12:12.121Z", read: null },
                { id: "", link: "", imageUrl: "", title: "", subtitle: "", summary: "", article: "", categories: [], created: "2018-01-01T05:12:12.121Z", updated: "2018-01-01T05:12:12.121Z", read: null },
            ],
        },
        notifications: [],
        simple_search_results: [
            {
                courseid: 1,
                siteid_fk: 1,
                coursecode: "VCC2003",
                progress: "0",
                coursename: "ANIMAL SCHOOL",
                coursedescription: "Lorum ipsum dolor site amet, consequeter adipescing elit...",
                homepageid: 1,
                productid: 1,
                languageid: 1,
                vendorid: 1,
                expecteddurationtext: "",
                expecteddurationinhours: 1,
                versionno: 1,
                uploadedby: "",
                ownerid: "",
                contactid: "",
                developerid: "",
                supportid: "",
                deliverytype: "pdf",
                level: 1,
                created: "",
                uploaded: "",
                updated: "",
                startfrom: "",
                expires: "",
                review: "",
                status: 1,
                keywords: "",
                fieldofstudy: "",
                lessonobjectives: "",
                targetaudience: "",
                prerequisites: "",
                enableonlyaftercourse: 1,
                upgradedtocourse: 1,
                alternatetocourse: 1,
                childofcourse: 1,
                autoruncoursewhenfinished: 1,
                cost: "",
                price: "",
                specialofferprice: "",
                maxpoints: "",
                passpoints: "",
                coverimage: "images/courses/image1.jpg",
                spineimage: "",
                summaryimage1: "",
                summaryimage2: "",
                summaryimage3: "",
                summaryimage4: "",
                locationdir: "",
                packagefilename: "",
                packageid: 1,
                userrating: 3,
                category: 1,
                orderno: 1,
            },
            {
                courseid: 2,
                siteid_fk: 1,
                coursecode: "20120822",
                progress: "50",
                coursename: "Active TFT Display",
                coursedescription: "Lorum ipsum dolor site amet, consequeter adipescing elit...",
                homepageid: 1,
                productid: 1,
                languageid: 1,
                vendorid: 1,
                expecteddurationtext: "",
                expecteddurationinhours: 1,
                versionno: 1,
                uploadedby: "",
                ownerid: "",
                contactid: "",
                developerid: "",
                supportid: "",
                deliverytype: "person_screen",
                level: 1,
                created: "",
                uploaded: "",
                updated: "",
                startfrom: "",
                expires: "",
                review: "",
                status: 1,
                keywords: "",
                fieldofstudy: "",
                lessonobjectives: "",
                targetaudience: "",
                prerequisites: "",
                enableonlyaftercourse: 1,
                upgradedtocourse: 1,
                alternatetocourse: 1,
                childofcourse: 1,
                autoruncoursewhenfinished: 1,
                cost: "",
                price: "",
                specialofferprice: "",
                maxpoints: "",
                passpoints: "",
                coverimage: "images/courses/image2.jpg",
                spineimage: "",
                summaryimage1: "",
                summaryimage2: "",
                summaryimage3: "",
                summaryimage4: "",
                locationdir: "",
                packagefilename: "",
                packageid: 1,
                userrating: 4,
                category: 1,
                orderno: 1,
            },
            {
                courseid: 3,
                siteid_fk: 1,
                coursecode: "CODE_3",
                progress: "100",
                coursename: "All New Volvo V60 First Look",
                coursedescription: "Lorum ipsum dolor site amet, consequeter adipescing elit...",
                homepageid: 1,
                productid: 1,
                languageid: 1,
                vendorid: 1,
                expecteddurationtext: "",
                expecteddurationinhours: 1,
                versionno: 1,
                uploadedby: "",
                ownerid: "",
                contactid: "",
                developerid: "",
                supportid: "",
                deliverytype: "ear_pointer",
                level: 1,
                created: "",
                uploaded: "",
                updated: "",
                startfrom: "",
                expires: "",
                review: "",
                status: 1,
                keywords: "",
                fieldofstudy: "",
                lessonobjectives: "",
                targetaudience: "",
                prerequisites: "",
                enableonlyaftercourse: 1,
                upgradedtocourse: 1,
                alternatetocourse: 1,
                childofcourse: 1,
                autoruncoursewhenfinished: 1,
                cost: "",
                price: "",
                specialofferprice: "",
                maxpoints: "",
                passpoints: "",
                coverimage: "images/courses/image3.jpg",
                spineimage: "",
                summaryimage1: "",
                summaryimage2: "",
                summaryimage3: "",
                summaryimage4: "",
                locationdir: "",
                packagefilename: "",
                packageid: 1,
                userrating: 3,
                category: 1,
                orderno: 1,
            },
            {
                courseid: 4,
                siteid_fk: 1,
                coursecode: "CODE_4",
                progress: "50",
                coursename: "Basic Electric",
                coursedescription: "Lorum ipsum dolor site amet, consequeter adipescing elit...",
                homepageid: 1,
                productid: 1,
                languageid: 1,
                vendorid: 1,
                expecteddurationtext: "",
                expecteddurationinhours: 1,
                versionno: 1,
                uploadedby: "",
                ownerid: "",
                contactid: "",
                developerid: "",
                supportid: "",
                deliverytype: "pdf",
                level: 1,
                created: "",
                uploaded: "",
                updated: "",
                startfrom: "",
                expires: "",
                review: "",
                status: 1,
                keywords: "",
                fieldofstudy: "",
                lessonobjectives: "",
                targetaudience: "",
                prerequisites: "",
                enableonlyaftercourse: 1,
                upgradedtocourse: 1,
                alternatetocourse: 1,
                childofcourse: 1,
                autoruncoursewhenfinished: 1,
                cost: "",
                price: "",
                specialofferprice: "",
                maxpoints: "",
                passpoints: "",
                coverimage: "images/courses/image4.jpg",
                spineimage: "",
                summaryimage1: "",
                summaryimage2: "",
                summaryimage3: "",
                summaryimage4: "",
                locationdir: "",
                packagefilename: "",
                packageid: 1,
                userrating: 3,
                category: 1,
                orderno: 1,
            },
            {
                courseid: 5,
                siteid_fk: 1,
                coursecode: "CODE_5",
                progress: "50",
                coursename: "Basic Selekt",
                coursedescription: "Lorum ipsum dolor site amet, consequeter adipescing elit...",
                homepageid: 1,
                productid: 1,
                languageid: 1,
                vendorid: 1,
                expecteddurationtext: "",
                expecteddurationinhours: 1,
                versionno: 1,
                uploadedby: "",
                ownerid: "",
                contactid: "",
                developerid: "",
                supportid: "",
                deliverytype: "morterboard",
                level: 1,
                created: "",
                uploaded: "",
                updated: "",
                startfrom: "",
                expires: "",
                review: "",
                status: 1,
                keywords: "",
                fieldofstudy: "",
                lessonobjectives: "",
                targetaudience: "",
                prerequisites: "",
                enableonlyaftercourse: 1,
                upgradedtocourse: 1,
                alternatetocourse: 1,
                childofcourse: 1,
                autoruncoursewhenfinished: 1,
                cost: "",
                price: "",
                specialofferprice: "",
                maxpoints: "",
                passpoints: "",
                coverimage: "images/courses/image5.jpg",
                spineimage: "",
                summaryimage1: "",
                summaryimage2: "",
                summaryimage3: "",
                summaryimage4: "",
                locationdir: "",
                packagefilename: "",
                packageid: 1,
                userrating: 2,
                category: 1,
                orderno: 1,
            },
            {
                courseid: 6,
                siteid_fk: 1,
                coursecode: "CODE_6",
                progress: "50",
                coursename: "C70 Roof Information (RHT)",
                coursedescription: "Lorum ipsum dolor site amet, consequeter adipescing elit...",
                homepageid: 1,
                productid: 1,
                languageid: 1,
                vendorid: 1,
                expecteddurationtext: "",
                expecteddurationinhours: 1,
                versionno: 1,
                uploadedby: "",
                ownerid: "",
                contactid: "",
                developerid: "",
                supportid: "",
                deliverytype: "ear_video",
                level: 1,
                created: "",
                uploaded: "",
                updated: "",
                startfrom: "",
                expires: "",
                review: "",
                status: 1,
                keywords: "",
                fieldofstudy: "",
                lessonobjectives: "",
                targetaudience: "",
                prerequisites: "",
                enableonlyaftercourse: 1,
                upgradedtocourse: 1,
                alternatetocourse: 1,
                childofcourse: 1,
                autoruncoursewhenfinished: 1,
                cost: "",
                price: "",
                specialofferprice: "",
                maxpoints: "",
                passpoints: "",
                coverimage: "images/courses/image6.jpg",
                spineimage: "",
                summaryimage1: "",
                summaryimage2: "",
                summaryimage3: "",
                summaryimage4: "",
                locationdir: "",
                packagefilename: "",
                packageid: 1,
                userrating: 3,
                category: 1,
                orderno: 1,
            },
            {
                courseid: 7,
                siteid_fk: 1,
                coursecode: "CODE_7",
                progress: "100",
                coursename: "CDK Warranty eLearning",
                coursedescription: "Lorum ipsum dolor site amet, consequeter adipescing elit...",
                homepageid: 1,
                productid: 1,
                languageid: 1,
                vendorid: 1,
                expecteddurationtext: "",
                expecteddurationinhours: 1,
                versionno: 1,
                uploadedby: "",
                ownerid: "",
                contactid: "",
                developerid: "",
                supportid: "",
                deliverytype: "morterboard",
                level: 1,
                created: "",
                uploaded: "",
                updated: "",
                startfrom: "",
                expires: "",
                review: "",
                status: 1,
                keywords: "",
                fieldofstudy: "",
                lessonobjectives: "",
                targetaudience: "",
                prerequisites: "",
                enableonlyaftercourse: 1,
                upgradedtocourse: 1,
                alternatetocourse: 1,
                childofcourse: 1,
                autoruncoursewhenfinished: 1,
                cost: "",
                price: "",
                specialofferprice: "",
                maxpoints: "",
                passpoints: "",
                coverimage: "images/courses/image7.jpg",
                spineimage: "",
                summaryimage1: "",
                summaryimage2: "",
                summaryimage3: "",
                summaryimage4: "",
                locationdir: "",
                packagefilename: "",
                packageid: 1,
                userrating: 1,
                category: 1,
                orderno: 1,
            },
            {
                courseid: 8,
                siteid_fk: 1,
                coursecode: "CODE_8",
                progress: "0",
                coursename: "CEM",
                coursedescription: "Lorum ipsum dolor site amet, consequeter adipescing elit...",
                homepageid: 1,
                productid: 1,
                languageid: 1,
                vendorid: 1,
                expecteddurationtext: "",
                expecteddurationinhours: 1,
                versionno: 1,
                uploadedby: "",
                ownerid: "",
                contactid: "",
                developerid: "",
                supportid: "",
                deliverytype: "ear_video_camera",
                level: 1,
                created: "",
                uploaded: "",
                updated: "",
                startfrom: "",
                expires: "",
                review: "",
                status: 1,
                keywords: "",
                fieldofstudy: "",
                lessonobjectives: "",
                targetaudience: "",
                prerequisites: "",
                enableonlyaftercourse: 1,
                upgradedtocourse: 1,
                alternatetocourse: 1,
                childofcourse: 1,
                autoruncoursewhenfinished: 1,
                cost: "",
                price: "",
                specialofferprice: "",
                maxpoints: "",
                passpoints: "",
                coverimage: "images/courses/image8.jpg",
                spineimage: "",
                summaryimage1: "",
                summaryimage2: "",
                summaryimage3: "",
                summaryimage4: "",
                locationdir: "",
                packagefilename: "",
                packageid: 1,
                userrating: 4,
                category: 1,
                orderno: 1,
            },
            {
                courseid: 9,
                siteid_fk: 1,
                coursecode: "CODE_9",
                progress: "50",
                coursename: "Chassis Control Systems",
                coursedescription: "Lorum ipsum dolor site amet, consequeter adipescing elit...",
                homepageid: 1,
                productid: 1,
                languageid: 1,
                vendorid: 1,
                expecteddurationtext: "",
                expecteddurationinhours: 1,
                versionno: 1,
                uploadedby: "",
                ownerid: "",
                contactid: "",
                developerid: "",
                supportid: "",
                deliverytype: "ear_screen",
                level: 1,
                created: "",
                uploaded: "",
                updated: "",
                startfrom: "",
                expires: "",
                review: "",
                status: 1,
                keywords: "",
                fieldofstudy: "",
                lessonobjectives: "",
                targetaudience: "",
                prerequisites: "",
                enableonlyaftercourse: 1,
                upgradedtocourse: 1,
                alternatetocourse: 1,
                childofcourse: 1,
                autoruncoursewhenfinished: 1,
                cost: "",
                price: "",
                specialofferprice: "",
                maxpoints: "",
                passpoints: "",
                coverimage: "images/courses/image9.jpg",
                spineimage: "",
                summaryimage1: "",
                summaryimage2: "",
                summaryimage3: "",
                summaryimage4: "",
                locationdir: "",
                packagefilename: "",
                packageid: 1,
                userrating: 5,
                category: 1,
                orderno: 1,
            },
            {
                courseid: 1,
                siteid_fk: 1,
                coursecode: "",
                progress: "50",
                coursename: "Coaching for Performance",
                coursedescription: "Lorum ipsum dolor site amet, consequeter adipescing elit...",
                homepageid: 1,
                productid: 1,
                languageid: 1,
                vendorid: 1,
                expecteddurationtext: "",
                expecteddurationinhours: 1,
                versionno: 1,
                uploadedby: "",
                ownerid: "",
                contactid: "",
                developerid: "",
                supportid: "",
                deliverytype: "morterboard",
                level: 1,
                created: "",
                uploaded: "",
                updated: "",
                startfrom: "",
                expires: "",
                review: "",
                status: 1,
                keywords: "",
                fieldofstudy: "",
                lessonobjectives: "",
                targetaudience: "",
                prerequisites: "",
                enableonlyaftercourse: 1,
                upgradedtocourse: 1,
                alternatetocourse: 1,
                childofcourse: 1,
                autoruncoursewhenfinished: 1,
                cost: "",
                price: "",
                specialofferprice: "",
                maxpoints: "",
                passpoints: "",
                coverimage: "images/courses/image10.jpg",
                spineimage: "",
                summaryimage1: "",
                summaryimage2: "",
                summaryimage3: "",
                summaryimage4: "",
                locationdir: "",
                packagefilename: "",
                packageid: 1,
                userrating: 1,
                category: 1,
                orderno: 1,
            },
            {
                courseid: 2,
                siteid_fk: 1,
                coursecode: "",
                progress: "100",
                coursename: "Connected Service Booking",
                coursedescription: "Lorum ipsum dolor site amet, consequeter adipescing elit...",
                homepageid: 1,
                productid: 1,
                languageid: 1,
                vendorid: 1,
                expecteddurationtext: "",
                expecteddurationinhours: 1,
                versionno: 1,
                uploadedby: "",
                ownerid: "",
                contactid: "",
                developerid: "",
                supportid: "",
                deliverytype: "ear_video",
                level: 1,
                created: "",
                uploaded: "",
                updated: "",
                startfrom: "",
                expires: "",
                review: "",
                status: 1,
                keywords: "",
                fieldofstudy: "",
                lessonobjectives: "",
                targetaudience: "",
                prerequisites: "",
                enableonlyaftercourse: 1,
                upgradedtocourse: 1,
                alternatetocourse: 1,
                childofcourse: 1,
                autoruncoursewhenfinished: 1,
                cost: "",
                price: "",
                specialofferprice: "",
                maxpoints: "",
                passpoints: "",
                coverimage: "images/courses/image11.jpg",
                spineimage: "",
                summaryimage1: "",
                summaryimage2: "",
                summaryimage3: "",
                summaryimage4: "",
                locationdir: "",
                packagefilename: "",
                packageid: 1,
                userrating: 1,
                category: 1,
                orderno: 1,
            },
            {
                courseid: 3,
                siteid_fk: 1,
                coursecode: "",
                progress: "100",
                coursename: "Content Store - Job Manager",
                coursedescription: "Lorum ipsum dolor site amet, consequeter adipescing elit...",
                homepageid: 1,
                productid: 1,
                languageid: 1,
                vendorid: 1,
                expecteddurationtext: "",
                expecteddurationinhours: 1,
                versionno: 1,
                uploadedby: "",
                ownerid: "",
                contactid: "",
                developerid: "",
                supportid: "",
                deliverytype: "ear_video",
                level: 1,
                created: "",
                uploaded: "",
                updated: "",
                startfrom: "",
                expires: "",
                review: "",
                status: 1,
                keywords: "",
                fieldofstudy: "",
                lessonobjectives: "",
                targetaudience: "",
                prerequisites: "",
                enableonlyaftercourse: 1,
                upgradedtocourse: 1,
                alternatetocourse: 1,
                childofcourse: 1,
                autoruncoursewhenfinished: 1,
                cost: "",
                price: "",
                specialofferprice: "",
                maxpoints: "",
                passpoints: "",
                coverimage: "images/courses/image12.jpg",
                spineimage: "",
                summaryimage1: "",
                summaryimage2: "",
                summaryimage3: "",
                summaryimage4: "",
                locationdir: "",
                packagefilename: "",
                packageid: 1,
                userrating: 1,
                category: 1,
                orderno: 1,
            },
            {
                courseid: 4,
                siteid_fk: 1,
                coursecode: "",
                progress: "50",
                coursename: "Content Store - Shop",
                coursedescription: "Lorum ipsum dolor site amet, consequeter adipescing elit...",
                homepageid: 1,
                productid: 1,
                languageid: 1,
                vendorid: 1,
                expecteddurationtext: "",
                expecteddurationinhours: 1,
                versionno: 1,
                uploadedby: "",
                ownerid: "",
                contactid: "",
                developerid: "",
                supportid: "",
                deliverytype: "ear_video_camera",
                level: 1,
                created: "",
                uploaded: "",
                updated: "",
                startfrom: "",
                expires: "",
                review: "",
                status: 1,
                keywords: "",
                fieldofstudy: "",
                lessonobjectives: "",
                targetaudience: "",
                prerequisites: "",
                enableonlyaftercourse: 1,
                upgradedtocourse: 1,
                alternatetocourse: 1,
                childofcourse: 1,
                autoruncoursewhenfinished: 1,
                cost: "",
                price: "",
                specialofferprice: "",
                maxpoints: "",
                passpoints: "",
                coverimage: "images/courses/image13.jpg",
                spineimage: "",
                summaryimage1: "",
                summaryimage2: "",
                summaryimage3: "",
                summaryimage4: "",
                locationdir: "",
                packagefilename: "",
                packageid: 1,
                userrating: 1,
                category: 1,
                orderno: 1,
            },
            {
                courseid: 5,
                siteid_fk: 1,
                coursecode: "",
                progress: "0",
                coursename: "Content Store- Web to Publish",
                coursedescription: "Lorum ipsum dolor site amet, consequeter adipescing elit...",
                homepageid: 1,
                productid: 1,
                languageid: 1,
                vendorid: 1,
                expecteddurationtext: "",
                expecteddurationinhours: 1,
                versionno: 1,
                uploadedby: "",
                ownerid: "",
                contactid: "",
                developerid: "",
                supportid: "",
                deliverytype: "pdf",
                level: 1,
                created: "",
                uploaded: "",
                updated: "",
                startfrom: "",
                expires: "",
                review: "",
                status: 1,
                keywords: "",
                fieldofstudy: "",
                lessonobjectives: "",
                targetaudience: "",
                prerequisites: "",
                enableonlyaftercourse: 1,
                upgradedtocourse: 1,
                alternatetocourse: 1,
                childofcourse: 1,
                autoruncoursewhenfinished: 1,
                cost: "",
                price: "",
                specialofferprice: "",
                maxpoints: "",
                passpoints: "",
                coverimage: "images/courses/image14.jpg",
                spineimage: "",
                summaryimage1: "",
                summaryimage2: "",
                summaryimage3: "",
                summaryimage4: "",
                locationdir: "",
                packagefilename: "",
                packageid: 1,
                userrating: 1,
                category: 1,
                orderno: 1,
            },
            {
                courseid: 6,
                siteid_fk: 1,
                coursecode: "",
                progress: "50",
                coursename: "Coolant Filling Tool",
                coursedescription: "Lorum ipsum dolor site amet, consequeter adipescing elit...",
                homepageid: 1,
                productid: 1,
                languageid: 1,
                vendorid: 1,
                expecteddurationtext: "",
                expecteddurationinhours: 1,
                versionno: 1,
                uploadedby: "",
                ownerid: "",
                contactid: "",
                developerid: "",
                supportid: "",
                deliverytype: "pdf",
                level: 1,
                created: "",
                uploaded: "",
                updated: "",
                startfrom: "",
                expires: "",
                review: "",
                status: 1,
                keywords: "",
                fieldofstudy: "",
                lessonobjectives: "",
                targetaudience: "",
                prerequisites: "",
                enableonlyaftercourse: 1,
                upgradedtocourse: 1,
                alternatetocourse: 1,
                childofcourse: 1,
                autoruncoursewhenfinished: 1,
                cost: "",
                price: "",
                specialofferprice: "",
                maxpoints: "",
                passpoints: "",
                coverimage: "images/courses/image15.jpg",
                spineimage: "",
                summaryimage1: "",
                summaryimage2: "",
                summaryimage3: "",
                summaryimage4: "",
                locationdir: "",
                packagefilename: "",
                packageid: 1,
                userrating: 1,
                category: 1,
                orderno: 1,
            },
            {
                courseid: 7,
                siteid_fk: 1,
                coursecode: "",
                progress: "0",
                coursename: "Customer Buying Cycle",
                coursedescription: "Lorum ipsum dolor site amet, consequeter adipescing elit...",
                homepageid: 1,
                productid: 1,
                languageid: 1,
                vendorid: 1,
                expecteddurationtext: "",
                expecteddurationinhours: 1,
                versionno: 1,
                uploadedby: "",
                ownerid: "",
                contactid: "",
                developerid: "",
                supportid: "",
                deliverytype: "ear_screen",
                level: 1,
                created: "",
                uploaded: "",
                updated: "",
                startfrom: "",
                expires: "",
                review: "",
                status: 1,
                keywords: "",
                fieldofstudy: "",
                lessonobjectives: "",
                targetaudience: "",
                prerequisites: "",
                enableonlyaftercourse: 1,
                upgradedtocourse: 1,
                alternatetocourse: 1,
                childofcourse: 1,
                autoruncoursewhenfinished: 1,
                cost: "",
                price: "",
                specialofferprice: "",
                maxpoints: "",
                passpoints: "",
                coverimage: "images/courses/image16.jpg",
                spineimage: "",
                summaryimage1: "",
                summaryimage2: "",
                summaryimage3: "",
                summaryimage4: "",
                locationdir: "",
                packagefilename: "",
                packageid: 1,
                userrating: 1,
                category: 1,
                orderno: 1,
            },
            {
                courseid: 8,
                siteid_fk: 1,
                coursecode: "",
                progress: "50",
                coursename: "Dangerous Goods",
                coursedescription: "Lorum ipsum dolor site amet, consequeter adipescing elit...",
                homepageid: 1,
                productid: 1,
                languageid: 1,
                vendorid: 1,
                expecteddurationtext: "",
                expecteddurationinhours: 1,
                versionno: 1,
                uploadedby: "",
                ownerid: "",
                contactid: "",
                developerid: "",
                supportid: "",
                deliverytype: "person_screen",
                level: 1,
                created: "",
                uploaded: "",
                updated: "",
                startfrom: "",
                expires: "",
                review: "",
                status: 1,
                keywords: "",
                fieldofstudy: "",
                lessonobjectives: "",
                targetaudience: "",
                prerequisites: "",
                enableonlyaftercourse: 1,
                upgradedtocourse: 1,
                alternatetocourse: 1,
                childofcourse: 1,
                autoruncoursewhenfinished: 1,
                cost: "",
                price: "",
                specialofferprice: "",
                maxpoints: "",
                passpoints: "",
                coverimage: "images/courses/image17.jpg",
                spineimage: "",
                summaryimage1: "",
                summaryimage2: "",
                summaryimage3: "",
                summaryimage4: "",
                locationdir: "",
                packagefilename: "",
                packageid: 1,
                userrating: 1,
                category: 1,
                orderno: 1,
            },
            {
                courseid: 9,
                siteid_fk: 1,
                coursecode: "",
                progress: "50",
                coursename: "Diesel Diagnostics Videos",
                coursedescription: "Lorum ipsum dolor site amet, consequeter adipescing elit...",
                homepageid: 1,
                productid: 1,
                languageid: 1,
                vendorid: 1,
                expecteddurationtext: "",
                expecteddurationinhours: 1,
                versionno: 1,
                uploadedby: "",
                ownerid: "",
                contactid: "",
                developerid: "",
                supportid: "",
                deliverytype: "pdf",
                level: 1,
                created: "",
                uploaded: "",
                updated: "",
                startfrom: "",
                expires: "",
                review: "",
                status: 1,
                keywords: "",
                fieldofstudy: "",
                lessonobjectives: "",
                targetaudience: "",
                prerequisites: "",
                enableonlyaftercourse: 1,
                upgradedtocourse: 1,
                alternatetocourse: 1,
                childofcourse: 1,
                autoruncoursewhenfinished: 1,
                cost: "",
                price: "",
                specialofferprice: "",
                maxpoints: "",
                passpoints: "",
                coverimage: "images/courses/image18.jpg",
                spineimage: "",
                summaryimage1: "",
                summaryimage2: "",
                summaryimage3: "",
                summaryimage4: "",
                locationdir: "",
                packagefilename: "",
                packageid: 1,
                userrating: 1,
                category: 1,
                orderno: 1,
            },
        ],
        missions: [],
        introInstructions: { Row1Title: "Welcome", Row2Title: "to The Ivy House Award", text: "Scroll down to view your learning sessions and start with session 1, working through in order to complete The Award. Enjoy." },
        curriculums: [],
        curriculums2: [],
        leaderboard: [],
        storyboardimages: [],
        quizquestions: [],
        quizresults: {},
        salesLeads: {},
        teams: {},
        customers: {},
        projects: {
            myProjects: {},
            allCurrentProjects: {},
            myPastProjects: {},
            allPastProjects: {},
            currentProjectView: {
                project_id: "",
                tasks: {},
                team: {},
                meetings: {},
                forms: {},
            },
        },
        schedule: {},
        eventLog: {},
        library: {},
        messages: {},
        support: {
            faqs: {},
            blogs: {},
        },
        redware: {
            userinfo: {},
        },
        panels: [],
        categories: [
            { id: "1", title: "My Life", answer1: "", answer2: "" },
            { id: "2", title: "My Element", answer1: "", answer2: "" },
            { id: "3", title: "My Learning", answer1: "", answer2: "" },
            { id: "4", title: "My Inner Rhino", answer1: "", answer2: "" },
            { id: "5", title: "My Behaviour", answer1: "", answer2: "" },
            { id: "6", title: "My Communication", answer1: "", answer2: "" },
            { id: "7", title: "My Brand", answer1: "", answer2: "" },
            { id: "8", title: "My Thinking", answer1: "", answer2: "" },
            { id: "9", title: "My Power", answer1: "", answer2: "" },
            { id: "10", title: "My Vision", answer1: "", answer2: "" },

            { id: "11", title: "My Action", answer1: "", answer2: "" },
            { id: "12", title: "My Confidence", answer1: "", answer2: "" },
            { id: "13", title: "My Impact", answer1: "", answer2: "" },
            { id: "14", title: "My Relationships", answer1: "", answer2: "" },
            { id: "15", title: "My Conversations", answer1: "", answer2: "" },
            { id: "16", title: "My Tribe", answer1: "", answer2: "" },
            { id: "17", title: "My Wellness", answer1: "", answer2: "" },
            { id: "18", title: "My Leadership", answer1: "", answer2: "" },
            { id: "19", title: "My Pitch", answer1: "", answer2: "" },
            { id: "20", title: "My Choices", answer1: "", answer2: "" },
        ],
    },
};

export default pepData;
