<template>
    <div class="signup-form">
        <div class="card mb-0">
            <div class="card-body">
                <div class="text-center mb-3">
                    <img class="login-logo" v-bind:src="require(`@/${login_text.logo}`)" />
                    <span v-if="!success" class="d-block text-muted">{{ login_text.regsubtitle }}</span>
                </div>

                <div v-if="success" class="text-center">
                    <span>Your registration has been successful.</span>
                    <br />
                    <br />
                    <a v-on:click="loginAfterRegister()" style="cursor:pointer" class="ml-auto blue">Login</a>
                </div>
                <div v-else>

                    <div class="form-group form-group-feedback form-group-feedback-left">
                        <input tabindex="3" v-model="rcode" id="frm_rcode" type="number" class="form-control" v-bind:placeholder="login_text.rcode" maxlength="60">
                        <div class="form-control-feedback">
                            <i class="icon-lock2 text-muted"></i>
                        </div>
                    </div>

                    <div class="form-group form-group-feedback form-group-feedback-left">
                        <select tabindex="4" v-model="salutation" @change="onChange($event)" id="frm_salutation" class="form-control selectOptions initialState" v-on:click="removeClass('.selectOptions','initialState')">
                            <option value="" disabled selected>{{login_text.salutation}}</option>
                            <option value="miss">{{login_text.miss}}</option>
                            <option value="mr">{{login_text.mr}}</option>
                            <option value="mrs">{{login_text.mrs}}</option>
                            <option value="ms">{{login_text.ms}}</option>
                            <option value="other">{{login_text.other}}</option>
                        </select>
                        <div class="form-control-feedback">
                            <i class="icon-user text-muted"></i>
                        </div>
                    </div>

                    <div class="form-group form-group-feedback form-group-feedback-left" v-show="otherSalutationOpen">
                        <input tabindex="5" v-model="osalutation" id="frm_osalutation" type="text" class="form-control" v-bind:placeholder="login_text.salutation" maxlength="10">
                        <div class="form-control-feedback">
                            <i class="icon-user text-muted"></i>
                        </div>
                    </div>

                    <div class="form-group form-group-feedback form-group-feedback-left">
                        <input tabindex="6" v-model="fname" id="frm_fname" type="text" class="form-control" v-bind:placeholder="login_text.fname" maxlength="50">
                        <div class="form-control-feedback">
                            <i class="icon-user text-muted"></i>
                        </div>
                    </div>

                    <div class="form-group form-group-feedback form-group-feedback-left">
                        <input tabindex="7" v-model="lname" id="frm_lname" type="text" class="form-control" v-bind:placeholder="login_text.lname" maxlength="50">
                        <div class="form-control-feedback">
                            <i class="icon-user text-muted"></i>
                        </div>
                    </div>

                    <div class="form-group form-group-feedback form-group-feedback-left">
                        <input tabindex="8" v-model="email" id="frm_email" type="email" class="form-control" v-bind:placeholder="login_text.email" maxlength="100">
                        <div class="form-control-feedback">
                            <i class="icon-envelope text-muted"></i>
                        </div>
                    </div>

                    <div class="form-group form-group-feedback form-group-feedback-left">
                        <input tabindex="9" v-model="username" id="frm_username" autocomplete="off" type="text" class="form-control" v-bind:placeholder="login_text.username" maxlength="250">
                        <div class="form-control-feedback">
                            <i class="icon-user text-muted"></i>
                        </div>
                        <div id="uhint" class="text-muted" style="margin:5px 0;">Please enter a username. This can be your email address or just your name (e.g. joebloggs). However, your username must be unique, so if your choice has already been taken, you may need to add additional digits (e.g. joe.bloggs99).</div>
                    </div>

                    <div class="form-group form-group-feedback form-group-feedback-left">
                        <input tabindex="10" v-model="password" id="frm_password" autocomplete="off" v-bind:type="this.hidePassword" class="form-control" v-bind:placeholder="login_text.password" maxlength="50">
                        <div class="form-control-feedback">
                            <i class="icon-lock2 text-muted"></i>
                        </div>
                        <div id="phint" class="text-muted" style="margin:5px 0;">Your password must contain a capital letter, number and special character e.g. £$%.</div>
                        <div class="form-control-feedback icon-to-right" v-on:click="togglePassword()">
                            <i class="icon-eye text-muted"></i>
                        </div>
                    </div>

                    <div class="form-group form-group-feedback form-group-feedback-left">
                        <input tabindex="11" v-on:keyup.enter="register()" v-model="password2" id="frm_password2" autocomplete="off" v-bind:type="this.hideCPassword" class="form-control" v-bind:placeholder="login_text.confirmpassword" maxlength="50">
                        <div class="form-control-feedback">
                            <i class="icon-lock2 text-muted"></i>
                        </div>
                        <div class="form-control-feedback icon-to-right" v-on:click="togglePasswordC()">
                            <i class="icon-eye text-muted"></i>
                        </div>
                    </div>

                    <div>
                        <span class="d-block text-warning-800" style="padding-bottom:10px;" v-html="login_text.registererror"></span><label>
                            <input tabindex="12" v-model="agreeterms" type="checkbox" id="frm_terms" class="" style="margin-right:5px;" v-on:click="removeClass('#btnLogin','disabled')">
                            <span class="text-center text-muted">
                                <span>{{ login_text.terms1b }}</span> <a class="blue" v-on:click="changePage('/terms')">{{ login_text.terms2 }}</a> <span>{{ login_text.and }}</span> <a class="blue" v-on:click="changePage('/cookies')">{{ login_text.cookiepolicy }}</a>
                            </span>
                        </label>
                    </div>

                    <div class="form-group">
                        <div id="btnRegister" tabindex="13" v-on:keyup.enter="register()" v-on:click="register()" type="submit" class="btn btn-primary btn-block blue">
                            <span>{{ login_text.signup }}</span><i class="icon-circle-right2 ml-2"></i>
                        </div>
                    </div>

                    <div id="signin" class="form-group d-flex align-items-center" style="cursor:pointer;">
                        <a tabindex="14" v-on:keyup.enter="changePage('/')" v-on:click="changePage('/')" class="ml-auto blue">{{login_text.back}}</a>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {

        props: {
            token: Object,
            isloggedin: Boolean
        },
        data: function () {
            return {
                login_text: pep.ui_lang_components.login,
                username: "",
                password: "",
                canCreate: false,
                rcode: "",
                password2: "",
                fname: "",
                lname: "",
                salutation: "",
                osalutation: "",
                email: "",
                registered: false,
                agreeterms: false,
                otherSalutationOpen: false,
                hidePassword: "password",
                hideCPassword: "password",
                success: false
                //username: "peterv2demo3",
                //password: "Peter123!",
                //canCreate: false,
                //// rcode for demo curriculum
                //rcode: "341289",
                //password2: "Peter123!",
                //fname: "peter",
                //lname: "volosenco",
                //salutation: "mr",
                //osalutation: "",
                //email: "peter.volosenco4@purplemedia.co.uk",
                //registered: true,
                //agreeterms: true,
                //otherSalutationOpen: false,
                //hidePassword: "password",
                //hideCPassword: "password"
            };
        },
        methods: {
            register: function () {
                this.login_text.registererror = "";

                var fname_trim = this.fname.trim();
                var lname_trim = this.lname.trim();
                var email_trim = this.email.trim();


                this.fname = fname_trim;
                this.lname = lname_trim;
                this.email = email_trim;


                var errors = this.validateEmptyFields();

                if (errors == "") {
                    this.login_text.registererror = "";

                    var salutation_ = this.salutation == "other" ? this.osalutation : this.salutation;

                    pep.register(this.agreeterms, this.rcode, this.username, this.password, this.email, this.fname, this.lname, salutation_, this.checkregister);

                } else {
                    this.login_text.registererror = errors;
                }

            },
            checkregister: function (data) {
                if (data.success) {
                    console.log("WE ARE REGISTERED");

                    pep.emailRegistration(this.email, this.username, this.password);


                    this.registered = true;
                    this.success = true;
                }
            },
            loginAfterRegister: function () {
                pep.login(this.username, this.password);
            },
            changePage: function (pageurl) {
                this.login_text.registererror = "";
                this.$router.push(pageurl);
            },
            removeClass: function (el, c) {
                $(el).removeClass(c);
            },
            onChange: function (e) {

                if (e.target.value == "other") {
                    this.otherSalutationOpen = true;
                } else {
                    this.otherSalutationOpen = false;
                }
            },
            togglePassword: function () {
                console.log('toggle password');
                console.log(this.hidePassword);

                this.hidePassword = (this.hidePassword == "password" ? "text" : "password");
            },
            togglePasswordC: function () {
                console.log(this.hideCPassword);
                this.hideCPassword = (this.hideCPassword == "password" ? "text" : "password");
            },
            validateEmptyFields: function () {
                var errors = "";

                if (this.rcode == "")
                    errors += "Registration code field is empty. <br/>";

                if (this.salutation == "")
                    errors += "Salutation is not selected. <br/>";

                if (this.salutation == "other") {
                    if (this.osalutation == "") {
                        errors += "Salutation field is empty. <br/>";
                    } else {
                        if (!this.validateOnlyLetters(this.osalutation)) {
                            errors += "Salutation must contain letters only. <br/>";
                        }

                        if (this.osalutation.length > 10) {
                            errors += "Salutation must be less than 10 characters long. <br/>";
                        }
                    }
                }

                if (this.fname == "") {
                    errors += "First name field is empty. <br/>";
                } else {
                    if (!this.validateOnlyLetters(this.fname)) {
                        errors += "First name must contain letters only. <br/>";
                    }

                    if (this.fname.length > 50) {
                        errors += "First name must be less than 50 characters long. <br/>";
                    }
                }

                if (this.lname == "") {
                    errors += "Last name field is empty. <br/>";
                } else {
                    if (!this.validateOnlyLetters(this.lname)) {
                        errors += "Last name must contain letters only. <br/>";
                    }

                    if (this.lname.length > 50) {
                        errors += "Last name must be less than 50 characters long. <br/>";
                    }
                }

                if (this.email == "") {
                    errors += "Email field is empty. <br/>";
                } else {
                    if (!this.validateEmail(this.email))
                        errors += "Email provided isn't in the right format. <br/>";

                    if (this.email.length > 100) {
                        errors += "Email must be less than 100 characters long. <br/>";
                    }
                }

                if (this.username.length < 6) {
                    errors += "Username must be at least 6 characters long. <br/>";
                }

                if (this.username.length > 250) {
                    errors += "Username must be less than 250 characters long. <br/>";
                }

                if (this.password.length < 6) {
                    errors += "Password must be at least 6 characters long. <br/>";
                } else {

                    if (!this.validateHasLetters(this.password))
                        errors += "Password must have letters.<br/>";

                    if (!this.validateHasSymbols(this.password))
                        errors += "Password must have at least one symbol (!@#$%^&*+).<br/>";

                    if (!this.validateHasDigits(this.password))
                        errors += "Password must have at least one digit.<br/>";

                    if (this.password.length > 50) {
                        errors += "Password must be less than 50 characters long. <br/>";
                    }

                    if (this.username.length > 250) {
                        errors += "Username must be less than 250 characters long. <br/>";
                    }
                }

                if (this.password2 == "")
                    errors += "Confirm password field is empty. <br/>";

                if (this.password != this.password2 && this.password != "" && this.password2 != "")
                    errors += "Passwords don't match. <br/>";

                if (!this.agreeterms)
                    errors += "Terms & Conditions and Cookie Policy are not confirmed.";


                return errors;

            },
            validateEmail: function (email) {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(email).toLowerCase());
            },
            validatePassword: function (str) {
                // at least one number, one lowercase and one uppercase letter
                // at least six characters
                var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
                return re.test(str);
            },
            validateHasLetters: function (str) {
                var re = /(?=.*[a-zA-Z])/;
                return re.test(str);
            },
            validateHasDigits: function (str) {
                var re = /(?=.*[0-9])/;
                return re.test(str);
            },
            validateHasSymbols: function (str) {
                var re = /(?=.*[!@#$%^&*])/;
                return re.test(str);
            },
            validateOnlyLetters: function (str) {
                var re = /^[a-z ,.'-]+$/i;
                return re.test(str);
            },
        },
        watch: {
            isloggedin: function (val, oldval) {
                if (val === true) {
                    //we've been logged in so let's go to the home page
                    //this.$router.push('/home');
                    //this.$router.push('/video/intro');
                    console.log('here2');
                    console.log(pep.data.video[1].viewed);
                    if (pep.data.userdetail.udf1 == "1") {
                        this.$router.push('/home');
                    } else {
                        this.$router.push('/video/intro');
                    }
                }
            }
        },
    }
</script>

<style>

</style>