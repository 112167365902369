<template>
  <div class="content"><pep_storyboard_section_part_two></pep_storyboard_section_part_two></div>
</template>

<script>
export default {

}
</script>

<style>

</style>