<template>
    <div class="userdetail">
        <br />
        <table>
            <tr>
                <td style="width:60px" valign="top" rowspan="2">
                    <p><img v-on:click="getpicture" :src="avatarImage()" class="lb_userimage" style="width:100px; height:100px;margin:0px 40px 0px 0px;" /></p>
                </td>
                <td><p><span class="profile_name">{{user.firstname}} {{user.lastname}}</span><br /></p></td>
            </tr>
            <tr>
            <td><p>{{ user.school }}</p></td>
            </tr>
        </table>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                user: pep.data.user,
                userdetail: pep.data.userdetail
            };
        },
        computed: {
          
        },
        methods: {
            avatarImage: function () {
                return require(`@/${this.user.photo}`);
            },
            usersuccess: function (data) {
                console.log('success: ' + data);
                pep.data.user.userdetail = data[0];
                //pep.data.user.firstname = data[0].firstname;
                //pep.data.user.lastname = data[0].lastname;
                //pep.data.user.school = data[0].school;

                //stop imaghe caching
                var lower = 0;
                var upper = 100000000;
                var rndnum = (Math.floor(Math.random() * (upper - lower)) + lower);

                //sort photo
                if (data[0].avatarimage == "" || data[0].avatarimage == null || typeof data[0].avatarimage == 'undefined') {
                    //pep.data.user.photo = pep.config.services.imagepath + "users/default_user.jpg?r=" + rndnum;
                    pep.data.user.photo = "images/avatar.png?r=" + rndnum;
                } else {
                    pep.data.user.photo = pep.config.services.imagepath + data[0].avatarimage + "?r=" + rndnum;
                }

                var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
                pep.data.user.joined = new Date(data[0].started).toLocaleDateString("en-GB", options);
            },
            changedatejoined: function () {
                var myDate = pep.data.user.joined; // From model.

                //Can use this as a minimum date for date selector (Volvo founded date)
                var foundedDate = new Date("Thu Apr 14 1927 10:28:42 GMT+0100 (GMT+01:00)");
                var foundedDateUnix = new Date(-1348042117000);

                var dateoptions = {
                    mode: "date",
                    date: new Date(),
                    allowOldDates: true,
                    allowFutureDates: false,
                    minDate: foundedDateUnix,
                    maxDate: new Date(),
                    minuteInterval: 15,
                    locale: "en_gb",
                    okText: "Select",
                    cancelText: "Cancel",
                    androidTheme: 16974130
                };

                datePicker.show(dateoptions, this.dateSuccess, this.dateFail);

            },
            dateSuccess: function (date) {
                var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
                var handledDate = date.toLocaleDateString("en-GB", options);
                pep.data.user.joined = handledDate;
                //Call function below once API for updating user detail has been created.
                pep.updateJoinedDate(date.toISOString(), this.dateUploadSuccess, this.dateUploadFail);
            },
            dateFail: function () {
                console.error("An unexpected error occured with date selection");
                pep.data.alert.text = "Failed to update, please try again.";
                pep.data.alert.class = "active";
            },
            dateUploadSuccess: function () {
                pep.data.alert.text = "Start date updated.";
                pep.data.alert.class = "active";
            },
            dateUploadFail: function () {
                pep.data.alert.text = "Failed to update, please try again.";
                pep.data.alert.class = "active";
            },

            userfail: function () {
                //It's already retryed '
            },
            getpicture: function () {
                navigator.camera.getPicture(this.picsuccess, this.picfail, {
                    quality: 20,
                    targetWidth: 300,
                    targetHeight: 300,
                    destinationType: Camera.DestinationType.DATA_URL,
                    allowEdit: true,
                    correctOrientation: true,
                    encodingType: Camera.EncodingType.JPEG,
                    sourceType: Camera.PictureSourceType.CAMERA,
                });
            },
            picsuccess: function (imageData) {
                pep.data.user.photo = "data:image/jpeg;base64," + imageData;
                pep.updateImage(imageData, this.picUploadSuccess, this.picUploadFail);
            },
            picfail: function () {
                try {
                    if (pep.data.user.userdetail.avatarimage == "" || pep.data.user.userdetail.avatarimage == null || typeof pep.data.user.userdetail.avatarimage == 'undefined') {
                        pep.data.user.photo = pep.config.services.imagepath + "users/default_user.jpg";
                    } else {
                        pep.data.user.photo = pep.config.services.imagepath + data[0].avatarimage;
                    }
                }
                catch (err) {
                    console.warn("Camera cancelled and failed to put image in place. Should show on component reload.");
                }
                pep.data.alert.text = "Failed to upload image, please try again.";
                pep.data.alert.class = "active";

            },
            picUploadSuccess: function (url, data) {
                $('.ajax_loader').removeClass("enabled");
                try {
                    data[0].avatarimage = url;
                    pep.data.user.photo = pep.config.services.imagepath + data[0].avatarimage;
                } catch (err) {
                    console.log("Error on data[0]");
                };

                pep.data.alert.text = "Image successfully updated";
                pep.data.alert.class = "active";
                //pep.data.user.photo = pep.config.services.imagepath + url;
                $('.ajax_loader').removeClass("enabled");
            },
            picUploadFail: function () {
                pep.data.alert.text = "Failed to upload image, please try again.";
                pep.data.alert.class = "active";
                pep.data.user.photo = pep.config.services.imagepath + "avatar.png";
            }
        },
        mounted: function () {
            //Just check the user data
            //pep.getUserDetail(this.usersuccess, this.userfail);
            $.when(pep.getUserData()).done(function (userdata) {
                //console.log(userdata);
                if (userdata) {
                    this.usersuccess;
                    pep.data.user.school = userdata.Company;
                    pep.data.user.firstname = userdata.FirstName;
                    pep.data.user.lastname = userdata.LastName;
                    pep.data.user.photo = 'images/avatar.png';
                } else {
                    this.userfail;
                }
            });
        },
    }
</script>

<style>
</style>