<template>
  <div class="content"><pep_questionnaireTY></pep_questionnaireTY></div>
</template>

<script>
export default {

}
</script>

<style>

</style>