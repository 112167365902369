<template>
    <div :tabindex="this.index + 10" v-on:keyup.enter="goto()" v-on:click="goto()" :class="'tile ' + imageFullWidth" :style="{ backgroundImage: 'url('+ this.item.tileURL +')' }">
        <div>
            <span v-if="this.item.week != null" class="week">{{wNumTxt()}}</span>
            <div class="titleWrap" v-html="splitTitle"></div>
        </div>
        <div class="tileprogress" :style="{ width : progressWidth}"></div>
    </div>
</template>

<script>
    export default {
        props: ['item', 'curriculumid', 'index'],
        computed: {
            imageFullWidth: function () {
                return this.item.topFeaturedFullWidth ? 'current' : '';
            },
            linkurl: function () {
                "/week/" + this.item.id;
            },
            backgroundGrayScale: function () {
                var used_curriculum = pep.data.curriculums;

                if (document.location.href.indexOf('home_part_two') != -1) {
                    used_curriculum = pep.data.curriculums2;
                }
                var weeknumber = this.item.week;
                var weekname = this.item.week;
                if (this.item.targetaudience == 'Term 2') {
                    wknumArr = weeknumber.split(' ');
                    wknum = parseInt(wknumArr[1]);
                    weekname = wknumArr[0] + ' ' + (10 + wknum);
                }

                if (weeknumber != null) {
                    weeknumber = weeknumber.replace('Session ', '');
                }
                var bw = '';

                if (weeknumber > 1 && weeknumber <= 10) {
                    var previousComplete = false;
                    var countMandatory = 0, countComplete = 0;
                    var prevCourse = used_curriculum[weeknumber - 2].courses;

                    for (var i = 0; i < prevCourse.length; i++) {
                        if (prevCourse[i].Mandatory == true) {
                            countMandatory++;
                            if (prevCourse[i].isCompleted == true) {
                                countComplete++;
                            }
                        }
                    }

                    console.log('Mandatory: ' + countMandatory + ', Complete: ' + countComplete);
                    if (countMandatory != countComplete) {
                        //bw = '_grayscale';
                    }
                }

                if (document.location.href.indexOf('home_part_two') == -1) {
                    return 'background: url(' + pepData.data.generalURL + '/site/usermedia/images/w' + weeknumber + bw + '.jpg) center center;background-size:cover;';
                } else {
                    return 'background: url(' + pepData.data.generalURL + '/site/usermedia/images/session' + weeknumber + bw + '.jpg) center center;background-size:cover;';
                }
            },
            progressWidth: function () {
                return this.item.progress + '%';
            },
            splitTitle: function () {
                if (this.item.name != null) {
                    var arr = this.item.name.split(' ');

                    var title1 = arr[0], title2 = arr[1], title3 = '', title4 = '', title5 = '', s = '';

                    if (arr[2]) { title3 = ' ' + arr[2]; s = 'style="word-spacing:0;"'; }
                    if (arr[3]) { title4 = ' ' + arr[3]; s = 'style="word-spacing:0;"'; }
                    if (arr[4]) { title5 = ' ' + arr[4]; s = 'style="word-spacing:0;"'; }

                    var str = '<span class="title">' + title1 + '</span><br><span class="title"' + s + '>' + title2 + title3 + title4 + title5 + '</span>';

                    return str;
                }
            },
            greyScale: function () {
                var weeknumber = this.item.week;
                weeknumber = weeknumber.replace('Session ', '');
                console.log('week: ' + weeknumber);
            }
        },
        methods: {
            wNumTxt: function () {
                var weeknumber = this.item.week;
                var weekname = this.item.week;
                if (this.item.targetaudience == 'Term 2') {
                    wknumArr = weeknumber.split(' ');
                    wknum = parseInt(wknumArr[1]);
                    weekname = wknumArr[0] + ' ' + (10 + wknum);
                    //console.log('weekname: ' + weekname);
                }

                if (weeknumber != null) {
                    weeknumber = weeknumber.replace('Session ', '');
                }
                return weekname;
            },
            goto: function () {
                console.log("GO TO");
                console.log(this.item);

                pep.data.news.featured.week = this.item.week;
                pep.data.news.featured.title = this.item.name;
                pep.data.news.featured.imageUrl = this.item.tileURL;
                pep.data.news.featured.progress = this.item.progress;
                pep.data.news.featured.subtitle = this.item.description;


                if (this.item.id == 397 || this.item.id == 398) {
                    this.$router.push('/survey/' + this.item.id);
                } else {
                    this.$router.push('/week/' + this.item.id);
                }

            }
        },
    }
</script>

<style>
</style>