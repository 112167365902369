<template>
  <div class="coursedetail">
    
    <div class="cd_image" :style="backgroundStyle">
        
        <div class="progress_bar_background" style="bottom:0px;"></div>
        <div class="progress_bar" :style="'width:' + item_progress + '%'" style="bottom:0px"></div>
    </div>
    <div style="background-color:transparent;background-image:linear-gradient(transparent,black); width: 100%;height: 20px;margin-top: -20px;position: relative;"></div>
    <div class="cd_info">
        
        <div class="cd_left_info">
            
            <div class="cd_title">{{filteredCourses[0].coursename}}</div>
            <div class="cd_summary">{{filteredCourses[0].coursedescription}}</div>
        </div>
        <div class="cd_right_info">
            
            <div class="cd_stars">
                
                <div class="cd_like"><img v-on:click="setLike" src="images/Icons/like-solid-white.svg" class="like_icon" /> {{likes}}</div>
            </div>
        </div>
    </div>
    <div class="cd_buttons">
        
        <div class="cd_btn cd_btn_start" v-on:click="launchContent()">START</div>
        <div class="cd_btn cd_btn_book" v-on:click="goBack()">BACK</div>
    </div>
    <div class="cd_related">
        
        <div class="subheading">Related Content</div>
        <pep_course_summary_item 
                                 v-for="(item, index) in completeRelated" 
                                 v-bind:item="item" 
                                 v-bind:index="index" 
                                 v-bind:key="item.id" ></pep_course_summary_item>
    </div>
    <div id="loadedContent" style="position:fixed;top:0;left:0;width:100%;height:100%;background:#ffffff;display:none;z-index:1000;">
        
    </div>
</div>
</template>

<script>
export default {

        data: function () {
            return {
                curriculums: pep.data.curriculums,
                certifications: pep.data.certifications,
                includestars: false,
                likes: 0
            };
        },
        computed: {
            filteredCurriculums: function () {
                var routeCurriculumid = this.$route.params.curriculumid;

                return this.curriculums.filter(function (item) {
                    return item.id == routeCurriculumid;
                });
            },
            filteredCourses: function () {
                var routeCourseId = this.$route.params.courseid;

                return this.filteredCurriculums[0].courses.filter(function (item) {
                    return item.courseid == routeCourseId;
                });
            },
            filteredRelated: function () {
                var routeCourseId = this.$route.params.courseid;

                return this.filteredCurriculums[0].courses.filter(function (item) {
                    return item.courseid != routeCourseId;
                });
            },
            completeRelated: function () {
                return this.filteredCurriculums[0].courses.filter(function (item) {
                    return item.courseid;
                });
            },
            certification: function () {
                return this.certifications.filter(function (item) {
                    return item.id == this.filteredCurriculums[0].certificationid;
                });
            },
            backgroundStyle: function () {
                var pos = 'center center';
                if (this.filteredCourses[0].coverimage.indexOf('CoverImage.jpg') > 0) {
                    pos = 'center 10%!important';
                }
                return 'background-image: url(' + this.filteredCourses[0].coverimage + ');background-position:' + pos;
                //return 'background-image: url(' + this.filteredCourses[0].coverimage + ');';
            },
            item_progress: function () {
                return this.filteredCourses[0].progress;
            }

        },
        methods: {
            loadContent: function (cid, cname, curriculumid) {

                var courseULR = pep.config.services.generalURL + "/wdc2013/lmstokenauth.aspx?token=" + pep.data.login.token.access_token +
                    "&userName=" + pep.data.login.token.username +
                    "&courseName=" + cname +
                    "&courseid=" + cid +
                    "&curriculumId=" + curriculumid;

                console.log(courseULR);
                console.log('Current URL: ' + window.location.href);
                localStorage.setItem('scormback', window.location.href);
                window.open(courseULR, 'playerWin', 'fullscreen=yes, toolbar=no, resizable=yes, status=yes, titlebar=yes, scrollbars=yes');
                //window.open(courseULR, '_self', 'fullscreen=yes, toolbar=no, resizable=yes, status=yes, titlebar=yes, scrollbars=yes');
            },
            updateLikes: function (l) {
                this.likes = l;
            },
            setLike: function () {
                pep.setLike(1, this.filteredCourses[0].CourseID, this.updateLikes, this.likes);
            },
            goBack: function () {
                if (pep.data.settings.temp_redirect != "") {
                    pep.initData();
                } else {
                    this.$router.push('/week/' + this.$route.params.curriculumid);
                }

            },
            backToMenu: function () {
                this.$router.push('/week');
            },
            launchContent: function () {
                pep.data.settings.temp_redirect = '/week/' + this.$route.params.curriculumid;
                this.loadContent(this.filteredCourses[0].courseid, this.filteredCourses[0].coursename, this.filteredCourses[0].curriculumid);
            }
        },
        created: function () {
            //Get the initial Likes
            pep.getLikes(1, this.filteredCourses[0].CourseID, this.updateLikes);
            pep.addView(this.filteredCourses[0].CourseID);

            // certification & filteredCourses doesn't exist in this context 
            //'<div class="coursedetail" :class="certification[0].class + \' cd_\' + filteredCourses[0].deliverytype">\
        },
}
</script>

<style>

</style>