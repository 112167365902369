<template>
    <div class="content">
        <pep_netflix_header></pep_netflix_header> <br/>
        <pep_netflix_section></pep_netflix_section>
    </div>
</template>

<script>
    import pep_netflix_header from '@/components/pep_netflix_header.vue'
    import pep_netflix_section from '@/components/pep_netflix_section.vue'

    export default {
        components: {
            pep_netflix_header,
            pep_netflix_section
        }
    }
</script>

<style>
</style>