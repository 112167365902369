<template>
  <div class="content"><pep_terms></pep_terms></div>
</template>

<script>
    import pep_terms from '@/components/pep_terms.vue'
    export default {
        components: {
            pep_terms
        }
    }
</script>

<style>

</style>