<template>
    <div class="notification_page">
        <div class="featured_article">
            <h1>{{notification_text.title}}</h1>
            <h2>
                <span>{{notification_text.subtitle}}</span>
                <br /><br />
                <span v-if="false">{{notification_text.subtitle2}}</span>
            </h2>
            <br/>
            <pep_notification_item v-for="(notification, index) in notifications"
                                   v-bind:item="notification"
                                   v-bind:index="index"
                                   v-bind:key="notification.id"></pep_notification_item>
            <br />
        </div>
    </div>
</template>

<script>
    import pep_notification_item from '@/components/pep_notification_item.vue'
    export default {
        data: function () {
            return {
                notifications: pep.data.notifications,
                notification_text: pep.ui_lang_components.notifications
            };
        },
        mounted: function () {
            //Fire of a check to make sure we're not missing anything
            pep.getNotifications(this.loadNotifications, this.loadNotificationsFailed);
        },
        methods: {
            loadNotifications: function (d) {
                var self = this;
                $.each(d, function (i, o) {
                    var match = false;
                    //Check if it's already there, if not add it.
                    $.each(self.notifications, function (ii, oo) {
                        if (o.ContentID == oo.ContentID) {
                            match = true;
                        }
                    });
                    if (match === false) {
                        self.notifications.push(o);
                    }
                });
            },
            loadNotificationsFailed: function (d) {

            }
        },
        components: {
            pep_notification_item,
        }
    }
</script>

<style>
</style>