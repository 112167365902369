<template>
  <div class="content"><pep_cookies></pep_cookies></div>
</template>

<script>
    import pep_cookies from '@/components/pep_cookies.vue'
    export default {
        components: {
            pep_cookies
        }
    }
</script>

<style>

</style>