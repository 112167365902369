<template>
  <pep_awards></pep_awards>
</template>

<script>
export default {

}
</script>

<style>

</style>