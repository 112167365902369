<template>
<div id="topbarapp" class="navbar navbar-expand-md navbar-light navbar-static">
    <div id="navbar_inner">
        <div id="navbar_inner_row_top">
            <div id="navbar_row1_column1">
                <a v-on:keyup.enter="changePage(home.url)" v-on:click="changePage(home.url)" style="cursor:pointer;">
                    <img id="navbar-logo" src="@/images/logos/ivy_house_logo_white.png" tabindex="1" aria-label="Home" />
                </a>
            </div>
            <div id="navbar_row1_column2">
                <div class="" id="welcomeName" style="display:none;" v-if="haveName && login.isLoggedIn">{{welcome}} {{user.firstname}}</div>
            </div>
            <div id="navbar_row1_column3">
                <button v-if="login.isLoggedIn" v-html="svgbars()" class="navbar-toggler" name="toggleMenu" type="button" v-on:click="toggleMenu" tabindex="2" aria-label="Menu"></button>
            </div>
        </div>
        <div id="navbar_inner_row_menuitems" v-if="menuclass != ''" :class="menuclass">
            <div>
                <ul class="navbar-nav">
                    <li><span tabindex="3" v-on:keyup.enter="changePage('/video/intro')" v-on:click="changePage('/video/intro')">Introduction Video</span></li>
                    <li><span tabindex="4" v-on:keyup.enter="changePage(home.url)" v-on:click="changePage(home.url)">Home</span></li>
                    <li><span tabindex="5" v-on:keyup.enter="openLMS()" v-if="isTeacher()" v-on:click="openLMS()">Facilitator View</span></li>
                    <li><span tabindex="6" v-on:keyup.enter="openFacilitatorContent()" v-if="isTeacher()" v-on:click="openFacilitatorContent()">Facilitator Support Centre</span></li>
                    <li><span tabindex="7" v-on:keyup.enter="changePage(actionplan.url)" v-on:click="changePage(actionplan.url)">My Action Plan</span></li>
                    <li><span tabindex="8" v-on:keyup.enter="changePage(notifications.url)" v-on:click="changePage(notifications.url)">Notifications</span></li>
                    <li><span tabindex="8" v-on:keyup.enter="changePage(questions.url)" v-on:click="changePage(questions.url)">Contact Us</span></li>
                    <li><span tabindex="9" v-on:keyup.enter="logout()" v-on:click="logout()">Log out</span></li>
                </ul>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name:'pep_navbar',
    props: {
        token: Object
    },
    data: function () {
        return {
            welcome: pep.ui_lang.components.navbar.welcome,
            navbar_text: pep.ui_lang_components.navbar,
            current_certification: "",
            menuclass: "",
            user: pep.data.user,
            login: pep.data.login,
            services: pep.config.services,
            home: pep.config.defaultPages[1],
            actionplan: pep.config.defaultPages[2],
            notifications: pep.config.defaultPages[3],
            questions: pep.config.defaultPages[4],
        };
    },
    computed: {
        haveName: function () {
            return this.user.firstname !== '';
        },
    },
    methods: {
        isTeacher: function () {
            if (pep.data.userdetail == null) {
                return false;
            } else {
                //not student
                return pep.data.userdetail.JobTitle_FK.toUpperCase() != "CC132028-76D5-4094-A9F1-9C2AE24DDAE1";
            }
        },
        svgbars: function () {
            //IE 11 gives error if this added directly in the template
            return '<svg xmlns="http://www.w3.org/2000/svg" xmlns: xlink = "http://www.w3.org/1999/xlink" name="svg_togglemenu" viewBox="0 0 24 24" version="1.1" width="24px" height="24px" >\
                    <g id="surface1" name="surface1" >\
                        <path class="burger_menu" name="burger_menu" d="M 0 2 L 0 4 L 24 4 L 24 2 Z M 0 11 L 0 13 L 24 13 L 24 11 Z M 0 20 L 0 22 L 24 22 L 24 20 Z " />\
                    </g>\
                </svg>';
        },
        toggleMenu: function () {
            $('.savingprogress').remove();

            if (this.menuclass == "") {
                this.menuclass = "enabled";
            } else {
                this.menuclass = "";
            }
        },
        openFacilitatorContent() {
            var self = this;

            var facilitator_link = '';
            
            if (!this.login.isLoggedIn) {
                pageurl = "/";
            }

            if (typeof cordova == 'undefined') {

                facilitator_link = '/lms/375/' + encodeURIComponent(pep.config.services.generalURL + '/site/usermedia/lmspackages/IvyHouseV2Teacher/');
                self.$router.push(facilitator_link);

            } else {
                facilitator_link = pep.config.services.generalURL + 'site/usermedia/lmspackages/IvyHouseV2Teacher/';
                var ref = pep.CordovaLinkOpen(facilitator_link);
            }

            self.menuclass = "";
        },
        openLMS: function () {
            pep.getTokenValidation().done(function (data) {
                if (data == "200") {
                    var lms_link =  "/wdc2013/FacilitatorView.aspx?username=" + pep.data.userdetail.UserName + "&token=" + pep.data.login.token.access_token;

                    if (typeof cordova == 'undefined') {
                        window.open(lms_link);
                    } else {
                        var ref = pep.CordovaLinkOpen(pep.config.services.generalURL + lms_link);
                    }

                } else {
                    pep.data.isLoggedIn = false;
                    this.changePage("/");
                }
            });
        },
        logout: function () {
            pep.logout();
            this.menuclass = "";
        },
        changePage: function (newPageURL) {
            this.menuclass = "";
            pep.goToPageByURL(newPageURL);
        }
    },
}
</script>

<style>

</style>
