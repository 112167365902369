<template>
  <div class="content"><pep_question></pep_question></div>
</template>

<script>
    import pep_question from '@/components/pep_question.vue'

    export default {
        components: {
            pep_question
        }
    }
</script>

<style>

</style>