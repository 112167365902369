<template>
    <div class="content prinline"><pep_actiontable></pep_actiontable></div>
</template>

<script>
    export default {

    }
</script>

<style>
</style>