<template>
  <div class="content"><pep_notifications></pep_notifications></div>
</template>

<script>
    import pep_notifications from '@/components/pep_notifications.vue'
    export default {
        components: {
            pep_notifications,
        }
    }
</script>

<style>

</style>