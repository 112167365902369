<template>
  <div class="questions">
    <div class="helpContent featured_article">
        <h1 style="font-size:2rem; margin-bottom:20px;">Part Two</h1>
        <h1 style="font-size:5vw">Facilitator questionnaire</h1>
        <div class="featured_article">
            <div id="contact-form" class="contact-form">
                <div class="separator"></div>
                <form class="form" style="font-size:1.5rem">
                    <div>
                        <label for="recommend10" class="question">1. Would you recommend The Award?</label>
                        <div style="float:left;width:20%;text-align:right">Not at all</div>
                        <input class="question" type="radio" v-model="formd.recommend" id="recommend0" name="recommend" value="0" style="float:left;width:5%;margin-top:10px">
                        <input class="question" type="radio" v-model="formd.recommend" id="recommend1" name="recommend" value="1" style="float:left;width:5%;margin-top:10px">
                        <input class="question" type="radio" v-model="formd.recommend" id="recommend2" name="recommend" value="2" style="float:left;width:5%;margin-top:10px">
                        <input class="question" type="radio" v-model="formd.recommend" id="recommend3" name="recommend" value="3" style="float:left;width:5%;margin-top:10px">
                        <input class="question" type="radio" v-model="formd.recommend" id="recommend4" name="recommend" value="4" style="float:left;width:5%;margin-top:10px">
                        <input class="question" type="radio" v-model="formd.recommend" id="recommend5" name="recommend" value="5" style="float:left;width:5%;margin-top:10px">
                        <input class="question" type="radio" v-model="formd.recommend" id="recommend6" name="recommend" value="6" style="float:left;width:5%;margin-top:10px">
                        <input class="question" type="radio" v-model="formd.recommend" id="recommend7" name="recommend" value="7" style="float:left;width:5%;margin-top:10px">
                        <input class="question" type="radio" v-model="formd.recommend" id="recommend8" name="recommend" value="8" style="float:left;width:5%;margin-top:10px">
                        <input class="question" type="radio" v-model="formd.recommend" id="recommend9" name="recommend" value="9" style="float:left;width:5%;margin-top:10px">
                        <input class="question" type="radio" v-model="formd.recommend" id="recommend10" name="recommend" value="10" style="float:left;width:5%;margin-top:10px">
                        <div style="float:left;width:20%">Absolutely</div>
                    </div>
                    <label class="question">2. Which of the following changes have you seen in your students:</label>
                    <br />
                    <label class="question">More skilled to build own confidence</label>
                    <br />
                    <div>
                        <input class="question" type="radio" v-model="formd.changes1" id="changes1yes" name="changes1" value="Yes" style="float:left;width:10%;margin-top:10px">
                        <label style="float:left;width:90%" for="changes1">Yes</label>
                    </div>
                    <div>
                        <input type="radio" class="question" v-model="formd.changes1" id="changes1no" name="changes1" value="No" style="float:left;width:10%;margin-top:10px">
                        <label style="float:left;width:90%" for="changes1no" class="question">No</label>
                    </div>
                    <label class="question"> More skilled at pitching themselves</label>
                    <br />
                    <div>
                        <input class="question" type="radio" v-model="formd.changes2" id="changes2yes" name="changes2" value="Yes" style="float:left;width:10%;margin-top:10px">
                        <label style="float:left;width:90%" for="changes2">Yes</label>
                    </div>
                    <div>
                        <input type="radio" class="question" v-model="formd.changes2" id="changes2no" name="changes2" value="No" style="float:left;width:10%;margin-top:10px">
                        <label style="float:left;width:90%" for="changes2no" class="question">No</label>
                    </div>
                    <label class="question"> More skilled at creating a positive impact</label>
                    <br />
                    <div>
                        <input class="question" type="radio" v-model="formd.changes3" id="changes3yes" name="changes3" value="Yes" style="float:left;width:10%;margin-top:10px">
                        <label style="float:left;width:90%" for="changes3">Yes</label>
                    </div>
                    <div>
                        <input type="radio" class="question" v-model="formd.changes3" id="changes3no" name="changes3" value="No" style="float:left;width:10%;margin-top:10px">
                        <label style="float:left;width:90%" for="changes3no" class="question">No</label>
                    </div>
                    <label class="question"> More skilled at improving personal wellness - physical and mental</label>
                    <br />
                    <div>
                        <input class="question" type="radio" v-model="formd.changes4" id="changes4yes" name="changes4" value="Yes" style="float:left;width:10%;margin-top:10px">
                        <label style="float:left;width:90%" for="changes4">Yes</label>
                    </div>
                    <div>
                        <input type="radio" class="question" v-model="formd.changes4" id="changes4no" name="changes4" value="No" style="float:left;width:10%;margin-top:10px">
                        <label style="float:left;width:90%" for="changes4no" class="question">No</label>
                    </div>
                    <label class="question">More skilled at building better relationships</label>
                    <br />
                    <div>
                        <input class="question" type="radio" v-model="formd.changes5" id="changes5yes" name="changes5" value="Yes" style="float:left;width:10%;margin-top:10px">
                        <label style="float:left;width:90%" for="changes5">Yes</label>
                    </div>
                    <div>
                        <input type="radio" class="question" v-model="formd.changes5" id="changes5no" name="changes5" value="No" style="float:left;width:10%;margin-top:10px">
                        <label style="float:left;width:90%" for="changes5no" class="question">No</label>
                    </div>
                    <label class="question">More skilled at having meaningful, effective conversations</label>
                    <br />
                    <div>
                        <input class="question" type="radio" v-model="formd.changes6" id="changes6yes" name="changes6" value="Yes" style="float:left;width:10%;margin-top:10px">
                        <label style="float:left;width:90%" for="changes6">Yes</label>
                    </div>
                    <div>
                        <input type="radio" class="question" v-model="formd.changes6" id="changes6no" name="changes6" value="No" style="float:left;width:10%;margin-top:10px">
                        <label style="float:left;width:90%" for="changes6no" class="question">No</label>
                    </div>
                    <label class="question">More skilled at attracting a supportive tribe</label>
                    <br />
                    <div>
                        <input class="question" type="radio" v-model="formd.changes7" id="changes7yes" name="changes7" value="Yes" style="float:left;width:10%;margin-top:10px">
                        <label style="float:left;width:90%" for="changes7">Yes</label>
                    </div>
                    <div>
                        <input type="radio" class="question" v-model="formd.changes7" id="changes7no" name="changes7" value="No" style="float:left;width:10%;margin-top:10px">
                        <label style="float:left;width:90%" for="changes7no" class="question">No</label>
                    </div>
                    <label class="question">More skilled at leading others</label>
                    <br />
                    <div>
                        <input class="question" type="radio" v-model="formd.changes8" id="changes8yes" name="changes8" value="Yes" style="float:left;width:10%;margin-top:10px">
                        <label style="float:left;width:90%" for="changes8">Yes</label>
                    </div>
                    <div>
                        <input type="radio" class="question" v-model="formd.changes8" id="changes8no" name="changes8" value="No" style="float:left;width:10%;margin-top:10px">
                        <label style="float:left;width:90%" for="changes8no" class="question">No</label>
                    </div>
                    <label class="question">More skilled at taking action</label>
                    <br />
                    <div>
                        <input class="question" type="radio" v-model="formd.changes9" id="changes9yes" name="changes9" value="Yes" style="float:left;width:10%;margin-top:10px">
                        <label style="float:left;width:90%" for="changes9">Yes</label>
                    </div>
                    <div>
                        <input type="radio" class="question" v-model="formd.changes9" id="changes9no" name="changes9" value="No" style="float:left;width:10%;margin-top:10px">
                        <label style="float:left;width:90%" for="changes9no" class="question">No</label>
                    </div>
                    <label class="question">3. Based on your experience to date, what could be improved?</label><br />
                    <textarea class="question" name="improve" v-model="formd.improve" rows="4"></textarea>
                    <label class="question">4. What additional support content would have been useful to help you facilitate The Award - part 2?</label>
                    <br />
                    <textarea class="question" name="additionalSupport" v-model="formd.additionalSupport" rows="4"></textarea>
                    <span class="question button" v-on:click="senddata">Submit</span>
                </form>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {

  data: function () {
    return {
      formd: {
        area1: '',
        area2: '',
        area3: '',
        area4: '',
        area5: '',
        area6: '',
        area7: '',
        area8: '',
        area9: '',
        applied: '',
        appliedDetail: '',
        improveLearning: '',
        userid: ''
      }
    };
  },
  mounted: function () {
    //this.checkResponse();
  },
  created: function () {
    this.checkResponse()
  },
  methods: {
    clearForm: function () {
      for (let field in this.formd) {
        this.formd[field] = '';
      }
    },

    checkResponse: function () {
      var bool = false;
      bool = pep.checkSurveyTerm2();
      //alert(bool)
      //if (bool) {

      //    pep.gotoRoute('/questionnaireComplete');
      //    bool = false;
      //};
    },
    senddata: function (e) {
      e.preventDefault;
      var recommend = this.formd.recommend;
      var changes1 = this.formd.changes1;
      var changes2 = this.formd.changes2;
      var changes3 = this.formd.changes3;
      var changes4 = this.formd.changes4;
      var changes5 = this.formd.changes5;
      var changes6 = this.formd.changes6;
      var changes7 = this.formd.changes7;
      var changes8 = this.formd.changes8;
      var changes9 = this.formd.changes9;
      var improve = this.formd.improve;
      var additionalSupport = this.formd.additionalSupport;
      var userid = pep.data.userdetail.ID;

      pep.sendFacilitatorQuestionnaireResponsesTerm2(recommend, changes1, changes2, changes3, changes4, changes5, changes6, changes7, changes8, changes9, improve, additionalSupport, userid);
      var url = location.href;
      var newurl = url.split('/').slice(0, -1).join('/') + '/quesionnairethankyou';
      location.href = newurl;
    },
    senddatafailed: function () {
      //
    },
    senddatasuccess: function () {
      this.formd.recommend = "";
      this.formd.changes1 = "";
      this.formd.changes2 = "";
      this.formd.changes3 = "";
      this.formd.changes4 = "";
      this.formd.changes5 = "";
      this.formd.changes6 = "";
      this.formd.changes7 = "";
      this.formd.changes8 = "";
      this.formd.changes9 = "";
      this.formd.improve = "";
      this.formd.additionalSupport = "";
      this.formd.userid = "";
    }
  },
}
</script>

<style>

</style>