<template>
  <div class="content prinline"><pep_radar_preview></pep_radar_preview><pep_actiontable_preview></pep_actiontable_preview></div>
</template>

<script>
export default {

}
</script>

<style>

</style>