<template>
    <div class="questions">
        <div class="helpContent featured_article">
            <h1 class="title">Ask a question</h1>

            <div v-if="isSent == false" class="" style="margin-top:3rem;">
                <div id="contact-form" class="contact-form">
                    <div class="separator"></div>
                    <form class="form">
                        <input tabindex="5" maxlength="40" class="question" required name="name" v-model="contact.name" placeholder="Name" type="text" autocomplete="off" />
                        <input tabindex="6" maxlength="40" class="question" required name="email" v-model="contact.email" placeholder="E-mail" type="email" autocomplete="off" />
                        <label class="question">
                            Which session does your question relate to?<br />
                            <select tabindex="7" v-model="contact.session">
                                <option>Session 1</option>
                                <option>Session 2</option>
                                <option>Session 3</option>
                                <option>Session 4</option>
                                <option>Session 5</option>
                                <option>Session 6</option>
                                <option>Session 7</option>
                                <option>Session 8</option>
                                <option>Session 9</option>
                                <option>Session 10</option>
                                <option>Session 11</option>
                                <option>Session 12</option>
                                <option>Session 13</option>
                                <option>Session 14</option>
                                <option>Session 15</option>
                                <option>Session 16</option>
                                <option>Session 17</option>
                                <option>Session 18</option>
                                <option>Session 19</option>
                                <option>Session 20</option>
                            </select>
                        </label>
                        <div class="characters-left" v-html="charactersLeft()"></div>
                        <textarea tabindex="8" maxlength="2000" class="question" name="message" v-model="contact.message" rows="4" placeholder="Type your question here..."></textarea>
                        <div><span class="d-block text-warning-800" style="padding-bottom:10px;" v-html="errors"></span></div>
                        <span tabindex="9" v-on:keyup.enter="sendemail" v-on:click="sendemail" :class="'question button ' + (suspendSubmit ? 'suspend' : '')" style="cursor:pointer;">Send</span>
                    </form>
                </div>
            </div>

            <h2 v-if="isSent == true">Your question has been submitted. Thank you!</h2>

            <div class="loading-overlay" v-if="loading"></div>
        </div>
    </div>
</template>

<style scoped>
.loading-overlay {
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.8) url(../images/loading.gif) no-repeat center center;
}

.characters-left {
    font-size: 0.875rem;
}

.suspend {
    opacity: 0.7;
    pointer-events: none;
}
</style>

<script>
export default {
    data: function() {
        return {
            contact: {
                name: pep.data.userdetail.FirstName + " " + pep.data.userdetail.LastName,
                email: pep.data.userdetail.Email,
                message: "",
                session: "",
            },
            errors: "",
            isSent: false,
            loading: false,
            suspendSubmit: false,
        };
    },
    methods: {
        charactersLeft: function() {
            var self = this;

            var amountLeft = 1000;

            if (self.contact == null) return "";

            if (self.contact.message != null) {
                amountLeft = 2000 - self.contact.message.length;
            }

            return amountLeft + " characters left";
        },
        clearForm: function() {
            for (let field in this.contact) {
                this.contact[field] = "";
            }
        },
        sendemail: function(e) {
            var self = this;
            e.preventDefault;

            if (!self.suspendSubmit) {
                var emailaddress = this.contact.email;
                var name = this.contact.name;
                var message = "QUESTION: " + this.contact.message;
                var session = this.contact.session;

                var errors = self.validateEmptyFields();

                //TODO FIX EMAIL TARGET

                //info@ivyhouse.purplemediacloud.co.uk

                self.loading = true;

                var adminEmail = pep.data.settings.administrator_email;

                if (adminEmail == null) adminEmail = "support@ivyhouse.co.uk";

                if (errors == "") {
                    pep.sendmsg(adminEmail, "", emailaddress, "A Question has been asked at Ivy House Award by " + name + " about session " + session, message, null, null, self.sendmsgsuccess, self.sendmsgfailed);

                    //pep.sendmsg("peter.volosenco@purplemedia.co.uk", "", emailaddress, "A Question has been asked at Ivy House Award by " + name + " about session " + session, message, null, null, this.sendmsgsuccess, this.sendmsgfailed);
                } else {
                    self.errors = errors;
                }
            }
        },
        sendmsgfailed: function(status) {
            var self = this;
            self.loading = false;

            status = Number(status);

            switch (status) {
                case 500:
                    self.suspendSubmit = true;
                    self.errors = "An error occurred when saving, a developer will be notified. Please report this issue to a Teacher/Facilitator.";
                    break;

                default:
                    self.errors = "An error occurred when saving. Please check your connection.";
                    break;
            }
        },
        sendmsgsuccess: function(status) {
            var self = this;
            self.loading = false;

            self.contact.email = "";
            self.contact.name = "";
            self.contact.session = "";
            self.contact.message = "";

            self.isSent = true;
            self.errors = "";

            //open back the form after 10 seconds
            setTimeout(function() {
                self.isSent = false;
                self.errors = "";
            }, 5000);
        },
        validateEmptyFields: function() {
            var errors = "";

            if (this.contact.name == "") {
                errors += "Name field is empty. <br/>";
            } else {
                if (!this.validateOnlyLetters(this.contact.name)) {
                    errors += "Name must contain letters only. <br/>";
                }

                if (this.contact.name.length > 40) {
                    errors += "Name must be less than 40 characters long. <br/>";
                }
            }

            if (this.contact.email == "") {
                errors += "Email field is empty. <br/>";
            } else {
                if (!this.validateEmail(this.contact.email)) errors += "Email provided isn't in the right format. <br/>";

                if (this.contact.email.length > 40) {
                    errors += "Email must be less than 40 characters long. <br/>";
                }
            }

            if (this.contact.session == "") {
                errors += "Session field is empty.<br/>";
            }

            if (this.contact.message == "") {
                errors += "Message field is empty. <br/>";
            } else {
                if (this.contact.message.length < 20) {
                    errors += "Message must be more than 20 characters long. <br/>";
                }
            }

            return errors;
        },
        validateEmail: function(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        validatePassword: function(str) {
            // at least one number, one lowercase and one uppercase letter
            // at least six characters
            var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
            return re.test(str);
        },
        validateHasLetters: function(str) {
            var re = /(?=.*[a-zA-Z])/;
            return re.test(str);
        },
        validateHasDigits: function(str) {
            var re = /(?=.*[0-9])/;
            return re.test(str);
        },
        validateHasSymbols: function(str) {
            var re = /(?=.*[!@#$%^&*])/;
            return re.test(str);
        },
        validateOnlyLetters: function(str) {
            var re = /^[a-z ,.'-]+$/i;
            return re.test(str);
        },
    },
};
</script>
