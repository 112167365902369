import { render, staticRenderFns } from "./pep_storyboard_section.vue?vue&type=template&id=640ba1ad&scoped=true&"
import script from "./pep_storyboard_section.vue?vue&type=script&lang=js&"
export * from "./pep_storyboard_section.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "640ba1ad",
  null
  
)

export default component.exports