<template>
  <div class="storyboard_section">
    
    <div class="featured_article">
        
        <h1 class="title">Your Storyboard</h1> 
        <h1 class="title2">Cow or Rhino?</h1> 
        <br />
        <p>Are you a Cow or a Rhino?  Complete this section by choosing which you think you are?</p>
        <br />
    </div>
    <div>
        
        <div class="tile" v-on:click="choose(1)" style="background: rgba(0, 0, 0, 0) url(&quot;images/tiles/cow.png&quot;) repeat scroll center center / cover;" index="7"><div><span class="week"></span><div class="titleWrap"><span class="title">A</span><br><span class="title">Cow</span></div></div></div>
        <div class="tile" v-on:click="choose(2)" style="background: rgba(0, 0, 0, 0) url(&quot;images/tiles/rhino.png&quot;) repeat scroll center center / cover;" index="7"><div><span class="week"></span><div class="titleWrap"><span class="title">A</span><br><span class="title">Rhino</span></div></div></div>
    </div>
</div>
</template>

<script>
export default {
    data: function () {
        return {
            curriculums: pep.data.curriculums,
            featured: pep.data.featured,
            settings: pep.data.settings
        };
    },
    methods: {
        closeFeatured: function () {
            this.settings.storyboard_viewed = true;
            $('.featured_article').addClass("closed");
        },
        choose: function (id) {
            if (id == 1) {
                pep.data.curriculums[1].tileURL = "images/tiles/cow.png";
            } else {
                pep.data.curriculums[1].tileURL = "images/tiles/rhino.png";
            }
            this.$router.push('/home');
        }
    },
}
</script>

<style>

</style>