<template>
    <div class="loading_screen">
        <video id="vid1" class="videoPagePlayer" controls autoplay poster="getPoster()" v-on:play="showTitle(0)" @ended="onEnd(2)">
            <source :src="getVidUrl()">
        </video>
    </div>
</template>

<style scoped>
    .loading_screen {
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 100vw;
        background-color: #1E1E1C;
        z-index:500;
    }
    .videoPagePlayer {
        width:100vw;
        max-height: calc(100vh - 20px);
        margin: 0 auto;
        position: relative;
    }

    .videoPagePlayer:focus,
    .videoPagePlayer:active{
        outline:none;
        border:0;
    }
</style>

<script>
    export default {

        data: function () {
            return {
                thisVid: 0,
                videos: pep.data.video,
                thispage: this.$route
            };
        },
        methods: {
            changePage: function (pageurl) {
                this.$router.push(pageurl);
            },
            udfset: function () {
                this.changePage('/home');
                pep.save();
            },
            udfset2: function () {
                this.changePage('/home_part_two');
                pep.save();
            },
            onEnd: function (vID) {
                console.log('test video ended: ' + vID);

                pep.data.userdetail.udf1 = "1";
                pep.setUDF(1, "1", this.udfset, this.udfset);

                pep.save();

                this.changePage("/home");

                //var videoData = pepData.data.video;
                //for (var i = 0; i < this.videos.length; i++) {
                //    console.log(this.videos[i].id);
                //    if (this.videos[i].id == vID) {
                //        console.log('ready to change page');
                //        pep.data.video[i].viewed = true;
                //        console.log('vID: ' + vID);
                //        console.log('***');
                //        //console.log(pepData);
                //        if (vID == 3) {
                //            pep.data.userdetail.udf2 = "1";
                //            pep.setUDF(2, "1", this.udfset2, this.udfset2);
                //        } else {
                //            pep.data.userdetail.udf1 = "1";
                //            pep.setUDF(1, "1", this.udfset, this.udfset);
                //        }
                //        //localStorage.setItem('introViewed' + pep.data.login.token.username, true);
                //        //localStorage.setItem('pepData', JSON.stringify(pepData));
                //        //if (vID == 1) {
                //        //    this.changePage('/video/intro');
                //        //    this.showTitle();
                //        //    //setTimeout(function () {
                //        //    document.getElementById('vid1').currentTime = 0;
                //        //    //}, 1000);
                //        //} else {
                //        //}
                //    }
                //}
            },
            showTitle: function (n) {
                //console.log('running showTitle');
                //if (n == 0) {
                //    $('.videoPageTitle').fadeOut(350);
                //} else {
                //    $('.videoPageTitle').fadeIn(350);
                //}
            },
            getPoster: function () {
                //var v = 1;
                //console.log(this.thisVid);
                //if (this.thisVid == 3) {
                //    v = 2;
                //}
                //var thisposter = pep.data.video[v].poster;
                //console.log('tp: ' + thisposter);

                return "https://ivyhouseaward.com/site/usermedia/video/poster/intro.jpg";
            },
            getVidUrl: function () {
                //var v = 1;
                //console.log(this.thisVid);
                //if (this.thisVid == 3) {
                //    v = 2;
                //}
                //var thisurl = pep.data.video[v].videoUrl;
                //console.log('tp: ' + thisurl);

                return "https://player.vimeo.com/external/430259662.hd.mp4?s=4b89f17ad018a64596fdb633ff14101958131623&profile_id=175";
            }
        },
        computed: {
            video: function () {
                //console.log(this.$route.path);
                //var ref;
                //switch (this.$route.path) {
                //    case '/video/orientation':
                //        ref = 1;
                //        break;
                //    case '/video/intro':
                //        ref = 2;
                //        break;
                //    //case '/video/intro2':
                //    //    ref = 3;
                //    //    this.thisVid = 3;
                //    //    break;
                //}
                //return this.videos.filter(function (item) {
                //    return item.id == ref;
                //});
            }
        },
        mounted: function () {
            var ref;
            //switch (this.$route.path) {
            //    case '/video/orientation':
            //        ref = 1;
            //        break;
            //    case '/video/intro':
            //        ref = 2;
            //        break;
            //    //case '/video/intro2':
            //    //    ref = 3;
            //    //    this.thisVid = 3;
            //    //    break;
            //}
            //var thisvideo = pep.data.video.filter(function (item) {
            //    return item.id == ref;
            //});

            //if (thisvideo[0].viewed == true) {
            //pep.gotoRoute("/home");
            //}
            //if (pep.data.storyboardimages.length < 10 && this.$route.path == '/video/intro2') {
            //    pep.gotoRoute("/term2");
            //}

        },
    }
</script>
