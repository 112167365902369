<template>
    <div class="content"><pep_term2></pep_term2></div>
</template>

<script>
    export default {

    }
</script>

<style>
</style>