<template>
  <div class="course_summary_item" :class="item.class + ' ' + item.deliverytype">
    
    <router-link :to="{ name: '',  path: linkurl}">
        <div class="course_summary_image" :style="backgroundStyle">
            
            <div class="progress_bar_background" style="bottom:0px;"></div>
            <div class="progress_bar" :style="('width:' + item.progress + '%')" style="bottom:0px:position:absolute;"></div>
        </div>
    </router-link>
    <div class="course_summary_right">
        
        <div class="course_summary_title">{{item.coursename}}</div>
        <div class="course_summary_summary">{{item.coursedescription}}</div>
    </div>
</div>

</template>

<script>
export default {
    props: ['item'],
    computed: {
        backgroundStyle: function () {
            var pos = 'center center;';
            if (this.item.coverimage.indexOf('CoverImage.jpg') > 0) {
                pos = 'center 10%!important;';
            }
            return 'background-image: url(' + this.item.coverimage + ');background-position:' + pos;
        },
        linkurl: function () {
            return '/course/' + this.item.curriculumid + "/" + this.item.courseid;
        }
    }
}
</script>

<style>

</style>