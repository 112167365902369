<template>
    <div>
        <div class="lmsclosebar">
            <div tabindex="3" class="lmsclose" v-on:keyup.enter="lmsCloseFrame" @click="lmsCloseFrame">&times;</div>
        </div>
        <iframe frameborder="0" v-if="showIframe" :src="framesource" id="lmsiframe" :class="'lmsiframe '+ extraClass" :rel="iOS" :scrolling="checkScrolling"></iframe>
        <div class="lmsiframe" v-if="showError">
            <div style="color:white; font-size:22px; text-align:center; padding-top:20px;">CORS issue.</div>
        </div>
    </div>
</template>

<script>
    export default {

        data: function () {
            return {
                showIframe: false,
                showError: false,
            };
        },
        computed: {
            checkScrolling: function () {
                var s = 'no';
                var deeplinkurl = this.$route.params.deeplinkurl;

                console.log('DeepLinkURL: ' + deeplinkurl);
                if (deeplinkurl != null) {
                    if (deeplinkurl.indexOf('gameplan') >= 0) {
                        s = 'yes';
                    }
                }

                return s;
            },
            framesource: function () {
                var deeplinkurl = this.$route.params.deeplinkurl;
                console.log('framesource dlu: ' + deeplinkurl);

                if (deeplinkurl != null) {
                    //replace DeepLinkURL Host with window.location.host to avoid CORS

                    var checkSameHostArr = deeplinkurl.split('/');
                    checkSameHostArr[2] = window.location.host;
                     
                    if (checkSameHostArr[3] == 'studentsv2')
                        checkSameHostArr[3] = 'students';

                    var deeplinkurl_SameHost = checkSameHostArr.join('/');

                    //for localhost testing
                    if (window.location.host.indexOf('localhost') > -1) {
                        deeplinkurl_SameHost = window.location.origin + "/#/" + checkSameHostArr[5];
                    }

                    console.group('Link creation LRS');
                    console.log(checkSameHostArr);
                    console.log(deeplinkurl_SameHost);
                    console.log(decodeURIComponent(deeplinkurl_SameHost));
                    console.groupEnd();

                    return decodeURIComponent(deeplinkurl_SameHost);
                } else {

                    var curriculumid = this.$route.params.curriculumid;
                    var cid = this.$route.params.courseid;
                    var cname = this.$route.params.coursename;
                    var courseURL = window.location.origin + "/wdc2013/lmstokenauth.aspx?token=" + pep.data.login.token.access_token +
                        "&userName=" + pep.data.login.token.username +
                        "&courseid=" + cid +
                        "&curriculumId=" + curriculumid +
                        "&courseName=" + encodeURIComponent(cname);

                    $('#navbar_button_bar').fadeOut(350);

                    console.group('Link creation LMS');
                    console.log(courseURL);
                    console.groupEnd();

                    return courseURL;
                }
            },
            extraClass: function () {
                var s = '';
                console.log('cid: ' + this.$route.params.courseid);
                if (this.$route.params.courseid == 715 || this.$route.params.courseid == 711) {
                    s = 'session9';
                }
                return s;
            },
            iOS: function () {
                var iOStest = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
                var s = '';
                if (iOStest === true) {

                    var iHeight = window.screen.height;
                    var iWidth = window.screen.width;

                    if (iWidth == 375 && iHeight == 812) {
                        s = 'iPhoneX';
                    } else if (iWidth >= 414 && iHeight >= 896) {
                        s = 'runningiOS';
                    }
                    else if (iWidth >= 375 && iHeight >= 812) {
                        s = 'runningiOS';
                    }
                    else if (iHeight < 750) {
                        s = 'runningiOSwithButton';
                    }
                    else if (iWidth > 750) {
                        s = 'runningiPad';
                    }
                }
                return s;
            }
        },
        methods: {
            gpcomplete: function () {
                console.log('gpcomplete');
                if (pep.data.settings.temp_redirect != "") {
                    pep.initData();
                } else {
                    this.$router.push('/week/' + this.$route.params.curriculumid);
                }
            },
            getParameterByName: function (name, url) {
                if (!url) url = window.location.href;
                name = name.replace(/[\[\]]/g, '\\$&');
                var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                    results = regex.exec(url);
                if (!results) return null;
                if (!results[2]) return '';
                return decodeURIComponent(results[2].replace(/\+/g, ' '));
            },
            lmsCloseFrame: function () {
                var self = this;

                if (self.module_hasChangesUnsaved()) {
                    self.module_confirmClosing();
                } else {
                    self.returnToCurriculum();
                }
            },
            module_hasChangesUnsaved: function () {
                return String(localStorage.getItem('userChangedData')).toLowerCase() == "true" && String(localStorage.getItem('userSavedData')).toLowerCase() == "false";
            },
            module_clearUnsavedStatus: function () {
                localStorage.setItem('userChangedData', null);
                localStorage.setItem('userSavedData', null);
            },
            module_confirmClosing: function () {
                var self = this;

                var confirmationMessage = "Its looks like you've not submitted your actions, are you sure you want to exit ?";

                if (confirm(confirmationMessage)) {
                    self.module_clearUnsavedStatus();
                    self.returnToCurriculum();
                }
            },
            returnToCurriculum: function () {
                pep.data.settings.temp_redirect = '/week/' + this.$route.params.curriculumid;
                pep.refresh_CourseProgress();
            }
        },
        mounted: function () {
            var self = this;
            //pep.data.settings.temp_redirect = '/week/' + this.$route.params.curriculumid;

            //if (pepData.data.generalURL == window.location.origin) {
            //}

            self.showIframe = true;

            self.showError = false;

            setTimeout(function () {
                //IE FIX
                var currentPath = window.location.hash.slice(1);
                refreshIframeForDeeplinks(currentPath);
            }, 50);
        },
    }
</script>

<style>
</style>