<template>
    <div class="content-wrapper" id="pepApp">
        <!-- Main navbar -->
        <div class="content-wrapper">
            <pep_navbar></pep_navbar>
            <!-- /main navbar -->
            <!-- Page content -->
            <div class="page-content">
                <!-- Main content -->
                <div class="content-wrapper">
                    <!-- Content area -->
                    <div class="content d-flex justify-content-center ">
                        <!-- Login form -->
                        <router-view></router-view>
                        <!-- /login form -->
                    </div>
                    <!-- /content area -->
                    <!-- Footer -->
                    <pep_button_bar></pep_button_bar>
                    <!-- /footer -->
                </div>
                <!-- /main content -->
            </div>
            <!-- /page content -->
        </div>
    </div>
</template>

<style lang="scss">
    @import "@/styles/custom.scss";
</style>

<script>
    import pep_navbar from '@/components/pep_navbar.vue'
    import pep_button_bar from '@/components/pep_button_bar.vue'

    export default {
        name: 'IvyHouseApp',
        data: function () {
            return {

            }
        },
        watch: {
            '$route.params': {
                handler(params) {

                },
                immediate: true,
            }
        },
        components: {
            pep_navbar,
            pep_button_bar
        }
    }
</script>


