<template>
  <div class="questions">
    <div class="helpContent featured_article">
        <h1 style="font-size:2rem; margin-bottom:20px;">Part Two</h1>
        <h1 style="font-size:5vw">Student questionnaire</h1>
        <div class="featured_article">
            <div id="contact-form" class="contact-form">
                <div class="separator"></div>
                <form class="form" style="font-size:1.5rem">
                    <label class="question">
                        1. Having completed part two of The Award has your knowledge and understanding deepened in the following areas?
                        <br />Tick all of those that apply
                    </label><br />
                    <div>
                        <input class="question" type="checkbox" id="area1" v-model="formd.area1" name="area1" value="I have more skill to build my own confidence" style="float:left;width:10%;margin-top:10px">
                        <label style="float:left;width:90%" for="area1">I have more skill to build my own confidence</label>
                    </div>
                    <div>
                        <input class="question" type="checkbox" id="area2" v-model="formd.area2" name="area2" value="I have more skill to pitch myself" style="float:left;width:10%;margin-top:10px">
                        <label style="float:left;width:90%" l for="area2">I have more skill to pitch myself</label>
                    </div>
                    <div>
                        <input class="question" type="checkbox" id="area3" v-model="formd.area3" name="area3" value="I have more skill to create a positive impact impact" style="float:left;width:10%;margin-top:10px">
                        <label style="float:left;width:90%" for="area3">I have more skill to create a positive impact impact</label>
                    </div>
                    <div>
                        <input class="question" type="checkbox" id="area4" v-model="formd.area4" name="area4" value="I have more skill to improve my personal wellness - both physical and mental" style="float:left;width:10%;margin-top:10px">
                        <label style="float:left;width:90%" for="area4">I have more skill to improve my personal wellness - both physical and mental</label>
                    </div>
                    <div>
                        <input class="question" type="checkbox" id="area5" v-model="formd.area5" name="area5" value="I have more skill to build relationships" style="float:left;width:10%;margin-top:10px">
                        <label style="float:left;width:90%" for="area5">I have more skill to build relationships</label>
                    </div>
                    <div>
                        <input class="question" type="checkbox" id="area6" v-model="formd.area6" name="area6" value="I have more skill to have meaningful, effective conversations" style="float:left;width:10%;margin-top:10px">
                        <label style="float:left;width:90%" for="area6">I have more skill to have meaningful, effective conversations</label>
                    </div>
                    <div>
                        <input class="question" type="checkbox" id="area7" v-model="formd.area7" name="area7" value="I am clearer about what constitutes a supportive tribe" style="float:left;width:10%;margin-top:10px">
                        <label style="float:left;width:90%" for="area7">I am clearer about what constitutes a supportive tribe</label>
                    </div>
                    <div>
                        <input class="question" type="checkbox" id="area8" v-model="formd.area8" name="area8" value="I have more skill to lead others" style="float:left;width:10%;margin-top:10px">
                        <label style="float:left;width:90%" label for="area8">I have more skill to lead others</label>
                    </div>
                    <div>
                        <input class="question" type="checkbox" id="area9" v-model="formd.area9" name="area9" value="I have a clear action plan" style="float:left;width:10%;margin-top:10px">
                        <label style="float:left;width:90%" for="area9">I have a clear action plan</label>
                    </div>
                    <label class="question"> 2. Have you applied the learning in any areas of your life:</label><br />
                    <div>
                        <label style="float:right;width:90%" for="appliedyes">Yes</label>
                        <input class="question" type="radio" v-model="formd.applied" id="appliedyes" name="applied" value="Yes" style="float:left;width:10%;margin-top:10px">
                    </div>
                    <div>
                        <label style="float:right;width:90%" for="appliedno" class="question">No</label>
                        <input type="radio" class="question" v-model="formd.applied" id="appliedno" name="applied" value="No" style="float:left;width:10%;margin-top:10px">
                    </div>
                    <div>
                        <label class="question" for="appliedDetail">3. Tell us how you have applied the learning ?</label>
                        <br />
                        <textarea class="question" id="appliedDetail" v-model="formd.appliedDetail" rows="4"></textarea>
                    </div>
                    <div>
                        <label class="question" for="improveLearning">4. How might we improve The Award for future learners?</label>
                        <br />
                        <textarea class="question" id="improveLearning" v-model="formd.improveLearning" rows="4"></textarea>
                    </div>

                    <div>
                        <label class="question" for="enjoyedLearning">5. What have you particulalry enjoyed about The Award?</label>
                        <br />
                        <textarea class="question" id="enjoyedLearning" v-model="formd.enjoyedLearning" rows="4"></textarea>
                    </div>
                    <span class="question button" v-on:click="senddata">Submit</span>
                </form>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {

  data: function () {
    return {
      formd: {
        area1: '',
        area2: '',
        area3: '',
        area4: '',
        area5: '',
        area6: '',
        area7: '',
        area8: '',
        area9: '',
        applied: '',
        appliedDetail: "",
        improveLearning: "",
        enjoyedLearning: "",
        userid: pep.data.userdetail.ID
      },
      sendingData: false
    };
  },
  mounted: function () {
    //this.checkResponse();
    for (let field in this.formd) {
      //console.log(field);
    }
  },
  created: function () {
    this.checkResponse()
  },
  methods: {
    clearForm: function () {
      for (let field in this.formd) {
        this.formd[field] = (typeof this.formd[field] == "boolean") ? false : "";
      }
    },
    checkResponse: function () {
      var bool = false;
      bool = pep.checkSurveyTerm2();
    },
    senddata: function (e) {
      e.preventDefault;
      var area1 = this.formd.area1;
      var area2 = this.formd.area2;
      var area3 = this.formd.area3;
      var area4 = this.formd.area4;
      var area5 = this.formd.area5;
      var area6 = this.formd.area6;
      var area7 = this.formd.area7;
      var area8 = this.formd.area8;
      var area9 = this.formd.area9;
      var applied = this.formd.applied;
      var appliedDetail = this.formd.appliedDetail;
      var improveLearning = this.formd.improveLearning;
      var enjoyedLearning = this.formd.enjoyedLearning;

      pep.sendStudentQuestionnaireResponsesTerm2(area1, area2, area3, area4, area5, area6, area7, area8, area9, applied, appliedDetail, improveLearning, enjoyedLearning, userid);

      var url = location.href;
      var newurl = url.split('/').slice(0, -1).join('/') + '/quesionnairethankyou';
      location.href = newurl;
    },
    senddatafailed: function () {
      //
    },
    senddatasuccess: function () {
      this.clearForm();
      this.$router.push('/questionnaireComplete');
    }
  },
}
</script>

<style>

</style>