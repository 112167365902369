<template>
    <div class="radar_holder featured_article">
        <div class="radar_toggle_buttons" style="width:auto; height:42px;border:1px solid gray;position:absolute; right:5%;top:100px; ">
            <div class="btn_toggle_table button radar_toggle_button" title="Click to see your Action Plan preview" v-on:click="printActionPlan()" style="width:32px; height:32px; border:1px solid white;padding:3px;cursor:pointer;margin:4px;float:right;">
                <img style="width:100%;" src="@/images/Icons/print.png" />
            </div>
        </div>

        <!-- POP UP MESSAGES -->
        <div id="js-feedback1" v-if="showfeedback1">
            <div class="savingprogress" style="z-index:100;">Please wait, saving your progress...</div>
        </div>
        <div id="js-feedback2" v-if="showfeedback2">
            <div class='savingprogress' style='z-index:100;'>
                CONGRATULATIONS, YOU HAVE NOW FULLY COMPLETED THE AWARD.
                <br /><br />
                TAKE SOME TIME TO REFLECT BACK AND SEE HOW FAR YOU HAVE COME ON YOUR JOURNEY BEFORE DOWNLOADING AND PRINTING YOUR ACTION PLAN..
                <br /><br />
                REMEMBER, THIS IS JUST THE START.... WHAT YOU DO NEXT IS WHAT MATTERS AND WE WISH YOU LOVE, LUCK AND RHINOS.
                <br /><br />
                <div v-on:click="this.printActionPlan()" style='text-transform:uppercase; color:#000; width:180px; margin:0 auto; font-weight:bold; background-color:#fff; padding:4px 8px;cursor:pointer;'>
                    PRINT<div></div>
                </div>
            </div>
        </div>
        <div id="js-feedback3" v-if="showfeedback3">
            <div class='savingprogress' style='z-index:100;'>
                <div style="max-width: 720px; margin:0 auto;">
                    Great news your actions have been saved, click the 'X' to close this screen and to find out more visit the additional content section.
                </div>
            </div>
        </div>
        <div id="js-feedback4" v-if="showfeedback4">
            <div class="savingprogress" style="z-index:100;">Your actions have been saved. Click the menu (<img style="height:0.75em;" src="@/images/Icons/menu.png" />) to go to another section.</div>
        </div>
        <!-- POP UP MESSAGES -->
        <!-- TOUR -->
        <div id="actiontour" v-if="tourComplete == false" style="position:fixed; top: 30%; width:100%; text-align:center; z-index:5;">
            <div id="" class="">
                <h2 style="font-weight:800;text-transform:uppercase;">Welcome to your Action Plan</h2>
                <div><p>Here's a quick tour of this section.</p></div>
            </div>
            <div class="tour_item" id="tour1">
                <p>Type your actions here and the date you will complete them</p>
                <div class="sb-button tour-button gameplannext" @click="tournext()">NEXT</div>
            </div>

            <div class="tour_item" id="tour2" style="display:none;">
                <p>Submit your actions to save them</p>
                <div class="sb-button tour-button gameplannext" @click="tournext()">NEXT</div>
            </div>
        </div>
        <!-- TOUR -->

        <div class="radar_left_panel">
            <h2 :class="'gpTour ' + tourCompleteClass" style="font-weight:800;">Action Plan</h2>
            <h1 :class="'gpTour title2 radar_title2 ' + tourCompleteClass">{{categories[currentcat].title}}</h1>

            <!-- Action 1 -->
            <!--<p v-if="action1PageActive" :class="'gpTour '+ tourCompleteClass">Here you can see your Action Plan.</p>-->
            <p v-if="action1PageActive" :class="'gpTour '+ tourCompleteClass">Take some time to consider what you have learnt about yourself in this session and capture your notes here. Over the 20 sessions, this will build up to be a full picture of all of the things you have learnt about yourself on your journey.</p>
            <div v-if="action1PageActive && action1PageReady" v-on:click="openAction2Page()" :class="actionBtnClass" data-tour="tour2">Next</div>

            <!-- Action 2 -->
            <p v-if="action2PageActive" :class="'gpTour '+ tourCompleteClass">Now you have learnt something about yourself and the life you want to create, what do you want to do with that knowledge?  Capture your actions and commitments here and this will also build up over the 20 sessions. </p>
            <div v-if="action2PageActive" v-on:click="openAction1Page()" class="actionSubmit gpTour toshow actionPlanBtn">Back</div>
            <div v-if="action2PageActive && action2PageReady" v-on:click="saveGameplan()" class="actionSubmit gpTour toshow actionPlanBtn">Submit</div>
        </div>

        <div class="radar_right_panel" style="position:relative;">
            <div class="action_table_holder">
                <table class="radar_actiontable">
                    <tr :class="'gpTour '+ tourCompleteClass">
                        <th width="100%" v-if="action1PageActive" style="padding:5px;">What have I learnt about myself?</th>
                        <th width="100%" v-if="action2PageActive" style="padding:5px;">What actions am I going to take?</th>
                    </tr>
                </table>
                <div :class="'toshow gpTour '+ tourCompleteClass">
                    <div v-if="action1PageActive" class="actionBlock actionSAS" style="width:100%;">
                        <div class="actionRow">
                            <textarea v-model="categories[currentcat].answer1" style="height: 200px;" placeholder="" maxlength="500" value=""></textarea>
                        </div>
                    </div>
                    <div v-if="action2PageActive" class="actionBlock actionDate" style="width:100%;">
                        <div class="actionRow">
                            <textarea v-model="categories[currentcat].answer2" style="height: 200px;" placeholder="" maxlength="500" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        data: function () {
            var url = decodeURIComponent(window.location.href).split('/');
            var sid = this.getParameterByName("sessionID", url);

            //1. uncomment to enable the tour instrunctions
            //var actiontourcomplete = (localStorage.getItem("actiontourcomplete") == "true");
            //2. and set the following
            //tourComplete: actiontourcomplete
            return {
                sessionId: sid,
                categories: [
                    {
                        title: '',
                        answer1: '',
                        answer2: ''
                    }
                ],
                currentcat: 0,
                tour: 1,
                tourComplete: true,
                action1PageActive: true,
                action2PageActive: false,
                showfeedback1: false,
                showfeedback2: false,
                showfeedback3: false,
                showfeedback4: false,
            };
        },
        computed: {
            actionBtnClass() {
                var self = this;

                var inactiveClasses = self.tourComplete ? '' : 'inactive';

                return 'actionSubmit gpTour toshow actionPlanBtn ' + inactiveClasses;
            },
            tourCompleteClass() {
                return this.tourComplete ? "" : "inactive";
            },
            action1PageReady() {
                return this.categories[this.currentcat].answer1.length >= 2;
            },
            action2PageReady() {
                return this.categories[this.currentcat].answer2.length >= 2;
            },
        },
        methods: {
            printActionPlan: function () {
                pep.createDocument();
            },
            loadDataIfPassed: function () {
                var self = this;

                var storageData = localStorage.getItem('actionPlan');

                if (storageData != null) {
                    console.log("storageData != null");

                    self.categories = JSON.parse(storageData);
                    console.log(self.categories);

                    pep.data.categories.forEach(function (elem, index) {
                        if (elem.id == self.sessionId) {
                            self.currentcat = index;
                            console.log("currentcat = " + index);
                        }
                    });

                } else {
                    console.log("Action plan storage data empty");

                    self.loadDataFromParentFrame();
                }
            },
            loadDataFromParentFrame: function () {
                var self = this;
                if (self.inIframe()) {
                    if (top.pep != null) {
                        try {
                            if (self.sessionId != null) {
                                console.log("sessionId = " + self.sessionId);
                                self.categories = [...top.pep.data.categories];

                                console.log("self.categories = ");
                                console.log(top.pep.data.categories);

                                pep.data.categories.forEach(function (elem, index) {
                                    if (elem.id == self.sessionId) {
                                        self.currentcat = index;
                                        console.log("self.currentcat = ");
                                        console.log(self.currentcat);
                                    }
                                });
                            }
                        } catch (ex) {
                            console.error("loadDataFromParentFrame failed.")
                        }
                    }
                }
            },
            fadeInAnimationsContent: function () {
                var elem1 = $(".radar_left_panel");
                var elem2 = $(".radar_right_panel");
                elem1.hide();
                elem2.hide();

                elem1.fadeIn(400);
                elem2.fadeIn(800);
            },
            fadeInAnimationsFeedback: function () {
                var elems = $(".js-feedback1, .js-feedback2, .js-feedback3, .js-feedback4");
                elems.hide();
                elems.fadeIn();
            },
            getGameplanData: function (username) {
                return $.ajax({
                    url: pepData.config.services.ServiceEndpoint + 'getGameplanData?username=' + username,
                    type: 'get',
                    beforesend: function (xhr) {
                        xhr.setrequestheader('authorization', 'bearer ' + self.data.login.token.access_token);
                    }
                });
            },
            setGameplanData: function (username) {
                var self = this;

                if (self.categories == null || typeof self.categories != "object")
                    return;

                var data = {
                    username: username,
                    gameplanJson: JSON.stringify(self.categories)
                }

                localStorage.setItem('actionPlan', JSON.stringify(self.categories));

                console.log("setGameplanData>>>");
                console.log(data);

                return $.ajax({
                    url: pepData.config.services.ServiceEndpoint + 'setGameplanData',
                    method: "POST",
                    data: data,
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    dataType: "json",
                    crossDomain: true,
                    async: true
                });
            },
            getParameterByName: function (name, url) {
                if (!url) url = window.location.href;
                name = name.replace(/[\[\]]/g, '\\$&');
                var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                    results = regex.exec(url);
                if (!results) return null;
                if (!results[2]) return '';
                return decodeURIComponent(results[2].replace(/\+/g, ' '));
            },
            openAction1Page: function () {
                this.action1PageActive = true;
                this.action2PageActive = false;

                this.tour++;

                this.fadeInAnimationsContent();
            },
            openAction2Page: function () {
                this.action1PageActive = false;
                this.action2PageActive = true;

                this.fadeInAnimationsContent();
            },
            changePage: function (pageurl) {
                if (pageurl == '/radar') {
                    pageurl = '/' + this.getR();
                } else if (pageurl == '/action') {
                    pageurl = '/' + this.getA();
                } else if (pageurl == '/preview') {
                    pageurl = '/' + this.getP();
                }

                var url = decodeURIComponent(window.location.href).split('/');

                //url = url[url.length - 1];
                //var qs = getParameterByName('sessionID', url);

                if (n != undefined) {
                    pageurl = pageurl + '?sessionID=' + n;
                }

                this.$router.push(pageurl);
            },
            saveGameplan: function () {
                console.log('saveGameplan>>');
                var self = this;

                //self.setGameplanData(pep.data.userdetail.UserName);
                self.showfeedback1 = true;

                var everyThingAnswered = self.categories.filter(function (e) {
                    return e.answer1 == ""
                });

                console.group("everyThingAnswered");
                console.log(everyThingAnswered);
                console.log(everyThingAnswered.length);
                console.groupEnd();

                if (everyThingAnswered.length == 18) {
                    setTimeout(function () {
                        self.showfeedback1 = false;
                        self.showfeedback2 = true;
                    }, 1500);
                } else {
                    setTimeout(function () {
                        self.showfeedback1 = false;
                        self.showfeedback3 = true;
                    }, 1500);
                }
            },
            isVisible: function (i) {
                var cI = (parseInt(i) - 1);

                if (typeof n != 'undefined') {
                    if (cI != n)
                        return 'display:none;';
                }
            },
            starttour: function () {

            },
            tournext: function () {
                var self = this;

                $("#tour" + this.tour).hide();

                this.tour++;

                if (self.tour > 2) {
                    $(".gpTour").removeClass("inactive");
                    $("#actiontour").fadeOut();
                    this.tour = 1;
                    localStorage.setItem("actiontourcomplete", true);
                    self.tourComplete = true;
                } else {
                    var tourStr = 'tour' + this.tour;
                    $(".gpTour").addClass("inactive");


                    $("#tour" + this.tour).show();
                }
            },
            inIframe: function () {
                try {
                    return window.self !== window.top;
                } catch (e) {
                    return true;
                }
            }
        },
        mounted: function () {
            var myself = this;
            var qs = '';

            //if (myself.tourComplete) {
            //    $('.gpTour').removeClass('inactive');
            //    $("#actiontour").hide();
            //} else {
            //    $("#actiontour").show();
            //}

            if (myself.inIframe()) {
                $(".page-content").css('margin-top', 0);
                $("#topbarapp").hide();
            }

            this.fadeInAnimationsContent();
            this.loadDataIfPassed();
        }
    }
</script>

<style>
</style>