<template>
    <div class="content"><pep_myprofile></pep_myprofile></div>
</template>

<script>
    import pep_myprofile from '@/components/pep_myprofile.vue'
    export default {
        components: {
            pep_myprofile,
        }
    }
</script>

<style>
</style>