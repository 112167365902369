<template>
    <div class="storyboard_section posternoprint">

        <div class="featured_article">

            <h1 class="title">Action Plan</h1>
            <h2>Here you can download and print your Action Plan.</h2>

            <br />
            <div class="hidethis cd_btn" @click="download" id="downloadpdf">{{btnTitle}}</div>

            <!--<div class="hidethis cd_btn" @click="print" id="printposter">PRINT</div>-->
        </div>
    </div>
</template>

<script>
    export default {

        data: function () {
            return {
                downloadBtn: "DOWNLOAD / PRINT",
                generating: false
            };
        },
        computed: {
            btnTitle: function () {
                return this.downloadBtn;
            },
            sbbak: function () {
                return sbbaks[0];
            },
        },
        mounted: function () {
            var self = this;

            console.log("Refresh actionplan data...");
            self.getGameplanData(pep.data.userdetail.UserName).done(function (data, status) {

                if (data != "Nothing found" && data != null && self.IsJsonString(data)) {
                    var parsedData = JSON.parse(data);
                    if (typeof parsedData == "object") {
                        //console.log("main.js pep.data.categories = ");
                        //console.log(data);
                        console.log("success");

                        localStorage.setItem('actionPlan', data);
                        pep.data.categories = parsedData;
                    } else {
                        console.error("main.js actionplan not an object");
                    }
                } else {
                    console.error("getGameplanData> Nothing found");
                }
            })
        },
        methods: {
            download: function () {
                var self = this;

                if (self.generating == false) {
                    self.downloadBtn = "Generating...";
                    self.generating = true;

                    pep.createDocument(function (status) {
                        self.downloadBtn = status;

                        if (status == "Done.")
                            setTimeout(function () {
                                self.downloadBtn = "DOWNLOAD / PRINT";
                                self.generating = false;
                            }, 2000);
                    });
                }
            },
            getGameplanData: function (username) {
                return $.ajax({
                    url: pep.config.services.ServiceEndpoint + 'getGameplanData?username=' + username,
                    type: 'get',
                    beforesend: function (xhr) {
                        xhr.setrequestheader('authorization', 'bearer ' + self.data.login.token.access_token);
                    }
                });
            },
            IsJsonString: function (str) {
                try {
                    JSON.parse(str);
                } catch (e) {
                    return false;
                }
                return true;
            },
            print: function () {
                //window.print();
            }
        },
    }
</script>

<style>
</style>