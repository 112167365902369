<template>
    <div class="content"><pep_lms_frameset></pep_lms_frameset></div>
</template>

<script>
    import pep_lms_frameset from '@/components/pep_lms_frameset.vue'

    export default {
        components: {
            pep_lms_frameset,
        }
    }
</script>

<style>
</style>