<template>
  <div class="content-wrapper">
    <pep_navbar></pep_navbar> 
    <div class="ajax_loader"></div> 
    <div id="videoContentWrapper" class="content-wrapper">
        <div class="content d-flex justify-content-center align-items-center">
            <video_player></video_player> 
        </div> 
    </div> 
</div>
</template>

<script>
    import pep_navbar from '@/components/pep_navbar.vue'
    import video_player from '@/components/video_player.vue'
    export default {
        components: {
            pep_navbar,
            video_player
        }
    }
</script>

<style>

</style>