<template>
    <div class="login-form">
        <div class="card mb-0">
            <div class="card-body">
                <div class="text-center mb-3">
                    <img class="login-logo" v-bind:src="require(`@/${login_text.logo}`)" />
                    <span class="d-block text-muted">{{ login_text.subtitle }}</span>
                    <span class="d-block text-warning-800">{{ login_text.loginerror }}</span>
                </div>
                <div class="form-group form-group-feedback form-group-feedback-left">
                    <input tabindex="3" v-model="username" id="frm_username" type="email" class="form-control" v-bind:placeholder="login_text.username">
                    <div class="form-control-feedback">
                        <i class="icon-user text-muted"></i>
                    </div>
                </div>
                <div class="form-group form-group-feedback form-group-feedback-left">
                    <input tabindex="4" v-model="password" id="frm_password" v-on:keyup.enter="login()" type="password" class="form-control" v-bind:placeholder="login_text.password">
                    <div class="form-control-feedback">
                        <i class="icon-lock2 text-muted"></i>
                    </div>
                </div>
                <div class="d-flex flex-row mb-2">
                    <div tabindex="5" class="form-group d-flex flex-fill align-items-center" style="cursor:pointer;">
                        <a v-on:click="changePage('/password_recover')" class="ml-auto mr-auto blue">{{login_text.forgot}}</a>
                    </div>
                    <div tabindex="6" class="form-group d-flex flex-fill align-items-center" style="cursor:pointer;">
                        <a v-on:click="changePage('/forgot_username')" class="ml-auto mr-auto blue">{{login_text.forgot2}}</a>
                    </div>
                    <div tabindex="7" class="form-group d-flex flex-fill align-items-center" style="cursor:pointer;">
                        <a v-on:click="changePage('/register')" class="ml-auto mr-auto blue">{{login_text.noaccount}}</a>
                    </div>
                </div>
                <span class="form-text text-center text-muted">
                    <span>{{ login_text.terms1 }}</span>  <a class="blue pointer" tabindex="8" v-on:keyup.enter="changePage('/terms')" v-on:click="changePage('/terms')">{{ login_text.terms2 }}</a> <span>{{ login_text.and }}</span> <a class="blue pointer" tabindex="9" v-on:keyup.enter="changePage('/cookies')"  v-on:click="changePage('/cookies')">{{ login_text.cookiepolicy }}</a> <br /><br />
                </span>

                <div class="form-group">
                    <div id="btnLogin" tabindex="10" v-on:keyup.enter="login()" v-on:click="login()" type="submit" class="btn btn-primary btn-block blue"><span>{{ login_text.signin }}</span><i class="icon-circle-right2 ml-2"></i></div>
                </div>
                <div class="form-group text-center text-muted content-divider" :style="canCreate ? 'display:block' : 'display:none'">
                    <span class="px-2">{{ login_text.noaccount }}</span>
                </div>
                <div class="form-group" :style="canCreate ? 'display:block' : 'display:none'">
                    <a href="#" class="btn btn-light btn-block">{{ login_text.signup }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            token: Object,
            isloggedin: Boolean
        },
        data: function () {
            return {
                login_text: pep.ui_lang_components.login,
                loggedIn: pep.data.login.isLoggedIn,
                username: "",
                password: "",
                canCreate: false
            };
        },
        watch: {
            '$route.params': {
                handler(params) {
                    var self = this;

                    //self.checkLoggedIn();
                },
                immediate: true,
            }
        },
        methods: {
            login: function () {
                this.login_text.loginerror = "";

                pep.login(this.username, this.password);
            },
            changePage: function (pageurl) {
                this.login_text.loginerror = "";

                this.$router.push(pageurl);
            },
            checkLoggedIn: function () {
                var self = this;

                if (self.loggedIn)
                    pep.goToPageByURL(pep.config.defaultPages[1].url);
            }
        },
        mounted: function () {
            var self = this;

            if (window.location.hash.indexOf("lmsstudentview") > -1) {
                console.log("lmsstudentview");

                var username = this.$route.params.username;
                var token = this.$route.params.token;

                if (username != null && token != null) {
                    //validate token

                    //console.log(username);
                    //console.log(token);

                    pep.data.login.isLoggedIn = true;
                    pep.data.login.token.username = username;
                    pep.data.login.token.access_token = token;

                    pep.initData();
                    //on success log in
                }
            }

            this.$nextTick(function () {
                self.checkLoggedIn();
            });
        },
    }
</script>

<style scoped lang="scss">
    .blue {
        cursor: pointer;
    }
</style>
