<template>
    <div class="questions">
        <div class="featured_article">
            <div class="backHome" style="background:#000;padding: 1% 0%; margin-bottom:1rem;width:60px;text-align:center;" v-on:click="goHome()">
                <img src="@/images/Icons/1x/back button.png">
            </div>
            <h1 class="title">Terms and Conditions</h1>
            <br />

            <span>Hi</span>
            <br />
            <br />
            <span>
                We are so excited that you are starting your journey on The Award. Before you get cracking we just need to cover a few important bits; we really have tried to keep this as brief as possible, but please make sure you read carefully.
            </span>
            <br />
            <br />
            <span>Your role as an Authorised User</span>
            <br />
            <br />
            <span>Ivy House has signed an Agreement with your school which permits you access to The Award as an “Authorised User” through a “User Subscription”.  As an Authorised User:</span>
            <ol>
                <li>Your first line of support is your school; they can help you get set up as an Authorised User if needed</li>
                <li>You must not share your User Subscription details with anyone else</li>
                <li>You must maintain a secure password, and keep that password confidential</li>

                <li>You must not store, distribute or transmit any viruses, or any material that: is unlawful, harmful, threatening, defamatory, obscene, infringing, harassing or racially or ethnically offensive; facilitates illegal activity; depicts sexually explicit images; promotes unlawful violence; is discriminatory based on race, gender, colour, religious belief, sexual orientation, disability; or is otherwise illegal or causes damage or injury to any person or property</li>
                <li>You are not allowed to copy, reproduce, distribute, commercially use, or benefit from, the software, services and content which collectively make up The Award</li>
                <li>You should regularly download any materials generated by The Award as and when instructed.</li>
            </ol>

            <span>Your well-being is really important to both Ivy House and your school, therefore:</span>
            <ol start="7">

                <li>Your School has an “administrator access” to The Award; this provides them access to data around any Authorised User’s progress and usage of The Award. Your School is also able to view your Action Plan</li>

                <li>Every school has provided Ivy House with the name and contact details of Designated Safety Officer (DSO) at your school</li>

                <li>The Award software has a “word check” functionality embedded within in it. Should anything be typed that triggers the word check, or that makes Ivy House concerned for the well-being of any Authorised User, then Ivy House will contact your DSO immediately.</li>

            </ol>
            <span>The last few important bits…</span>
            <ol start="10">
                <li>Ivy House will make reasonable endeavours to make The Award available and accessible for the Subscription Period, but occasionally we will need to undertake planned maintenance (we will always try to do this outside normal business hours)</li>

                <li>Ivy House does not grant Authorised Users any rights to any copyright, content, database right, trade names, trademarks (whether registered or unregistered), or any other rights or licences in respect of The Award</li>

                <li>Ivy House and/or its licensors own all intellectual property rights in The Award (software, services and content) and in all Ivy House™ and Ivy House Award™ trademarks, domain names, brands and associated logos</li>

                <li>Ivy House is committed to securely processing all data in accordance with all legal obligations. All data will be handled in line with our Data Protection and Privacy policies (available upon request)</li>

                <li>Ivy House shall not be liable for any damage, loss or corruption of data or information, or for any indirect or consequential loss, costs, damages, charges, or expenses however arising from use of The Award</li>

                <li>Ivy House reserves the right, without liability or prejudice to its other rights, to disable any Authorised User’s access to any material that breaches the provisions of The Agreement or these Terms and Conditions.</li>
            </ol>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            goHome: function () {
                this.$router.push("/");
            },
        }
    }
</script>

<style>
</style>