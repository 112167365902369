import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


import logon_template from '@/templates/logon_template'
import video_template from '@/templates/video_template'
import home_template from '@/templates/home_template'

//lazy loading components
// { name: 'logon_template', path: '/', component: () => import ( /* webpackChunkName: "main-templates" */ '../views/logon_template.vue') },

var routes = [
  {
    name: 'video_template_intro',
    path: '/video/intro',
    component: video_template
  },
  { name: 'logon_template', path: '/', component: logon_template },
  { name: 'logon_lms_template', path: '/lmsstudentview/:username/:token', component: logon_template },

  { name: 'home_template', path: '/home', component: home_template },
  {
    name: 'curriculum_template',
    path: '/week/:curriculumid',
    component: () =>
      import( /* webpackChunkName: "tier-templates" */ '@/templates/curriculum_template.vue')
  },
  {
    name: 'myprofile_template',
    path: '/profile',
    component: () =>
      import( /* webpackChunkName: "tier-templates" */ '@/templates/myprofile_template.vue')
  },
  {
    name: 'help_template',
    path: '/help',
    component: () =>
      import( /* webpackChunkName: "tier-templates" */ '@/templates/help_template.vue')
  },

  {
    name: 'forgotUsername_template',
    path: '/forgot_username',
    component: () =>
      import( /* webpackChunkName: "tier-templates" */ '@/templates/forgotUsername_template.vue')
  },
  {
    name: 'forgotPassword_template',
    path: '/password_recover',
    component: () =>
      import( /* webpackChunkName: "tier-templates" */ '@/templates/forgotPassword_template.vue')
  },
  {
    name: 'resetPassword_template',
    path: '/password_reset',
    component: () =>
      import( /* webpackChunkName: "tier-templates" */ '@/templates/resetPassword_template.vue')
  },
  {
    name: 'register_template',
    path: '/register',
    component: () =>
      import( /* webpackChunkName: "tier-templates" */ '@/templates/register_template.vue')
  },

  {
    name: 'terms_template',
    path: '/terms',
    component: () =>
      import( /* webpackChunkName: "tier-templates" */ '@/templates/terms_template.vue')
  },
  {
    name: 'cookies_template',
    path: '/cookies',
    component: () =>
      import( /* webpackChunkName: "tier-templates" */ '@/templates/cookies_template.vue')
  },
  {
    name: 'lms_player_template_lms',
    path: '/lms/:curriculumid/:courseid/:coursename',
    component: () =>
      import( /* webpackChunkName: "tier-templates" */ '@/templates/lms_player_template.vue')
  },
  {
    name: 'lms_player_template_lrs',
    path: '/lms/:curriculumid/:deeplinkurl',
    component: () =>
      import( /* webpackChunkName: "tier-templates" */ '@/templates/lms_player_template.vue')
  },
  {
    name: 'pep_actionplan_template',
    path: '/actionplanposter',
    component: () =>
      import( /* webpackChunkName: "tier-templates" */ '@/templates/pep_actionplanposter_template.vue')
  },

  {
    name: 'contact_us',
    path: '/questions',
    component: () =>
      import( /* webpackChunkName: "tier-templates" */ '@/templates/questions_template.vue')
  },


  {
    name: 'survey',
    path: '/survey/:id',
    component: () =>
      import( /* webpackChunkName: "tier-templates" */ '@/templates/pep_survey_template.vue')
  },

  {
      name: 'notifications_template',
      path: '/notifications',
      component: () =>
        import( /* webpackChunkName: "tier-templates" */ '@/templates/notifications_template.vue')
  },

  {
    name: 'loading',
    path: '/loading',
    component: () =>
      import( /* webpackChunkName: "tier-templates" */ '@/templates/loading_template.vue')
  },


  //{
  //  name: 'pep_actionplan_template',
  //  path: '/actionplan',
  //  component: () =>
  //    import( /* webpackChunkName: "tier-templates" */ '@/templates/pep_actionplan_template.vue')
  //},
  //{
  //  name: 'pep_actionplan_template_dup',
  //  path: '/actioninline',
  //  component: () =>
  //    import( /* webpackChunkName: "tier-templates" */ '@/templates/pep_actionplan_template.vue')
  //},

  // { name: 'home_template_part_two', path: '/home_part_two', component: home_template_part_two },
  // {
  //     name: 'course_template',
  //     path: '/course/:curriculumid/:courseid',
  //     component: () =>
  //         import ( /* webpackChunkName: "tier-templates" */ '../views/course_template.vue')
  // },


  // {
  //     name: 'search_template',
  //     path: '/search',
  //     component: () =>
  //         import ( /* webpackChunkName: "tier-templates" */ '../views/search_template.vue')
  // },


  // {
  //     name: 'awards_template',
  //     path: '/awards',
  //     component: () =>
  //         import ( /* webpackChunkName: "tier-templates" */ '../views/awards_template.vue')
  // },
  // {
  //     name: 'progress_template',
  //     path: '/progress',
  //     component: () =>
  //         import ( /* webpackChunkName: "tier-templates" */ '../views/progress_template.vue')
  // },



  // {
  //     name: 'leaderboard_template',
  //     path: '/leaderboard',
  //     component: () =>
  //         import ( /* webpackChunkName: "tier-templates" */ '../views/leaderboard_template.vue')
  // },
  // {
  //     name: 'advancedsearch_template',
  //     path: '/advancedsearch',
  //     component: () =>
  //         import ( /* webpackChunkName: "tier-templates" */ '../views/advancedsearch_template.vue')
  // },


  // {
  //     name: 'quiz_template',
  //     path: '/quiz',
  //     component: () =>
  //         import ( /* webpackChunkName: "tier-templates" */ '../views/quiz_template.vue')
  // },
  // {
  //     name: 'video_template_orientation',
  //     path: '/video/orientation',
  //     component: video_template
  // },


  // {
  //     name: 'video_template_intro2',
  //     path: '/video/intro2',
  //     component: video_template
  // },
  // {
  //     name: 'term2_template',
  //     path: '/term2',
  //     component: () =>
  //         import ( /* webpackChunkName: "tier-templates" */ '../views/term2_template.vue')
  // },





  // {
  //     name: 'storyboard',
  //     path: '/storyboard',
  //     component: () =>
  //         import ( /* webpackChunkName: "tier-templates" */ '../views/storyboard.vue')
  // },

  // {
  //     name: 'pep_radar_template',
  //     path: '/radar',
  //     component: () =>
  //         import ( /* webpackChunkName: "tier-templates" */ '../views/pep_radar_template.vue')
  // },


  // {
  //     name: 'pep_actiontable_template',
  //     path: '/actiontable',
  //     component: () =>
  //         import ( /* webpackChunkName: "tier-templates" */ '../views/pep_actiontable_template.vue')
  // },
  // {
  //     name: 'pep_radar_action_preview_template',
  //     path: '/gameplanpreview',
  //     component: () =>
  //         import ( /* webpackChunkName: "tier-templates" */ '../views/pep_radar_action_preview_template.vue')
  // },
  // {
  //     name: 'pep_radar_inline',
  //     path: '/gameplan',
  //     component: () =>
  //         import ( /* webpackChunkName: "tier-templates" */ '../views/pep_radar_inline.vue')
  // },


  // {
  //     name: 'pep_actionplan_template',
  //     path: '/actionplan',
  //     component: () =>
  //         import ( /* webpackChunkName: "tier-templates" */ '../views/pep_actionplan_template.vue')
  // },
  // {
  //     name: 'pep_actiontable_inline',
  //     path: '/actioninline',
  //     component: () =>
  //         import ( /* webpackChunkName: "tier-templates" */ '../views/pep_actiontable_inline.vue')
  // },
  // {
  //     name: 'pep_radar_action_preview_inline',
  //     path: '/previewinline',
  //     component: () =>
  //         import ( /* webpackChunkName: "tier-templates" */ '../views/pep_radar_action_preview_inline.vue')
  // },


  // {
  //     name: 'pep_studentquestionnaire_template',
  //     path: '/studentquestionnaire',
  //     component: () =>
  //         import ( /* webpackChunkName: "tier-templates" */ '../views/pep_studentquestionnaire_template.vue')
  // },
  // {
  //     name: 'pep_facilitatorquestionnaire_template',
  //     path: '/facilitatorquestionnaire',
  //     component: () =>
  //         import ( /* webpackChunkName: "tier-templates" */ '../views/pep_facilitatorquestionnaire_template.vue')
  // },
  // {
  //     name: 'pep_studentquestionnairePart2_template',
  //     path: '/studentquestionnaire2',
  //     component: () =>
  //         import ( /* webpackChunkName: "tier-templates" */ '../views/pep_studentquestionnairePart2_template.vue')
  // },


  // {
  //     name: 'pep_facilitatorquestionnairePart2_template',
  //     path: '/facilitatorquestionnaire2',
  //     component: () =>
  //         import ( /* webpackChunkName: "tier-templates" */ '../views/pep_facilitatorquestionnairePart2_template.vue')
  // },
  // {
  //     name: 'pep_questionnaireTY_template',
  //     path: '/quesionnairethankyou',
  //     component: () =>
  //         import ( /* webpackChunkName: "tier-templates" */ '../views/pep_questionnaireTY_template.vue')
  // },
  // {
  //     name: 'pep_questionnairecomplete_template',
  //     path: '/questionnaireComplete',
  //     component: () =>
  //         import ( /* webpackChunkName: "tier-templates" */ '../views/pep_questionnairecomplete_template.vue')
  // }



  //page not found the last
  {
    name: 'page_not_found',
    path: '*',
    component: () =>
      import( /* webpackChunkName: "tier-templates" */ '@/templates/pep_page_not_found404.vue')
  }
];

var router = new VueRouter({
  routes: routes,
  scrollBehavior: function (to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

export default router