<template>
    <div class="content"><pep_survey></pep_survey></div>
</template>

<script>
    import pep_survey from '@/components/pep_survey.vue'

    export default {
        components: {
            pep_survey,
        }
    }
</script>

<style>
</style>