<template>
    <div class="questions">

        <div class="helpContent featured_article" style="padding-bottom:10rem;">
            <div class="backHome" style="background:#000;padding: 1% 0%; margin-bottom:1rem;width:60px;text-align:center;" v-on:click="goHome()">
                <img src="@/images/Icons/1x/back button.png">
            </div>

            <div class="featured_article">

                <h1>{{quizTitle}}</h1>

                <p v-if="!surveyActive && surveyMessage" class="title">{{surveyMessage}}</p>

                <div id="contact-form" class="contact-form">

                    <form class="form" style="font-size:1.5rem" v-if="surveyActive">

                        <h2 v-html="quizText"></h2>

                        <!--<div class="questionBlock_checkbox" v-for="n in firstBlock">
        <label :for="'QID_'+ n.QID" v-html="n.Question"></label>
        <input :id="'QID_'+ n.QID" class="question" type="checkbox" :value="n.QID">
    </div>-->

                        <div class="questionBlock_radio" v-for="(n, index) in firstBlock">
                            <label :for="'QID_'+ n.QID" v-html="n.Question"></label>

                            <div class="radioBlock">
                                <div class="radioInput">
                                    <label :for="'QID_'+ n.QID + '_yes'">Yes</label>
                                    <input :id="'QID_'+ n.QID + '_yes'" v-model="submitData[n.QID]" :name="'QID_'+ n.QID" type="radio" value="Yes">
                                </div>
                                <div class="radioInput">
                                    <label :for="'QID_'+ n.QID + '_no'">No</label>
                                    <input :id="'QID_'+ n.QID + '_no'" v-model="submitData[n.QID]" :name="'QID_'+ n.QID" type="radio" value="No">
                                </div>
                            </div>
                        </div>


                        <div class="questionBlock_textarea" v-for="(n, index) in secondBlock">
                            <div class="textareaBlock">
                                <label :for="'QID_'+ n.QID" v-html="n.Question"></label>
                                <div class="characters-left" v-html="charactersLeft(n.QID)"></div>
                                <textarea :id="'QID_'+ n.QID" v-model="submitData[n.QID]" rows="4" maxlength="1000" v-on:click="focusInput($event)"></textarea>
                            </div>
                        </div>

                        <div class="questionBlock_Rating_1_to_10" v-for="(n, index) in thirdBlock">
                            <label :for="'QID_'+ n.QID" v-html="n.Question"></label>
                            <!--<div class="ratingBlock">
            <div class="ratingTextStart">0</div>
            <div class="ratingRadios">
                <input v-for="rating in 10" :name="'QID_'+ n.QID" v-model="submitData[n.QID]" type="radio" :value="rating">
            </div>
            <div class="ratingTextEnd">10</div>
        </div>-->

                            <div class="ratingSliderBlock">
                                <div :data-QID="n.QID" class="ratingSlider">
                                    <span class="ui-slider-handle">
                                        <span class="ui-number">0</span>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div v-if="surveyError"><span class="d-block text-warning-800" style="padding-bottom:10px;" v-html="surveyError"></span></div>

                        <div :class="'submitBlock '+ (suspendSubmit ? 'suspend': '')">
                            <span class="question button" v-on:click="senddata">Submit</span>
                        </div>

                    </form>
                </div>
            </div>

            <div class="loading-overlay" v-if="loading">
                <h2></h2>
            </div>
        </div>

    </div>

</template>

<style scoped>
    .loading-overlay {
        position: fixed;
        width: 100%;
        height: 100vh;
        left: 0;
        top: 0;
        background: rgba(0,0,0,0.8) url(../images/loading.gif) no-repeat center center;
        display:flex;
        align-items:center;
        justify-content:center;
        color:white;
    }

    .characters-left {
        font-size: 0.875rem;
    }

    .suspend {
        opacity:0.7;
        pointer-events:none;
    }
</style>

<script>
    export default {

        data: function () {
            var quizTitle = pep.data.news.featured.title.toLowerCase();

            var quizQuestions = pep.data.quizquestions.filter(function (elem, index) {
                var elemTitle = elem.QuizName.toLowerCase();

                if (elemTitle == quizTitle) {
                    return elem;
                }
            });

            var firstBlock = quizQuestions.filter(function (elem, index) {
                //if (elem.Question.indexOf('(e.g.') > -1 && elem.Question.indexOf('<br/>') == -1) {
                //    elem.Question = elem.Question.split('(e.g.').join('<br/>(e.g.');
                //}

                return elem.Question_Template.trim() == "first_block";
            });

            var secondBlock = quizQuestions.filter(function (elem, index) {
                return elem.Question_Template.trim() == "second_block";
            });

            var thirdBlock = quizQuestions.filter(function (elem, index) {
                return elem.Question_Template.trim() == "third_block";
            });

            return {
                page: pep.data.news.featured,
                quizSetId: quizQuestions[0].SetID,
                quizTitle: quizQuestions[0].QuizName,
                quizText: quizQuestions[0].QuizDescription,
                firstBlock: firstBlock,
                secondBlock: secondBlock,
                thirdBlock: thirdBlock,
                surveyActive: true,
                loading: false,
                surveyMessage: "",
                surveyError: "",
                suspendSubmit: false,
                submitData: {

                }
            };
        },
        mounted: function () {
            this.hasQuizSetResult();
            this.setupSliders();
        },
        created: function () {
            this.hasQuizSetResult()
        },
        computed: {
            checkFilled: function () {
                var length = Object.keys(this.submitData).length;
                return length == this.questionCount;
            }
        },
        methods: {
            charactersLeft: function (QID) {
                var self = this;

                var amountLeft = 1000;

                if (self.submitData == null)
                    return "";

                if (self.submitData[QID] != null) {
                    amountLeft = 1000 - self.submitData[QID].length;
                }

                return amountLeft + " characters left";
            },
            focusInput: function (e) {
                var position = $(e.target).eq(0).offset();
                $('html,body').animate({ scrollTop: position.top }, 'slow');
            },
            setupSliders: function () {
                var self = this;

                $(".ratingSlider").slider({
                    value: 0,
                    min: 0,
                    max: 10,
                    step: 1,
                    slide: function (event, ui) {
                        var QID = $(this).attr("data-qid");
                        var uiNumber = $(this).find(".ui-number");

                        self.submitData[QID] = ui.value;
                        uiNumber.html(ui.value);
                    }
                });
            },
            goHome: function () {
                var self = this;
                self.saveSurveyLocally()

                self.$router.push("/home");
            },
            loadSurveyLocally: function () {
                var self = this;

                if (pep.data.surveys == null)
                    pep.data.surveys = {};

                if (pep.data.surveys["QS_" + self.quizSetId] != null) {
                    try {
                        self.submitData = JSON.parse(pep.data.surveys["QS_" + self.quizSetId]);
                    } catch (ex) {
                        pep.data.surveys["QS_" + self.quizSetId] = {};
                    }
                }
            },
            saveSurveyLocally: function () {
                var self = this;

                if (pep.data.surveys == null)
                    pep.data.surveys = {};

                pep.data.surveys["QS_" + self.quizSetId] = JSON.stringify(self.submitData);

                pep.save();
            },
            hasQuizSetResult: function () {
                var self = this;

                self.loading = true;

                pep.checkSurvey(pep.data.userdetail.ID, this.quizSetId, function (completed) {
                    self.loading = false;

                    if (completed) {
                        console.log("this survey was completed QS_ID = " + self.quizSetId);

                        self.surveyActive = !completed;
                        self.surveyMessage = "You have filled in the survey";
                        self.quizTitle = "";
                    } else {
                        self.loadSurveyLocally()
                    }
                });
            },
            senddata: function (e) {
                var self = this;

                self.saveSurveyLocally()

                var sendData = [];

                if (!self.suspendSubmit) {

                    Object.keys(self.submitData).forEach(function (key) {
                        //console.log(key);
                        sendData.push(
                            {
                                "QID": key,
                                "UserID": pep.data.userdetail.ID,
                                "Answer": self.submitData[key],
                            }
                        );
                    });

                    //console.log("SEND DATA");
                    //console.log(sendData);
                    //console.log(JSON.stringify(sendData));
                    self.loading = true;

                    pep.sendSurvey(JSON.stringify(sendData), self.quizSetId, function (status) {
                        status = Number(status);
                        self.loading = false;

                        switch (status) {
                            case 200:
                            case 202:
                                self.surveyActive = false;
                                self.surveyMessage = "The survey was submitted. Thank you.";
                                self.quizTitle = "";
                                self.suspendSubmit = false;
                                break;

                            case 500:
                                self.surveyError = "An error occurred when saving, a developer will be notified. Please report this issue to your Teacher/Facilitator.";
                                self.suspendSubmit = true;
                                break;

                            default:
                                self.suspendSubmit = false;
                                self.surveyError = "An error occurred when saving. Please check your connection.";
                                break;
                        }
                    });
                }
            },
        },
    }
</script>

