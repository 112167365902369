<template>
    <div class="term2">
        <div class="term2Content featured_article">
            <p class="title">LOADING...</p>
        </div>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {

            };
        },
        mounted: function () {

        },
    }
</script>

<style>
</style>