<template>
    <div :id="rowid" :class="'notification_item ' + rowcss">
        <div class="notification_left_pane">
            <div class="notification_title">{{item.Title}}</div>
            <div v-if="includeCustom" class="notification_summary">{{item.Custom}}</div>
            <div class="notification_summary">{{item.summary}}</div>
            <div class="notification_summary" style="font-style: italic;">{{item.Text}}</div>
            <br />
            <div class="notification_dated">Dated: {{updated}}</div>
            <br />
        </div>
        <div :class="'notification_right_pane ' + item.cssClass" :style="this.backgroundStyle"></div>
    </div>
</template>

<script>
    export default {
        props: ['item', 'index'],
        computed: {
            backgroundStyle() {
                var img = "";
                if (this.item.ImageURL != "" && typeof this.item.ImageURL != 'undefined') {
                    img = 'background-image: url(' + this.item.ImageURL + ');';
                };
                if (this.item.cssClass == "staffmessage") {
                    img = 'background-image: url(' + pep.config.services.imageProfilePath + this.item.ImageURL + ');border-radius: 50%;';
                };
                return img;
            },
            rowcss() {
                return "animated fadeInUp delay-0" + (this.index + 1);
            },
            updated() {
                return new Date(this.item.updated).toLocaleString();
            },
            rowid() {
                return "n_" + this.index;
            },
            includeCustom() {
                if (this.item.cssClass == "staffmessage") { return true } else { return false }
            }
        },
        methods: {
            //markAsRead: function (i) {
            //    var ContentID = pep.data.notifications[i].ContentID;
            //    pep.setNotificationAsRead(ContentID);

            //    $('#n_' + i).fadeOut(function () {
            //        pep.data.notifications.splice(i, 1);
            //    });
            //}
        },
        mounted: function () {
            var myindex = this.index;
            this.$nextTick(function () {
                // Code that will run only after the entire view has been rendered
                console.log("draggable")

                $('.delay-0' + (this.index + 1)).draggable({
                    axis: "x",
                    scroll: false,
                    distance: 30,
                    containment: [20, 0, 1000, 0],
                    revert: function () {
                        return $(this).offset().left < 200;
                    },
                    stop: function () {
                        if ($(this).offset().left >= 200) {
                            $(this).animate({ "margin-left": "5000px" }, 500, function () {
                                //Set Hide Flag
                                var i = myindex;
                                var ContentID = pep.data.notifications[i].ContentID;
                                //pep.save();
                                $(this).animate({
                                    "min-height": "0px",
                                    "height": "0px",
                                    "margin-bottom": "0px",
                                    "padding": "0px"
                                }, 250, function () {
                                    pep.data.notifications.splice(i, 1);

                                    $(this).css({
                                        "min-height": "150px",
                                        "height": "auto",
                                        "margin-bottom": "20px",
                                        "padding": "15px",
                                        "margin-left": "0px",
                                        "left": "0px"
                                    }, 250);

                                    pep.setNotificationAsRead(ContentID);
                                });
                            });
                        }
                    }
                });
            });
        }
    }
</script>

<style>
</style>