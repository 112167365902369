<template>
    <div class="content"><pep_loading></pep_loading></div>
</template>

<script>
    import pep_loading from '@/components/pep_loading.vue'
    export default {
        components: {
            pep_loading
        }
    }
</script>

<style>
</style>