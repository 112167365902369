<template>
    <div class="questions">
        <div class="featured_article">
            <div class="backHome" style="background:#000;padding: 1% 0%; margin-bottom:1rem;width:60px;text-align:center;" v-on:click="goHome()">
                <img src="@/images/Icons/1x/back button.png">
            </div>

            <h1 class="title">Cookies Policy</h1>
            <br />
            At Ivy House, we believe in being clear and open about how we collect, and use data related to you. This Cookie Policy applies to any Ivy House product or service that links to this policy or incorporates it by reference. We use cookies and similar technologies to collect and use data as part of our Services as defined in our Privacy Policy ("Services") and which includes our sites, communications, and mobile applications.<br /><br />
            In the spirit of transparency, this policy provides detailed information about how and when we use these technologies.<br /><br />
            By continuing to visit or use our Services, you are agreeing to the use of cookies and similar technologies for the purposes described in this policy.<br /><br />
            <h2>What technologies are used?</h2>
            <table width="100%">
                <tr><td style="border-bottom:2px solid white; width:20%;"><b>Type of technology</b></td><td style="border-bottom:2px solid white"><b>Description</b></td></tr>
                <tr><td style="border-bottom:1px solid white">Cookies</td><td style="border-bottom:1px solid white">A cookie is a small file placed onto your device that enables Ivy House features and functionality. Any browser visiting our sites may receive cookies from us or cookies from third parties such as our partners or service providers.<br /><br />We use two types of cookies: persistent cookies and session cookies. A persistent cookie may help us recognize you as an existing user, so it's easier to return to Ivy House or interact with our Services without signing in again. A persistent cookie stays in your browser and will be read by Ivy House when you return. Session cookies last only as long as the session (usually the current visit to a website or a browser session).</td></tr>
                <tr><td style="border-bottom:1px solid white">Local storage</td><td style="border-bottom:1px solid white">Local storage enables a website or application to store information locally on your device(s). Local storage may be used to improve the Ivy House experience, for example, by enabling features, remembering your preferences and speeding up site functionality.</td></tr>
            </table>
            <br /><br />Our cookie table lists cookies and similar technologies that are used as part of our Services. Please note that the names of cookies and similar technologies may change over time.<br /><br />
            <h2>What are these technologies used for?</h2>
            Below we describe the purposes for which we use these technologies.<br /><br />
            <table width="100%">
                <tr><td style="border-bottom:2px solid white; width:20%;"><b>Purpose</b></td><td style="border-bottom:2px solid white"><b>Description</b></td></tr>
                <tr><td style="border-bottom:1px solid white">Authentication</td><td style="border-bottom:1px solid white">We use cookies and similar technologies to recognize you when you visit our Services.<br /><br />If you're signed into Ivy House, these technologies help us show you the right information and personalize your experience in line with your settings. For example, cookies enable Ivy House to identify you and verify your account.</td></tr>
                <tr><td style="border-bottom:1px solid white">Security</td><td style="border-bottom:1px solid white">We use cookies and similar technologies to make your interactions with our Services faster and more secure.<br /><br />For example, we use cookies to enable and support our security features, keep your account safe and to help us detect malicious activity and violations of our User Agreement.</td></tr>
            </table>
        </div>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    export default {
        methods: {
            goHome: function () {
                this.$router.push("/");
            },
        }
    }
</script>
