<template>
    <div class="netflix_row">

        <div class="netflix-red-bar"><div class="netflix-red-bar-progress" :style="{ width: (item.progress + '%')}"></div></div>
        <div class="netflix_row_name">IN THIS SESSION</div>
        <div class="netflix_row_row col-md-12" style="padding-left:0;">

            <pep_netflix_item v-for="(course, index) in item.courses"
                              v-bind:item="course"
                              v-bind:index="index"
                              v-bind:key="course.courseid" class="col-md-4"
                              v-bind:curriculumid="item.id"
                              style="padding-left:0;padding-right:1.25rem"></pep_netflix_item>
        </div>
    </div>
</template>

<script>
    import pep_netflix_item from '@/components/pep_netflix_item.vue'

    export default {
        props: ['item'],
        components: {
            pep_netflix_item
        }
    }
</script>

<style>
</style>