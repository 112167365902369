<template>
  <div class="content"><pep_actionplan></pep_actionplan></div>
</template>

<script>
    //import pep_actionplan from '@/components/pep_actionplan.vue'

    export default {
        components: {
            //pep_actionplan,
        }
    }
</script>

<style>

</style>