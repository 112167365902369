<template>
    <div class="bg" :style="{ backgroundImage: 'url('+ bgImage +')' }">
        <div class="featured_article bgLinear">
            <div>
                <div class="backHome" tabindex="3" v-on:keyup.enter="goHome()" v-on:click="goHome()">
                    <img src="@/images/Icons/1x/back button.png">
                </div>
                <h2 class="week">{{ wNumTxt()}}</h2>
                <div class="titleWrap" v-html="splitTitle"></div>
                <h1 class="title2" style="display:none;">{{ featured.title }}</h1>
                <h1 class="title3" style="display:none;">{{ featured.title2 }}</h1>
            </div>
            <h1 class="title3" style="display:none;">{{ featured.title3 }}</h1>
            <div class="featured-article-subtitle" style="margin-top:13rem;">{{featured.title}}</div>
            <div class="featured-article-descr">{{featured.subtitle }}</div>
            <br /><br />
            <div id="hidethis" class="" style="display:none;" v-on:click="hideArticle()">begin</div>
        </div>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                featured: pep.data.news.featured,
                bgImage: pep.data.news.featured.imageUrl,
            };
        },
        computed: {
            hideArticle: function () {
                //alert('g');
                //this.isActive = !this.isActive;
            },
            splitTitle: function () {
                var arr = pep.data.news.featured.title.split(' ');
                var title1 = arr[0], title2 = arr[1], title3 = '', s = '';
                if (arr[2]) { title3 = ' ' + arr[2]; s = 'style="word-spacing:0;"'; }
                if (arr[3]) { title3 += ' ' + arr[3]; s = 'style="word-spacing:0;"'; }
                if (arr[4]) { title3 += ' ' + arr[4]; s = 'style="word-spacing:0;"'; }

                var str = '<h1 class="title2">' + title1 + '</h1><h1 class="title2"' + s + '>' + title2 + title3 + '</h1>';
                return str;
            }
        },
        methods: {
            wNumTxt: function () {
                //var weeknumber = this.featured.week;
                //var weekname = this.featured.week;
                //var weekTxt = this.featured.title;
                //if (weekTxt == 'My Self Leadership' || weekTxt == 'My Confidence' || weekTxt == 'My Story' || weekTxt == 'My Impact' || weekTxt == 'My Wellness' || weekTxt == 'My Relationships' || weekTxt == 'My Conversations' || weekTxt == 'My Tribe' || weekTxt == 'My Leadership' || weekTxt == 'My Actions') {
                //    //console.log(weekTxt);
                //    wknumArr = weeknumber.split(' ');
                //    wknum = parseInt(wknumArr[1]);
                //    weekname = wknumArr[0] + ' ' + (10 + wknum);
                //    //console.log('weekname: ' + weekname);
                //}

                //return weekname;
            },
            goHome: function () {
                var routeCurriculumid = this.$route.params.curriculumid;

                this.$router.push("/home");
            }
        },
        mounted: function () {
            pep.save();
        },
    }
</script>

<style>
</style>