<template>
  <div class="content"><pep_advanced_search></pep_advanced_search></div>
</template>

<script>
export default {

}
</script>

<style>

</style>