<template>
    <div class="netflix_section">
        <pep_netflix_row v-for="(curriculum, index) in curriculum"
                         v-bind:item="curriculum"
                         v-bind:index="index"
                         v-bind:key="curriculum.id"></pep_netflix_row>
    </div>
</template>

<script>
    import pep_netflix_row from '@/components/pep_netflix_row.vue'

    export default {
        data: function () {
            var selected_curriculums = pep.data.curriculums;

            var routeCurriculumid = this.$route.params.curriculumid;

            // if (routeCurriculumid >= 385) {
            //   selected_curriculums = pep.data.curriculums2;
            // }

            return {
                curriculums: selected_curriculums
            };

        },
        computed: {
            curriculum: function () {
                var routeCurriculumid = this.$route.params.curriculumid;

                return this.curriculums.filter(function (item) {
                    return item.id == routeCurriculumid;
                });
            },
        },
        components: {
            pep_netflix_row
        }
    }
</script>

<style>
</style>