<template>
  <pep_simple_search></pep_simple_search>
</template>

<script>
export default {

}
</script>

<style>

</style>