<template>
  <div class="content"><pep_studentquestionnaire></pep_studentquestionnaire></div>
</template>

<script>
export default {

}
</script>

<style>

</style>