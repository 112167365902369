<template>
    <div class="storyboard_section">
        <div class="storyboard_intro featured_article grey" v-if="storyboard_viewed">
            <h1 class="title">{{ intro.Row1Title.toUpperCase() }}</h1>
            <h1 class="title2">{{ intro.Row2Title.toUpperCase() }}</h1>
            <div class="video-text">
                <router-link to="/video/intro">
                    <p><img src="@/images/Icons/video.png" /> <span>Play introduction video</span></p>
                </router-link>
            </div>
            <p>{{ intro.text }}</p>
            <br/>
            <div class="storyboard_btn" v-on:click="closeFeatured()">close</div>
        </div>

        <pep_storyboard_item v-for="(curriculum, index) in curriculums"
                             v-bind:item="curriculum"
                             v-bind:index="index"
                             v-bind:key="curriculum.id">
        </pep_storyboard_item>
    </div>
</template>

<style scoped lang="scss">

</style>

<script>
    import pep_storyboard_item from '@/components/pep_storyboard_item.vue'

    export default {
        data: function () {
            return {
                curriculums: pep.data.curriculums,
                intro: pep.data.introInstructions,
                settings: pep.data.userdetail.udf1
            };
        },
        computed: {
            storyboard_viewed: function () {
                return this.settings != "1"
            }
        },
        methods: {
            isTeacher: function () {
                if (pep.data.userdetail == null) {
                    return false;
                } else {
                    return pep.data.userdetail.JobTitle_FK.toLowerCase() == "40117184-27f6-4318-9b88-d343648bb8bd";
                }
            },
            closeFeatured: function () {
                this.settings = "1";
                pep.data.userdetail.udf1 = "1";
                pep.setUDF(1, 1);
            },
            survey: function () {
                var url = location.href;
                var newurl = url.split('/').slice(0, -1).join('/') + '/studentquestionnaire';
                location.href = newurl;

            }
        },
        components: {
            pep_storyboard_item,
        }
    }
</script>
