<template>
  <div class="storyboard_section">
    
    <div class="featured_article" v-if="settings.storyboard_viewed == false">
        
        <h1 class="title">{{ featured.header.toUpperCase() }}</h1> 
        <h1 class="title2">{{ updateTitle() }}</h1> 
        <br />
        <p>{{ updateSubTitle() }}</p>
        <br />
        <div id="hidethis" class="" v-on:click="closeFeatured()">close</div>
    </div>
 
    <pep_storyboard_item  v-for="(curriculum, index) in curriculums" 
                         v-bind:item="curriculum" 
                         v-bind:index="index" 
                         v-bind:key="curriculum.id">
    </pep_storyboard_item>
    
    
</div>
</template>

<script>
export default {

  data: function () {
    return {
      curriculums: pep.data.curriculums2,
      featured: pep.data.featured,
      settings: pep.data.settings
    };
  },
  methods: {
    closeFeatured: function () {
      //console.log("CLOSING FEATURED");
      pep.data.settings.storyboard_viewed = true;
      $('.featured_article').addClass("closed");
      pep.save();
    },
    updateTitle: function () {
      var newTitle = this.featured.title.replace("Storyboard", "Gameplan");
      return newTitle;
    },
    updateSubTitle: function () {
      var newSubTitle = this.featured.subtitle.replace("storyboard", "gameplan");
      return newSubTitle;
    },
    survey: function () {
      var url = location.href;
      var newurl = url.split('/').slice(0, -1).join('/') + '/studentquestionnaire2';
      location.href = newurl;
    }
  },
  computed: {
    backgroundStyle: function () {
      return 'background: url(' + pepData.data.generalURL + '/site/usermedia/images/survey.png) center center;background-size:cover;';
    },
  },
  mounted: function () {
    //if part one is not finished
    //if (pep.data.storyboardimages.length < 10) {
    //    pep.gotoRoute("/term2");
    //} else {
    //    //if part two intro hasn't been watched
    //    if (pep.data.userdetail.udf2 != "1") {
    //        pep.gotoRoute("/home_part_two");
    //    }
    //}
  },
}
</script>

<style>

</style>