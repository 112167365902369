<template>
  <div class="content"><pep_radar></pep_radar></div>
</template>

<script>
export default {

}
</script>

<style>

</style>