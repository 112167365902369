<template>
  <div class="content"><pep_cow_or_rhino></pep_cow_or_rhino></div>
</template>

<script>
export default {

}
</script>

<style>

</style>