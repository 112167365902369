<template>
    <div class="content"><pep_actionplanposter></pep_actionplanposter></div>
</template>

<script>
    import pep_actionplanposter from '@/components/pep_actionplanposter.vue'
    export default {
        components: {
            pep_actionplanposter
        }
    }
</script>

<style>
</style>