<template>
    <div>
        <div v-on:click="openLink">
            <div :class="[this.certificationclass, item.deliverytype] + ' netflix_item'" :style="backgroundStyle"></div>
            <div class="netflix_item_name">
                <div class="progress_bar_background" style="bottom:0px;"></div>
                <div class="progress_bar" :style="{ width: (item.progress + '%'), bottom: '0px'}"></div>
                <h5>{{item.coursename}}</h5>
                <h6>{{item.course_details}}</h6>
            </div>
            <div class="netflix_name" style="display:none;"><p>{{item.subheading}}</p></div>
        </div>
    </div>
</template>

<script>
    export default {

        props: ['item', 'curriculumid', 'certificationclass'],
        data: function () {
            return {
                curriculums: pep.data.curriculums,
            };
        },
        computed: {
            backgroundStyle: function () {
                var pos = 'center center';
                if (this.item.coverimage.indexOf('CoverImage.jpg') > 0) {
                    pos = 'center top';
                }

                return 'background-image: url(' + this.item.coverimage + ');background-position:' + pos;
            },
            linkurl: function () {

                if (this.item.DeepLinkURL == "") {
                    return pep.data.generalURL + '/lms/' + this.curriculumid + "/" + this.item.courseid + "/" + this.item.coursename;
                } else {
                    return pep.data.generalURL + '/lms/' + this.curriculumid + "/" + encodeURIComponent(this.item.DeepLinkURL);
                }

            },
            additionalClasses: function () {
                return "";
                //return this.certificationclass + " " + item.deliverytype;
            },
            updateLikes: function (l) {
                this.likes = l;
            },
            setLike: function () {
                pep.setLike(1, this.filteredCourses[0].CourseID, this.updateLikes, this.likes);
            },
            gameplanProgress: function () {
                if (this.item.DeepLinkURL != '' && encodeURIComponent(this.item.DeepLinkURL).indexOf('gameplan') >= 0) {

                    console.log('AAAAA');
                    var c = '';
                    var w = 'width:0';
                    var index;
                    console.log(this.item.coursename);
                    if (this.item.coursename.indexOf('ELEVEN') >= 0) {
                        index = 0;
                    } else if (this.item.coursename.indexOf('TWELVE') >= 0) {
                        index = 1;
                    } else if (this.item.coursename.indexOf('THIRTEEN') >= 0) {
                        index = 2;
                    } else if (this.item.coursename.indexOf('FOURTEEN') >= 0) {
                        index = 3;
                    } else if (this.item.coursename.indexOf('FIFTEEN') >= 0) {
                        index = 4;
                    } else if (this.item.coursename.indexOf('SIXTEEN') >= 0) {
                        index = 5;
                    } else if (this.item.coursename.indexOf('SEVENTEEN') >= 0) {
                        index = 6;
                    } else if (this.item.coursename.indexOf('EIGHTEEN') >= 0) {
                        index = 7;
                    } else if (this.item.coursename.indexOf('NINETEEN') >= 0) {
                        index = 8;
                    } else if (this.item.coursename.indexOf('TWENTY') >= 0) {
                        index = 9;
                    }
                    if (pep.data.categories) {
                        console.log('BBBBB');
                        var gp = pep.data.categories;
                        var v = 0;
                        if (gp[index].goal > 1) {
                            v += 50;
                        }
                        if (gp[index].sasaction1 != '') {
                            v += 50;
                        }
                        console.log('v: ' + v);
                        w = 'width:' + v + '%';
                        console.log('w: ' + w);
                    }
                    c = 'test';
                    return w;
                } else {
                    w = 'width:' + this.item.progress + '%';
                    return w;
                }

            },
        },
        methods: {
            openLink() {
                var self = this;

                var cid = self.item.CourseID;
                var cname = self.item.CourseName;
                var curriculumid = self.item.Curriculum_ID_FK;
                var courseULR_Cordova = '';
                var courseULR_Browser = '';

                if (self.item.DeepLinkURL) {
                    courseULR_Cordova = self.item.DeepLinkURL;
                    courseULR_Browser = '/lms/' + self.curriculumid + "/" + encodeURIComponent(self.item.DeepLinkURL);

                    pep.addLaunch(cid);
                    pep.addView(cid);

                    self.item.progress = 100;
                    self.item.isCompleted = true;
                } else {
                    courseULR_Cordova = pep.data.generalURL + "/wdc2013/lmstokenauth.aspx?token=" + pep.data.login.token.access_token +
                        "&userName=" + pep.data.login.token.username +
                        "&courseName=" + cname +
                        "&courseid=" + cid +
                        "&curriculumId=" + curriculumid;
                    courseULR_Browser = '/lms/' + self.curriculumid + "/" + self.item.courseid + "/" + self.item.coursename;
                }


                if (typeof cordova == 'undefined') {
                    //open within the browser
                    self.$router.push(courseULR_Browser);

                } else {
                    //open on cordova
                    cordova.InAppBrowser.open(courseULR, '_system', 'location=no,toolbar=no,hideurlbar=yes');
                }
            }
        },
    }
</script>

<style>
</style>