<template>
    <div class="recover-password-form">
        <div class="card mb-0">
            <div class="card-body">
                <div class="text-center mb-3">
                    <img class="login-logo" v-bind:src="require(`@/${login_text.logo}`)" />
                    <span class="d-block text-muted">{{ login_text.recoversubtitle }}</span>
                    <span class="d-block text-warning-800">{{ login_text.recovererror }}</span>
                    <span class="d-block text-success-700">{{ login_text.recoversuccess }}</span>
                </div>
                <div class="form-group form-group-feedback form-group-feedback-left">
                    <input tabindex="3" v-model="email" id="frm_email" type="email" class="form-control" v-on:keyup.enter="recoverPassword()" v-bind:placeholder="login_text.email">
                    <div class="form-control-feedback">
                        <i class="icon-envelope text-muted"></i>
                    </div>
                </div>
                <div class="form-group">
                    <div tabindex="4" id="btnRecoverPassword" v-on:keyup.enter="recoverPassword()" v-on:click="recoverPassword()" type="submit"
                         class="btn btn-primary btn-block blue">
                        <span>{{ login_text.recover }}</span><i class="icon-circle-right2 ml-2"></i>
                    </div>
                </div>
                <div id="signin" class="form-group d-flex align-items-center" style="cursor:pointer;">
                    <a tabindex="5" v-on:click="changePage('/')" class="ml-auto blue">{{login_text.signin}}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            token: Object,
            isloggedin: Boolean
        },
        data: function () {
            return {
                login_text: pep.ui_lang_components.login,
                email: "",
                emailSent: false,
            };
        },
        methods: {
            recoverPassword: function () {
                var self = this;

                self.login_text.recoversuccess = "";
                self.login_text.recovererror = "";

                if (self.validateEmail(this.email) == false && this.email.length < 5) {
                    self.login_text.recovererror = 'Invalid email format.';
                    return;
                } else {

                    pep.recoverPassword(this.email);
                }
            },
            changePage: function (pageurl) {
                this.$router.push(pageurl);
            },
            removeClass: function (el, c) {
                $(el).removeClass(c);
            },
            emailSentF: function () {

            },
            validateEmail: function (email) {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(email).toLowerCase());
            }
        },
        mounted: function () {
            this.login_text.recoversuccess = "";
            this.login_text.recovererror = "";
        },
        watch: {
            isloggedin: function (val, oldval) {
                if (val === true) {
                    this.$router.push('/home')
                }
            }
        },
    }
</script>

<style>
</style>