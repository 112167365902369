<template>
  <div class="term2">
    
    <div class="term2Content featured_article">
        
        <h1 class="title">PART TWO</h1>
        <div class=""><p>This content is not available until Part One is completed.</p></div>
    </div>
</div>
</template>

<script>
export default {

  data: function () {
    return {
      leaderboard: pep.data.leaderboard
    };
  },
  mounted: function () {
    pep.checkSurveyForMenu();

    //isloggedin: function (val, oldval) {
    //    if (val === true) {
    //        this.$router.push('/home_part_two');
    //    }
    //}
    //if questionnaire complete
    //this.$router.push('/home_part_two');
  },
}
</script>

<style>

</style>