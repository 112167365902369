<template>
  <div class="help">
    <div class="helpContent featured_article">
        <h1 class="title">Help</h1>
        <div class="featured_article">
            <p>
                <ol>
                    <li>From the Home screen select <br>'Session 1 - Dream the Dream'</li>
                    <li>Work through the content in order.</li>
                    <li>When you have completed all of the content, your teacher will mark the session as complete and the next session's activities will be unlocked.</li>
                </ol>
            </p>
        </div>
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>