<template>
    <div class="content"><pep_storyboard_section></pep_storyboard_section></div>
</template>

<script>
    import pep_storyboard_section from '@/components/pep_storyboard_section.vue'

    export default {
        components: {
            pep_storyboard_section,
        },
        mounted: function () {
        
        }
    }
</script>

<style>
</style>