<template>
<div class="content-wrapper">
    <pep_navbar></pep_navbar>
    <div class="ajax_loader"></div>
    <div class="content-wrapper">
        <div class="content d-flex justify-content-center align-items-center">
            <pep_login></pep_login>
        </div>
    </div>
</div>
</template>

<script>
    import pep_login from '@/components/pep_login.vue'
    import pep_navbar from '@/components/pep_navbar.vue'

    export default {
        name: 'logon_template',
        components: {
            pep_login,
            pep_navbar
        }
    }
</script>

<style>

</style>
