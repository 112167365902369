<template>
  <div class="content-wrapper">
    <pep_navbar></pep_navbar> 
    <div class="ajax_loader"></div> 
    <div class="content-wrapper">
        <div class="content d-flex justify-content-center align-items-center">
            <pep_recover_username></pep_recover_username> 
        </div> 
    </div> 
</div>
</template>

<script>
    import pep_navbar from '@/components/pep_navbar.vue'
    import pep_recover_username from '@/components/pep_recover_username.vue'
    export default {
        components: {
            pep_navbar,
            pep_recover_username,
        }
    }
</script>

<style>

</style>