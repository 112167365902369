<template>
  <div class="featured_article">
      <h1>Page not found.</h1>

      <router-link to="/home" class="cd_btn">Back to Home</router-link>
</div>
</template>

<script>
    export default {
     
    }
</script>

<style scoped>
    h1 {
        font-size: 4vw;
    }
    .cd_btn{
        color:white;
        margin:1rem auto;
        display:block;
    }
</style>