<template>
    <div>
        <div :tabindex="this.index + 10" v-on:keyup.enter="openLink" v-on:click="openLink">
            <div :class="'netflix_item '+  (item.coursename.indexOf('7.') > -1 ? 'extended' : '')">
                <div class="courseName">{{item.coursename}}</div>
            </div>
            <div class="netflix_item_name">
                <div class="progress_bar" :style="{ width: (item.progress + '%') }"></div>
            </div>
        </div>
    </div>
</template>


<style scoped lang="scss">
    .netflix_item {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: #1E1E1C;
        color: white;
    }

        .netflix_item.extended {
            background-color: #FFF;
            color: #1E1E1C;
        }

    .courseName {
        font-weight: 900;
        font-family: "Montserrat", sans-serif;
        text-align: center;
    }

    @media only screen and (max-width: 767px) {
        .courseName {
            font-size: 8vw;
        }
    }

    @media only screen and (min-width: 768px) {
        .courseName {
            font-size: 3vw;
        }
    }

    .netflix_item_name {
        position: relative;
        z-index: 5;
    }

    .progress_bar {
        bottom: 0;
        margin-top: 0;
        position: absolute;
        left: 0;
    }
</style>

<script>
    export default {

        props: ['item', 'curriculumid', 'certificationclass', 'index'],
        data: function () {
            return {
                curriculums: pep.data.curriculums,
            };
        },
        computed: {
            backgroundStyle: function () {
                var pos = 'center center';
                if (this.item.coverimage.indexOf('CoverImage.jpg') > 0) {
                    pos = 'center top';
                }

                return 'background-image: url(' + this.item.coverimage + ');background-position:' + pos;
            },
            linkurl: function () {

                if (this.item.DeepLinkURL == "") {
                    return pep.config.services.generalURL + '/lms/' + this.curriculumid + "/" + this.item.courseid + "/" + this.item.coursename;
                } else {
                    return pep.config.services.generalURL + '/lms/' + this.curriculumid + "/" + encodeURIComponent(this.item.DeepLinkURL);
                }

            },
            additionalClasses: function () {
                return "";
                //return this.certificationclass + " " + item.deliverytype;
            },
            updateLikes: function (l) {
                this.likes = l;
            },
            setLike: function () {
                pep.setLike(1, this.filteredCourses[0].CourseID, this.updateLikes, this.likes);
            },
        },
        methods: {
            openLink() {
                var self = this;

                var cid = self.item.CourseID;
                var cname = self.item.CourseName;
                var curriculumid = self.item.Curriculum_ID_FK;
                var courseULR_Cordova = '';
                var courseULR_Browser = '';

                if (self.item.DeepLinkURL) {
                    courseULR_Browser = '/lms/' + self.curriculumid + "/" + encodeURIComponent(self.item.DeepLinkURL);

                    courseULR_Cordova = self.item.DeepLinkURL;

                    pep.addLaunch(cid);
                    pep.addView(cid);

                    self.item.progress = 100;
                    self.item.isCompleted = true;
                } else {
                    courseULR_Browser = '/lms/' + self.curriculumid + "/" + self.item.courseid + "/" + self.item.coursename;

                    courseULR_Cordova = pep.config.services.generalURL + "/wdc2013/lmstokenauth.aspx?token=" + pep.data.login.token.access_token +
                        "&userName=" + encodeURIComponent(pep.data.login.token.username) +
                        "&courseName=" + encodeURIComponent(cname) +
                        "&courseid=" + cid +
                        "&curriculumId=" + curriculumid;
                }

                console.log("Check cordova link >>>>>>>>>>>>>>>>> is cordova undefined?")
                console.log(typeof cordova == 'undefined')

                if (typeof cordova == 'undefined') {
                    //open within the browser
                    console.log("Cordova is null, opening regular iframe (LMS component)")
                    console.log("Cordova is null, opening link:");
                    console.log(courseULR_Cordova);

                    self.$router.push(courseULR_Browser);

                } else {
                    //open on cordova
                    console.log("Cordova exist, InAppBrowser open link:");
                    console.log(courseULR_Cordova);

                    pep.CordovaLinkOpen(courseULR_Cordova, self);
                }
            }
        },
    }
</script>
